import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDomainProvider } from './i-domain-provider';
import { HttpClient } from '@angular/common/http';

interface IConfig {
  domain: string;
}

@Injectable({ providedIn: 'root' })
export class DomainService implements IDomainProvider {
  private subject = new BehaviorSubject<string | null>(null);
  domain$ = this.subject.asObservable();

  constructor(http: HttpClient) {
    http
      .get<IConfig>('./config/domain.json')
      .pipe(
        map(config => (config && config.domain ? config.domain : null)),
        map(domain => (domain ? domain : this.getDomainFromUrl()))
      )
      .subscribe(domain => {
        console.log('TCL: DomainService -> constructor -> domain', domain);
        this.subject.next(domain);
      });
    window['Domain'] = (domain: string) => {
      this.subject.next(domain);
    }; // add global function so domains can be tested via JS console.
  }

  set(domain: string) {
    this.subject.next(domain);
  }

  private getDomainFromUrl() {
    const names = window.location.hostname.split('.');
    let name = names && names.length > 1 ? (names[0] !== 'order' ? names[0] : names[1]) : 'demo';
    name = name.includes('threebapps') ? 'demo' : name; // handle going to firebase url directly

    try {
      if (!isNaN(parseInt(name, 10))) {
        name = 'demo'; // local ip used fallback to demo
      }
    } catch (e) {
      // noop
    }
    return name;
  }
}
