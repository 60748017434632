import { Injectable } from '@angular/core';
import { IClientLocation } from '../../../../../shared/models/i-client-location';
import { isBefore, addMinutes, isAfter, addDays } from 'date-fns';
import { WeeklyHours } from '../components/weekly-hours/weekly-hours.model';

const MIN_MINUTES_ORDER_AHEAD = 15;

// Errors
const NO_LOCATION_SELECTED = 'No location selected';
const PICKUP_DATE_IS_IN_PAST = 'The pickup date is in the past';
const PICKUP_DATE_IS_TOO_CLOSE_TO_CURRENT_DATE = `The pickup date has to be at least ${MIN_MINUTES_ORDER_AHEAD} minutes in the future`;
const LOCATION_CLOSED_AT_TIME = 'This location is closed at that time';
const PICKUP_DATE_IS_TOO_FAR_IN_FUTURE = 'The pickup date is too far into the future';
const NO_DATE_SELECTED = 'No date was selected';

@Injectable({
  providedIn: 'root',
})
export class LocationPickupService {
  public location: IClientLocation;

  constructor() {}

  validateDate(date: Date) {
    const now = new Date();

    /* Check for location */
    if (!this.location) {
      console.log('Error no location selected!');
      return { valid: false, error: NO_LOCATION_SELECTED };
    }
    /* No date selected */
    if (!date) {
      console.log('No date selected');
      return { valid: false, error: NO_DATE_SELECTED };
    }

    console.log(
      `pickupDate: ${date.getMonth()}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    );
    console.log(
      `now: ${
        now.getMonth() + 1
      }/${now.getDate()}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`
    );

    /* Make sure pickup date is after now */
    if (isBefore(date, now)) {
      console.log('Pickup date is before current date');
      return { valid: false, error: PICKUP_DATE_IS_IN_PAST };
    }
    /* Make sure pickup date is after a minimum of minutes ahead */
    if (isBefore(date, addMinutes(now, MIN_MINUTES_ORDER_AHEAD))) {
      console.log(`Pickup date is not ${MIN_MINUTES_ORDER_AHEAD} minutes after current date`);
      return { valid: false, error: PICKUP_DATE_IS_TOO_CLOSE_TO_CURRENT_DATE };
    }
    /* Check that the pickup date does not go further than the max date set */
    const maxDaysOrderInAdvance = this.location.featureFlags.orderAhead.maxDaysInAdvance;
    // Add 40 minutes to make an extra buffer that ordering might take to allow the full max days
    if (isAfter(date, addMinutes(addDays(now, maxDaysOrderInAdvance), 40))) {
      console.log(`Pickup date is after the max date set ${maxDaysOrderInAdvance}`);
      return { valid: false, error: PICKUP_DATE_IS_TOO_FAR_IN_FUTURE };
    }

    const weeklyHours = new WeeklyHours(this.location.hours);
    const isOpenAtDate = weeklyHours.isOpenAtDate(date);

    if (isOpenAtDate) {
      console.log('Date is valid');
      return { valid: true };
    } else {
      return { valid: false, error: LOCATION_CLOSED_AT_TIME };
    }
  }
}
