import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ICoordinates } from '../../../../../shared/models/i-coordinates';
import { IDistanceToLocation } from '../../../../../shared/models/i-distance-to-location';
import { API } from '../../../../../shared/constants/api-endpoints';
import { IClientLocation } from '../../../../../shared/models/i-client-location';

@Injectable()
export class DistanceService {
  constructor(private afFunctions: AngularFireFunctions) {}

  getDistance(start: ICoordinates, address: string): Observable<IDistanceToLocation> {
    const latitude = start.latitude;
    const longitude = start.longitude;
    const callable = this.afFunctions.httpsCallable(API.getDistance);
    return callable({ latitude, longitude, address }).pipe(
      map(result => {
        if (!result) {
          return null;
        }
        return {
          address: address,
          distance: result.distance,
          duration: result.duration
        } as IDistanceToLocation;
      })
    );
  }

  getSortableDistance(
    start: ICoordinates,
    location: IClientLocation
  ): Observable<IDistanceToLocation> {
    const latitude = start.latitude;
    const longitude = start.longitude;
    const address = location.name;
    const callable = this.afFunctions.httpsCallable(API.getDistance);
    return callable({ latitude, longitude, address }).pipe(
      map(result => {
        if (!result) {
          return null;
        }
        return {
          distance: result.distance,
          duration: result.duration
        } as IDistanceToLocation;
      })
    );
  }
}
