import { DeviceService } from './device.service';
import { PlatformType } from './../../../../../shared/constants/platform-type';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AlertController, LoadingController } from '@ionic/angular';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { API } from '../../../../../shared/constants/api-endpoints';
import { Progress } from '../../../../../shared/constants/progress';
import { CheckoutProgressEvent } from '../../../../../shared/models/i-checkout-progress-event';
import { ICheckoutRequest } from '../../../../../shared/models/i-checkout-request';
import { StripeCheckoutService } from './stripe-checkout.service';
import { CartService } from './cart.service';
import { IOrder } from '../../../../../shared/models/i-order';

@Injectable()
export class CheckoutService {
  private subscription: Subscription;
  private loading: HTMLIonLoadingElement;
  private _event = new CheckoutProgressEvent();
  private progressSubject = new BehaviorSubject<CheckoutProgressEvent>(this._event);
  progress$ = this.progressSubject.asObservable();

  constructor(
    public afs: AngularFirestore,
    private afFunctions: AngularFireFunctions,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private stripeCheckout: StripeCheckoutService,
    public cartService: CartService,
    private deviceService: DeviceService
  ) {}

  async checkout(order: IOrder) {
    const platform = await this.deviceService.getPlatformType();

    order.id = this.afs.createId();
    order.platform = platform as PlatformType;
    const dto = { order: order } as ICheckoutRequest;
    if (order.cart.total > 0) {
      const token = await this.stripeCheckout.open(order);
      if (token === null) {
        return;
      }
      dto.tokenId = token.id;
    }
    await this.showProgressiveLoader(order);

    return this.afFunctions
      .httpsCallable(API.checkout)(dto)
      .pipe(
        first(),
        catchError(error => {
          console.log('TCL: CheckoutService -> checkout -> error', error);
          // Getting the Error details.
          const code = error ? error.code : null;
          const message = error ? error.message : null;
          const details = error ? error.details : null;
          console.log(`error code ${code} message ${message} details ${details}`);
          throw error;
        }),
        map((o: IOrder) => {
          console.log('TCL: CheckoutService -> checkout complete -> order', o);
          this.reset();
          this.cartService.clearCart();
          order = o;
          this._event.order = order;
          this._event.progress = Progress.COMPLETE;
          this.progressSubject.next(this._event);
          return order;
        })
      )
      .toPromise();
  }

  async showProgressiveLoader(order: IOrder) {
    this._event.progress = Progress.Processing;
    this._event.order = order;
    this.progressSubject.next(this._event);
    let counter = 1;
    let message = 'Ordering';
    await this.showLoading(message);
    this.subscription = interval(3000).subscribe(() => {
      if (counter > 2) {
        message = 'Processing';
      }
      if (counter > 4) {
        message = 'Almost done';
      }
      if (counter > 6) {
        message = 'Thanks for waiting';
      }
      if (counter > 10) {
        this.reset();
        this.alertController
          .create({
            header: 'Error',
            subHeader: 'Payment authorization has timed out',
            buttons: [
              {
                text: 'OK'
              }
            ]
          })
          .then(alert => alert.present());
        return;
      }
      this.loading.message = message;
      counter += 1;
    });
  }

  private async showLoading(message?: string) {
    this.loading = await this.loadingController.create({
      translucent: true,
      message: message
    });
    await this.loading.present();
  }

  private async hideLoading() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  private reset() {
    this.hideLoading();
    this._event = new CheckoutProgressEvent();
  }

  resetCompleted() {
    this.progressSubject.next(null);
  }

  onClosed() {
    if (!!this._event && this._event.progress === Progress.Processing) {
      return;
    }
    this.hideLoading();
  }

  errorAlert(message?: string) {
    this.reset();
    this.alertController
      .create({
        header: 'Oops!',
        subHeader: message ? message : 'We have experienced an error. Sorry for any inconvience.',
        buttons: [
          {
            text: 'OK'
          }
        ]
      })
      .then(alert => alert.present());
  }
}
