import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { map, filter } from 'rxjs/operators';
import { LocationService } from '../../shared/providers/location.service';
import { IAddress } from '../../../../../shared/models/i-address';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss']
})
export class MapModalComponent implements OnInit {
  url: SafeResourceUrl;
  address: string;

  constructor(
    public modalController: ModalController,
    private locationService: LocationService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.locationService.location$
      .pipe(
        filter(location => !!location),
        map(location => location.address)
      )
      .subscribe(address => {
        if (address) {
          this.address = this.addressToString(address);
          this.url = this.createUrl(this.address);
          return;
        }
      });
  }

  private createUrl(address: string) {
    const mapUrl =
      'https://www.google.com/maps/embed/v1/place?key=AIzaSyA2HcLD87N2LTCuLXZbKxBX9413qttvpcQ&q=';

    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${mapUrl}${address}`);
  }

  private addressToString(address: IAddress) {
    let addr = '';
    addr = address.line1 ? `${addr}${address.line1} ` : addr;
    addr = address.line2 ? `${addr}${address.line2} ` : addr;
    addr = address.city ? `${addr}, ${address.city}, ` : addr;
    addr = address.state ? `${addr}${address.state} ` : addr;
    addr = address.zip ? `${addr}${address.zip} ` : addr;
    console.log('TCL: MapModalComponent -> privateaddresToString -> addr', addr);
    return addr;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  getDirections() {
    const url = `http://maps.apple.com/?daddr=${this.address}`;
    console.log('TCL: MapModalComponent -> getDirections -> url', url);
    window.open(url, '_self');
  }
}
