import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { switchMap, map, filter } from 'rxjs/operators';
import { DB } from '../../../../../shared/constants/db';
import { IClient } from '../../../../../shared/models/i-client';
import { DomainService } from './domain.service';
import { AngularFirestore } from '@angular/fire/firestore';

const KEY = 'clientId';
@Injectable({ providedIn: 'root' })
export class ClientService {
  private clientIdSubject: BehaviorSubject<string | null>;
  public clientId$: Observable<string | null>;
  public client$: Observable<IClient>;
  private domainFilter$: BehaviorSubject<string>;

  constructor(private afs: AngularFirestore, private domainService: DomainService) {
    const storedId = localStorage.getItem(KEY);
    this.clientIdSubject = new BehaviorSubject<string | null>(storedId);
    this.clientId$ = this.clientIdSubject.asObservable();
    this.domainFilter$ = new BehaviorSubject<string | null>(null);
    this.observeFilteredClient();

    this.clientId$.pipe(filter((id) => !!id && id.length > 0)).subscribe((id) => {
      localStorage.setItem(KEY, id);
    });
    this.domainService.domain$.pipe(filter((domain) => !!domain)).subscribe((domain) => {
      this.domainFilter$.next(domain);
    });
  }

  private observeFilteredClient() {
    this.client$ = this.domainFilter$.pipe(
      filter((domain) => !!domain),
      switchMap((domain) => {
        const collectionRef = this.afs.collection<IClient>(DB.CLIENTS.ID, (ref) => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          query = query.where('domain', '==', domain);
          query = query.limit(1);
          return query;
        });
        return collectionRef.snapshotChanges().pipe(
          map((snapshots) => {
            if (snapshots.length === 0) {
              return null;
            }
            const snapshot = snapshots[0];
            this.clientIdSubject.next(snapshot.payload.doc.id);
            return snapshot.payload.doc.data();
          })
        );
      })
    );
  }

  filterByDomain(domain: string | null) {
    this.domainFilter$.next(domain);
  }
  public getClient(id: string) {
    return this.afs.collection(DB.CLIENTS.ID).doc<IClient>(id).valueChanges();
  }
}
