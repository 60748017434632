import { ConnectOauthGuard } from './modules/shared/guards/connect-oauth.guard';
import { SharedProvidersModule } from './modules/shared/providers/shared-providers.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Routes, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ModalModule } from './modules/modals/modal.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { LocationGuard } from './modules/shared/providers/location.guard';
import { AuthGuard } from './modules/shared/guards/auth.guard';
import { StripeConnectErrorComponent } from './modules/shared/components/stripe-connect-error/stripe-connect-error.component';
import { SharedComponentsModule } from './modules/shared/components/shared.components.module';
import { AngularFireStorageModule } from '@angular/fire/storage';

const routes: Routes = [
  { path: 'home', redirectTo: '' },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'menu',
    canActivate: [LocationGuard],
    loadChildren: () => import('./modules/menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'rewards',
    loadChildren: () => import('./modules/rewards/rewards.module').then((m) => m.RewardsPageModule),
  },
  {
    path: 'portal/location',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/location-portal/location-portal.module').then((m) => m.LocationPortalPageModule),
  },
  {
    path: 'portal/client',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/client-portal/client-portal.module').then((m) => m.ClientPortalPageModule),
  },
  {
    path: 'portal/admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/developer-portal/developer-portal.module').then((m) => m.DeveloperPortalPageModule),
  },
  {
    path: 'stripe/connect',
    canActivate: [ConnectOauthGuard],
    component: StripeConnectErrorComponent,
  },
  {
    path: 'native',
    loadChildren: () => import('./modules/native/native.module').then((m) => m.NativeModule),
  },
  {
    path: 'notallowed',
    loadChildren: () => import('./modules/members-only/members-only.module').then((m) => m.MembersOnlyPageModule),
  },
];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(),
    RouterModule.forRoot(routes),
    SharedProvidersModule,
    SharedComponentsModule,
    ModalModule,
    AngularFireModule.initializeApp(environment.firebase, 'threebapps'),
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: FunctionsRegionToken, useValue: 'us-central1' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
