import { Injectable } from '@angular/core';
import { ToastsService } from './toasts.service';
import { BehaviorSubject } from 'rxjs';
import { ICoordinates } from '../../../../../shared/models/i-coordinates';

@Injectable()
export class GeolocationService {
  isAvailable = false;
  private subject = new BehaviorSubject<ICoordinates | null>(null);
  currentPosition$ = this.subject.asObservable();
  constructor(private toastService: ToastsService) {
    this.init()
      .then(() => console.log('geolocation service initialized'))
      .catch(error => console.log('failed to initalize geolocation service', error));
  }

  private async init() {
    try {
      if ('geolocation' in navigator && location.protocol === 'https:') {
        this.isAvailable = true;
        const coordinates = await this.getPosition();
        console.log('coordinates', coordinates);
        this.subject.next(coordinates);
      } else {
        console.log('geolocation is not available');
        this.isAvailable = false;
      }
    } catch (error) {
      console.log({ error });
    }
  }

  private getPosition(): Promise<ICoordinates> {
    return new Promise((resolve, reject) => {
      if (!this.isAvailable) {
        const error = 'geolocation is not available';
        console.warn(error);
        reject(error);
        return;
      }
      navigator.geolocation.getCurrentPosition(
        position => {
          const currentPosition = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          } as ICoordinates;
          this.subject.next(currentPosition);
          resolve(currentPosition);
        },
        error => {
          console.error(error);
          this.toastService.showGeolocationFailedToast();
          reject(error);
        }
      );
    });
  }
}
