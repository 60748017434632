<div *ngIf="hours && loaded" class="hours ion-justify-content-start ion-align-items-center slider"
  (click)="showHours = !showHours" [class.open-hours]="showHours">
  <ion-grid class="ion-no-padding" *ngIf="!hours.isUnavailable">
    <!-- MONDAY -->
    <ion-row [ngClass]="{'bold-hour': hours.day === 1}" *ngIf="showHours || (hours.day === 1 && !showHours)">
      <ion-col class="ion-no-padding">
        <ion-row class="ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-label class="bold-hour ion-no-padding" *ngIf="hours.day === 1">Mon</ion-label>
            <ion-label class="ion-no-padding" *ngIf="!(hours.day === 1)">Mon</ion-label>
          </ion-col>
          <ion-col style="padding: 0 1px 0 0;">
            <ion-label color="success" *ngIf="hours.day === 1 && hours.isOpen">
              Open
            </ion-label>
            <ion-label color="danger" *ngIf="(hours.day === 1 && !hours.isOpen) && hours.monday.isOpen">
              Closed
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="!hours.monday.isOpen || !hours.monday.start || !hours.monday.end">
        <ion-label color="danger">CLOSED</ion-label>
      </ion-col>
      <ion-col class="info-left ion-no-padding"
        *ngIf="hours.monday.isOpen && hours.monday.start && hours.monday.start.toDate">
        {{ hours.monday.start.toDate() | date: 'shortTime' }}
      </ion-col>
      <ion-col  class="info-center ion-no-padding" size="1" *ngIf="
          hours.monday.isOpen && hours.monday.start && hours.monday.end && hours.monday.end.toDate
        ">
        -
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="hours.monday.isOpen && hours.monday.end && hours.monday.end.toDate">
        {{ hours.monday.end.toDate() | date: 'shortTime' }}
      </ion-col>
    </ion-row>
    <ion-row [ngClass]="{'bold-hour': hours.day === 2}" *ngIf="showHours || (hours.day === 2 && !showHours)">
      <ion-col class="ion-no-padding">
        <ion-row  class="ion-justify-content-between ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-label class="ion-no-padding">Tue</ion-label>
          </ion-col>
          <ion-col style="padding: 0 1px 0 0;">
            <ion-label color="success" *ngIf="hours.day === 2 && hours.isOpen">
              Open
            </ion-label>
            <ion-label color="danger" *ngIf="(hours.day === 2 && !hours.isOpen) && this.hours.tuesday.isOpen">
              Closed
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="!hours.tuesday.isOpen || !hours.tuesday.start || !hours.tuesday.end">
        <ion-label color="danger">CLOSED</ion-label>
      </ion-col>
      <ion-col class="info-left ion-no-padding"
        *ngIf="hours.tuesday.isOpen && hours.tuesday.start && hours.tuesday.start.toDate">
        {{ hours.tuesday.start.toDate() | date: 'shortTime' }}
      </ion-col>
      <ion-col class="info-center ion-no-padding" size="1" *ngIf="
          hours.tuesday.isOpen &&
          hours.tuesday.start &&
          hours.tuesday.end &&
          hours.tuesday.end.toDate
        ">
        -
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="hours.tuesday.isOpen && hours.tuesday.end && hours.tuesday.end.toDate">
        {{ hours.tuesday.end.toDate() | date: 'shortTime' }}
      </ion-col>
    </ion-row>
    <ion-row [ngClass]="{'bold-hour': hours.day === 3}" *ngIf="showHours || (hours.day === 3 && !showHours)">
      <ion-col class="ion-no-padding">
        <ion-row class="ion-justify-content-between ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-label class="ion-no-padding">Wed</ion-label>
          </ion-col>
          <ion-col style="padding: 0 1px 0 0;">
            <ion-label color="success" *ngIf="hours.day === 3 && hours.isOpen">
              Open
            </ion-label>
            <ion-label color="danger" *ngIf="(hours.day === 3 && !hours.isOpen) && this.hours.wednesday.isOpen">
              Closed
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="!hours.wednesday.isOpen || !hours.wednesday.start || !hours.wednesday.end">
        <ion-label color="danger">CLOSED</ion-label>
      </ion-col>
      <ion-col  class="info-left ion-no-padding"
        *ngIf="hours.wednesday.isOpen && hours.wednesday.start && hours.wednesday.start.toDate">
        {{ hours.wednesday.start.toDate() | date: 'shortTime' }}
      </ion-col>
      <ion-col class="info-center ion-no-padding" size="1" *ngIf="
          hours.wednesday.isOpen &&
          hours.wednesday.start &&
          hours.wednesday.start.toDate &&
          hours.wednesday.end &&
          hours.wednesday.end.toDate
        ">
        -
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="hours.wednesday.isOpen && hours.wednesday.end && hours.wednesday.end.toDate">
        {{ hours.wednesday.end.toDate() | date: 'shortTime' }}
      </ion-col>
    </ion-row>
    <ion-row [ngClass]="{'bold-hour': hours.day === 4}" *ngIf="showHours || (hours.day === 4 && !showHours)">
      <ion-col class="ion-no-padding">
        <ion-row class="ion-no-padding" class="ion-justify-content-between">
          <ion-col class="ion-no-padding">
            <ion-label class="ion-no-padding">Thur</ion-label>
          </ion-col>
          <ion-col style="padding: 0 1px 0 0;">
            <ion-label color="success" *ngIf="hours.day === 4 && hours.isOpen">
              Open
            </ion-label>
            <ion-label color="danger" *ngIf="(hours.day === 4 && !hours.isOpen) && this.hours.wednesday.isOpen">
              Closed
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="!hours.thursday.isOpen || !hours.thursday.start || !hours.thursday.end">
        <ion-label color="danger">CLOSED</ion-label>
      </ion-col>
      <ion-col class="info-left ion-no-padding"
        *ngIf="hours.thursday.isOpen && hours.thursday.start && hours.thursday.start.toDate">
        {{ hours.thursday.start.toDate() | date: 'shortTime' }}
      </ion-col>
      <ion-col class="info-center ion-no-padding" size="1" *ngIf="
          hours.thursday.isOpen &&
          hours.thursday.start &&
          hours.thursday.end &&
          hours.thursday.end.toDate
        ">
        -
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="hours.thursday.isOpen && hours.thursday.end && hours.thursday.end.toDate">
        {{ hours.thursday.end.toDate() | date: 'shortTime' }}
      </ion-col>
    </ion-row>
    <ion-row [ngClass]="{'bold-hour': hours.day === 5}" *ngIf="showHours || (hours.day === 5 && !showHours)">
      <ion-col class="ion-no-padding">
        <ion-row class="ion-no-padding" class="ion-justify-content-between">
          <ion-col class="ion-no-padding">
            <ion-label class="ion-no-padding">Fri</ion-label>
          </ion-col>
          <ion-col style="padding: 0 1px 0 0;">
            <ion-label color="success" *ngIf="hours.day === 5 && hours.isOpen">
              Open
            </ion-label>
            <ion-label color="danger" *ngIf="(hours.day === 5 && !hours.isOpen) && this.hours.thursday.isOpen">
              Closed
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="!hours.friday.isOpen || !hours.friday.start || !hours.friday.end">
        <ion-label color="danger">CLOSED</ion-label>
      </ion-col>
      <ion-col class="info-left ion-no-padding"
        *ngIf="hours.friday.isOpen && hours.friday.start && hours.friday.start.toDate">
        {{ hours.friday.start.toDate() | date: 'shortTime' }}
      </ion-col>
      <ion-col  class="info-center ion-no-padding" size="1" *ngIf="
          hours.friday.isOpen && hours.friday.start && hours.friday.end && hours.friday.end.toDate
        ">
        -
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="hours.friday.isOpen && hours.friday.end && hours.friday.end.toDate">
        {{ hours.friday.end.toDate() | date: 'shortTime' }}
      </ion-col>
    </ion-row>
    <ion-row [ngClass]="{'bold-hour': hours.day === 6}" *ngIf="showHours || (hours.day === 6 && !showHours)">
      <ion-col class="ion-no-padding">
        <ion-row class="ion-justify-content-between ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-label class="ion-no-padding">Sat</ion-label>
          </ion-col>
          <ion-col style="padding: 0 1px 0 0;">
            <ion-label color="success" *ngIf="hours.day === 6 && hours.isOpen">
              Open
            </ion-label>
            <ion-label color="danger" *ngIf="(hours.day === 6 && !hours.isOpen) && this.hours.saturday.isOpen">
              Closed
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="!hours.saturday.isOpen || !hours.saturday.start || !hours.saturday.end">
        <ion-label color="danger">CLOSED</ion-label>
      </ion-col>
      <ion-col  class="info-left ion-no-padding"
        *ngIf="hours.saturday.isOpen && hours.saturday.start && hours.saturday.start.toDate">
        {{ hours.saturday.start.toDate() | date: 'shortTime' }}
      </ion-col>
      <ion-col   class="info-center ion-no-padding" size="1"
        *ngIf="hours.saturday.isOpen && hours.saturday.start && hours.saturday.end">
        -
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="hours.saturday.isOpen && hours.saturday.end">
        {{ hours.saturday.end.toDate() | date: 'shortTime' }}
      </ion-col>
    </ion-row>
    <ion-row [ngClass]="{'bold-hour': hours.day === 0}" *ngIf="showHours || (hours.day === 0 && !showHours)">
      <ion-col class="ion-no-padding">
        <ion-row  class="ion-justify-content-between ion-no-padding">
          <ion-col class="ion-no-padding">
            <ion-label class="ion-no-padding">Sun</ion-label>
          </ion-col>
          <ion-col style="padding: 0 1px 0 0;">
            <ion-label color="success" *ngIf="hours.day === 0 && hours.isOpen">Open</ion-label>
            <ion-label color="danger" *ngIf="(hours.day === 0 && !hours.isOpen) && this.hours.sunday.isOpen">
              Closed
            </ion-label>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="!hours.sunday.isOpen || !hours.sunday.start || !hours.sunday.end">
        <ion-label color="danger">CLOSED</ion-label>
      </ion-col>
      <ion-col   class="info-left ion-no-padding"
        *ngIf="hours.sunday.isOpen && hours.sunday.start && hours.sunday.start.toDate">
        {{ hours.sunday.start.toDate() | date: 'shortTime' }}
      </ion-col>
      <ion-col  class="info-center ion-no-padding" size="1"
        *ngIf="hours.sunday.isOpen && hours.sunday.start && hours.sunday.end">
        -
      </ion-col>
      <ion-col class="ion-no-padding" *ngIf="hours.sunday.isOpen && hours.sunday.end">
        {{ hours.sunday.end.toDate() | date: 'shortTime' }}
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col *ngIf="hours.isOpen" class="status open">
        <small>{{ hours.untilClosed | titlecase }} Until Closing</small>
      </ion-col>
      <ion-col *ngIf="!hours.isOpen" class="status close">
        <small>{{ hours.untilOpen | titlecase }} Until Open</small>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="hours.isUnavailable">
    <ion-col>
      <ion-label color="danger">Ordering is currently not available from this location</ion-label>
    </ion-col>
  </ion-grid>
  <span class="arrow-icon rotate-back" [class.rotate]="showHours">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <style type="text/css">
        .st1 {
          fill: none;
        }
      </style>
      <path class="st0" d="M7.4,8.6l4.6,4.6l4.6-4.6L18,10l-6,6l-6-6L7.4,8.6z" />
      <path class="st1" d="M0,0h24v24H0V0z" />
    </svg>
  </span>
</div>