<ion-header style="opacity:0.99;">
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>Send Feedback</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen color="light" style="opacity:0.99;">
  <form (ngSubmit)="submit()" #feedbackForm="ngForm">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-card color="white">
            <ion-card-header>
              <ion-card-subtitle style="font-weight: bold;">What do you love and what can we improve?
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ion-textarea [(ngModel)]="this.message" name="message" rows="13" required placeholder="Enter Feedback"></ion-textarea>
            </ion-card-content>
          </ion-card>
          <ion-button [disabled]="!feedbackForm.valid || message === '' || message === null" tabindex="4" class="main-action-button" fill="solid"
            expand="block" size="medium" type="submit">
            Submit
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>