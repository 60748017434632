import { IOrder } from './../../../../../shared/models/i-order';
import { ILocationTip } from './../../../../../shared/models/i-location-tip';
import { IClient } from './../../../../../shared/models/i-client';
import { ClientService } from './client.service';
import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { IStripeDto } from '../../../../../shared/models/i-stripe-dto';
import { first } from 'rxjs/operators';
import { IStripeToken } from '../../../../../shared/models/i-stripe-token';
import { asCurrency } from '../../../../../shared/models/cart';

interface IStripeCheckout {
  open: (dto: IStripeDto) => any;
  close: () => any;
}
declare const StripeCheckout: any;

@Injectable({ providedIn: 'root' })
export class StripeCheckoutService {
  private order: IOrder;
  private client: IClient;
  private handler: IStripeCheckout;
  private resolve: any;
  private reject: any;

  constructor(private clientService: ClientService, private currencyPipe: CurrencyPipe) {
    this.clientService.client$.pipe(first(client => !!client)).subscribe(client => {
      this.client = client;
    });
  }

  initStripeCheckout(client: IClient, email: string): void {
    console.log('TCL: StripeCheckoutService -> apiKey', client.keys.stripe);
    this.handler = StripeCheckout.configure({
      key: client.keys.stripe,
      image: client.assets.logo.small,
      email: email,
      locale: 'auto',
      token: (token: IStripeToken) => {
        this.resolve(token);
      },
      closed: () => {
        if (this.resolve) {
          this.resolve(null);
        }
      }
    });
    // Close Checkout on page navigation:
    window.addEventListener('popstate', () => this.onPopState());
  }

  open(order: IOrder): Promise<IStripeToken> {
    const promise = new Promise<IStripeToken>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.initStripeCheckout(this.client, order.contact.email);

    const total = order.cart.total;
    let currency = this.currencyPipe.transform(total);
    currency = currency.replace('$', '');
    order.amount = asCurrency(parseFloat(currency));
    this.order = order;

    this.handler.open({
      name: this.order.contact.name,
      description: `Confirmation #${this.order.confirmation}`,
      amount: this.order.amount * 100, // stripe requires this value to be in cents
      email: this.order.contact.email
    });
    return promise;
  }

  private onPopState(): void {
    this.handler.close();
  }
}
