import { AuthService } from './modules/shared/providers/auth.service';
import { PlatformTypes } from './../../shared/constants/platform-type';
import { ThemeService } from './modules/shared/providers/theme.service';
import { Component, OnInit } from '@angular/core';
import { DeviceService } from './modules/shared/providers/device.service';
import { LoadingController, AlertController } from '@ionic/angular';
import { Router, RouteConfigLoadStart } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { ClientService } from './modules/shared/providers/client.service';
import { Title } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

import { Plugins, StatusBarStyle, Capacitor } from '@capacitor/core';
import { MessagingService } from './modules/shared/providers/messaging.service';
const { StatusBar, SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private location: PlatformLocation,
    private router: Router,
    private loadingController: LoadingController,
    private clientService: ClientService,
    private titleService: Title,
    private alertController: AlertController,
    private deviceService: DeviceService,
    private auth: AuthService,
    private platform: Platform,
    themeService: ThemeService,
    private messagingService: MessagingService
  ) {
    themeService.init();
    console.log({ isNative: this.deviceService.isNative() });
    this.setupMessaging();
    console.log('App Component');
    if (this.deviceService.isNative()) {
      this.platform.ready().then(() => {
        console.log('App Component - app is native');
        Capacitor.Plugins.SplashScreen.hide();
        SplashScreen.hide();
        this.setupNative();
      });
    } else {
      console.log('App Component - app is not native');
    }
  }
  private setupNative() {
    this.updateStatusBar();
  }

  private async updateStatusBar() {
    StatusBar.show();
    StatusBar.setStyle({ style: StatusBarStyle.Light });
    const platform = await this.deviceService.getPlatformType();
    if (platform === PlatformTypes.ANDROID) {
      StatusBar.hide();
    }
  }

  private setupMessaging() {
    if (this.deviceService.isNative()) {
      this.messagingService.registerNativeNotifications();
    } else {
      this.messagingService.registerWebNotifications();
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.dismissLoader();
      }
    });
    this.location.onPopState(() => {
      this.dismissLoader();
    });
    this.clientService.client$.pipe(first((client) => !!client)).subscribe((client) => {
      const name = client.name;
      this.titleService.setTitle(name);
    });
  }

  async dismissLoader() {
    const loader = await this.loadingController.getTop();
    if (loader) {
      loader.dismiss();
    }
  }
  async showAlert(header: string, subheader?: string) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      animated: true,
      backdropDismiss: true,
      translucent: false,
      keyboardClose: true,
    });
    await alert.present();
  }
}
