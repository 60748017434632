import { IMenu } from './../../../../../shared/models/i-menu';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchMenus'
})
export class SearchMenusPipe implements PipeTransform {
  transform(input: IMenu[], target: string): any {
    if (!input || input.length === 0 || !target || target.length === 0) {
      return input;
    }
    const t = target.toLowerCase();
    return input.filter(menu => {
      return (
        menu.name.toLowerCase().includes(t) ||
        menu.description.toLowerCase().includes(t) ||
        (menu &&
          menu.categories &&
          menu.categories.length > 0 &&
          menu.categories.filter(c => c.name.toLowerCase().includes(target)).length > 0)
      );
    });
  }
}
