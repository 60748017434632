import { SearchOrdersPipe } from './../../pipes/search-orders.pipe';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, combineLatest } from 'rxjs';
import { IOrder } from '../../../../../../shared/models/i-order';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-order-search-bar',
  templateUrl: './order-search-bar.component.html',
  styleUrls: ['./order-search-bar.component.scss']
})
export class OrderSearchBarComponent implements OnInit, OnDestroy {
  @Input() orders = new EventEmitter<IOrder[]>();
  @Input() pageSize = 8;
  @Input() pageNumber = 0;
  @Output() foundOrders = new EventEmitter<IOrder[]>();

  searchBar = new FormControl();
  itemsInitialized = false;
  placeholderText = `Search (shows ${this.pageSize})`;
  filtered = new Array<IOrder>();
  pages = new Array<Array<IOrder>>();

  private subscriptions = new Array<Subscription>();

  constructor(private searchOrdersPipe: SearchOrdersPipe) {}

  ngOnInit() {
    const orderSubscription = this.orders.subscribe(orders => {
      if (orders && orders.length > 0 && !this.itemsInitialized) {
        this.searchBar.setValue('', { emitEvent: true });
        this.itemsInitialized = true;
      }
    });
    this.subscriptions.push(orderSubscription);

    const searchSubscription = combineLatest([
      this.searchBar.valueChanges,
      this.orders.asObservable()
    ])
      .pipe(
        filter(([_search, orders]) => !!orders),
        tap(([search, orders]) => {
          this.filtered = this.searchOrdersPipe.transform(orders, search);
          this.pages = this.paginate(this.filtered, this.pageSize);
          this.foundOrders.emit(this.pages[this.pageNumber]);
        })
      )
      .subscribe();

    this.subscriptions.push(searchSubscription);
    this.searchBar.setValue('', { emitEvent: true });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  firstPage() {
    this.pageNumber = 0;
    this.foundOrders.emit(this.pages[this.pageNumber]);
  }

  lastPage() {
    this.pageNumber = this.pages.length - 1;
    this.foundOrders.emit(this.pages[this.pageNumber]);
  }
  nextPage() {
    if (this.pageNumber >= this.pages.length - 1) {
      this.pageNumber = this.pages.length - 1;
      return;
    }
    ++this.pageNumber;
    this.foundOrders.emit(this.pages[this.pageNumber]);
  }

  previousPage() {
    if (this.pageNumber < 1) {
      this.pageNumber = 0;
      return;
    }
    --this.pageNumber;
    this.foundOrders.emit(this.pages[this.pageNumber]);
  }

  paginate(array, size) {
    this.pageNumber = 0;
    if (!array) {
      return [];
    }
    size = size !== undefined && size !== null && size > 0 ? size : 10;
    const pages = [];
    for (let i = 0; i < array.length; i += size) {
      pages.push(array.slice(i, i + size));
    }
    return pages;
  }
}
