// https://ionicframework.com/docs/theming/basics
// codified version of the docs mainly using for reference
export const COLOR = {
  NAME: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    DARK: 'dark',
    MEDIUM: 'medium',
    LIGHT: 'light'
  },
  HEX: {
    PRIMARY: {
      BASE: '#3880ff',
      SHADE: '#3171e0',
      TINT: '#4c8dff'
    },
    SECONDARY: {
      BASE: '#0cd1e8',
      SHADE: '#0bb8cc',
      TINT: '#24d6ea'
    },
    TERTIARY: {
      BASE: '#7044ff',
      SHADE: '#633ce0',
      TINT: '#7e57ff'
    },
    SUCCESS: {
      BASE: '#10dc60',
      SHADE: '#0ec254',
      TINT: '#28e070'
    },
    WARNING: {
      BASE: '#ffce00',
      SHADE: '#e0b500',
      TINT: '#ffd31a'
    },
    DANGER: {
      BASE: '#f04141',
      SHADE: '#d33939',
      TINT: '#f25454'
    },
    DARK: {
      BASE: '#222428',
      SHADE: '#1e2023',
      TINT: '#383a3e'
    },
    MEDIUM: {
      BASE: '#989aa2',
      SHADE: '#86888f',
      TINT: '#a2a4ab'
    },
    LIGHT: {
      BASE: '#f4f5f8',
      SHADE: '#d7d8da',
      TINT: '#f5f6f9'
    }
  }
};
