<ion-grid *ngIf="auth.user">
  <ion-header>
    <ion-toolbar color="dark">
      <ion-title>User Not Allowed</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-row class="ion-margin">
    <ion-col class="ion-padding ion-text-center">
      <ion-label class="font-underlined">
        Members Only
      </ion-label>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-label class="font">
        User not allowed to access {{ featureName }}
      </ion-label>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button class="members-only-button" color="primary" fill="solid" expand="block" size="large"
        (click)="signOut()">Signout
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid *ngIf="!auth.user">
  <ion-header>
    <ion-toolbar color="dark">
      <ion-title>Sign In to Access</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-row class="ion-margin">
    <ion-col class="ion-padding ion-text-center">
      <ion-label class="font-underlined">
        Members Only
      </ion-label>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-label class="font">
        To access {{ featureName }}
      </ion-label>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button class="members-only-button" color="primary" fill="solid" expand="block" size="large"
        (click)="showLogin()">Sign In
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <app-hr-label text="OR"></app-hr-label>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button class="members-only-button" color="secondary" fill="solid" expand="block" size="large"
        (click)="showSignUp()">Sign Up
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>