import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { ClientService } from './client.service';
import { LocationService } from './location.service';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DB } from '../../../../../shared/constants/db';
import { IMenu } from '../../../../../shared/models/i-menu';
import { IMenuItem } from '../../../../../shared/models/i-menu-item';

@Injectable()
export class MenuService {
  private subject = new BehaviorSubject<IMenu | null>(null);
  public menu$: Observable<IMenu> = this.subject.asObservable();
  constructor(
    private afs: AngularFirestore,
    private clientService: ClientService,
    private locationService: LocationService
  ) {
    combineLatest(
      this.clientService.clientId$,
      this.locationService.location$,
      this.locationService.locationId$
    )
      .pipe(
        filter(
          ([clientId, location, locationId]) =>
            !!clientId &&
            !!location &&
            !!location.activeMenu &&
            location.activeMenu.length > 0 &&
            !!locationId
        ),
        map(([clientId, location, locationId]) => {
          this.afs
            .collection(DB.CLIENTS.ID)
            .doc(clientId)
            .collection(DB.CLIENTS.LOCATIONS.ID)
            .doc(locationId)
            .collection(DB.CLIENTS.LOCATIONS.MENUS.ID)
            .doc<IMenu>(location.activeMenu)
            .valueChanges()
            .subscribe(menu => this.subject.next(menu));
        })
      )
      .subscribe();
  }

  getItem$(categoryId: string, itemId: string): Observable<IMenuItem> {
    return this.menu$.pipe(
      filter(menu => !!menu && !!categoryId && !!itemId),
      map(menu => menu.categories.filter(c => c.id === categoryId)),
      filter(categories => {
        return (
          !!categories &&
          categories.length > 0 &&
          !!categories[0] &&
          !!categories[0].items &&
          categories[0].items.length > 0
        );
      }),
      map(categories => categories[0]),
      map(category => category.items.filter(c => c.id === itemId)),
      filter(items => !!items && items.length > 0 && !!items[0]),
      map(items => items[0])
    );
  }

  getCategoryName$(categoryId: string): Observable<string> {
    return this.menu$.pipe(
      filter(menu => !!menu && !!categoryId),
      map(menu => menu.categories.filter(c => c.id === categoryId)),
      filter(categories => {
        return (
          !!categories &&
          categories.length > 0 &&
          !!categories[0] &&
          !!categories[0].items &&
          categories[0].items.length > 0
        );
      }),
      map(categories => categories[0].name)
    );
  }
}
