import { IVerifyUserExistsRequest } from './../../../../../shared/models/i-verify-user-exists-request';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserRoleDictionary } from './../../../../../shared/access-control/roles/UserRoleDictionary';
import { ClientService } from './client.service';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { tap, filter, switchMap, map, first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TrackJS, TrackJSInstallOptions } from 'trackjs';
import { DB } from '../../../../../shared/constants/db';
import { DeviceService } from './device.service';
import { Platform } from '@ionic/angular';
import { IUser } from '../../../../../shared/models/i-user';
import { AngularFirestore } from '@angular/fire/firestore';
import { API } from '../../../../../shared/constants/api-endpoints';
import { IUpdateUserInfoRequest } from '../../../../../shared/models/i-update-user-info-request';
import { Plugins } from '@capacitor/core';
import { FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { AuthPlatformAvailabilityService } from './auth-platform-availability.service';
const { SignInWithApple, FacebookLogin } = Plugins;

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  public user$: Observable<firebase.User>;
  public user: firebase.User;
  public userDB: IUser;
  private roles = new BehaviorSubject<UserRoleDictionary>(null);
  public roles$ = this.roles.asObservable();
  private subscriptions = new Array<Subscription>();
  public idToken: string;

  constructor(
    private afAuth: AngularFireAuth,
    public deviceService: DeviceService,
    private afs: AngularFirestore,
    public plt: Platform,
    private afFunctions: AngularFireFunctions,
    public clientService: ClientService,
    private authPlatformAvailabilityService: AuthPlatformAvailabilityService
  ) {
    this.loadUser();
    const idTokenSubscription = this.afAuth.idToken.pipe(filter((idToken) => !!idToken)).subscribe((idToken) => {
      this.idToken = idToken;
    });
    this.subscriptions.push(idTokenSubscription);
  }

  private loadUser() {
    this.user$ = this.afAuth.authState;

    const sub = this.user$
      .pipe(
        filter((user) => !!user),
        switchMap((user) => {
          this.user = user;
          this.getUser(user.uid).subscribe((userDB) => {
            this.userDB = userDB;
          });
          return user.getIdTokenResult();
        }),
        map((token) => token.claims),
        tap((claims) => {
          console.log('TCL: AuthService -> loadUser -> claims', claims);
          this.roles.next(claims.roles);
        }),
        tap((claims) => {
          try {
            const options: TrackJSInstallOptions = environment.trackjs;
            options.sessionId = `${this.user.uid}-${new Date().toISOString()}`;
            options.userId = !!this.user.email && this.user.email.length > 0 ? this.user.email : this.user.displayName;
            TrackJS.install(options);
            TrackJS.addMetadata('roles', JSON.stringify(claims.roles));
            TrackJS.addMetadata('displayName', this.user.displayName);
            TrackJS.addMetadata('email', this.user.email);
            TrackJS.addMetadata('uid', this.user.uid);
          } catch (error) {
            console.error('failed to setup track js');
          }
        })
      )
      .subscribe();
    this.subscriptions.push(sub);
  }

  get isSignedIn(): boolean {
    return !!this.user;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async doFacebookLogin(): Promise<void> {
    if (!this.authPlatformAvailabilityService.canPlatformUseFacebookAuth()) {
      throw new Error('Facebook Auth Unavailable');
    }
    if (this.plt.is('cordova') || this.plt.is('capacitor')) {
      await this.nativeFacebookLogin();
      return;
    }
    await this.webFacebookLogin();
  }

  private async webFacebookLogin(): Promise<void> {
    const provider = new auth.FacebookAuthProvider();
    return this.afAuth.signInWithRedirect(provider);
  }

  private async nativeFacebookLogin(): Promise<void> {
    const FACEBOOK_PERMISSIONS = ['email'];
    const facebookResponseResult: FacebookLoginResponse = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });

    if (facebookResponseResult && facebookResponseResult.accessToken && facebookResponseResult.accessToken.token) {
      console.log('facebookResponseResult.accessToken.token', facebookResponseResult.accessToken.token);
      const facebookCredential = auth.FacebookAuthProvider.credential(facebookResponseResult.accessToken.token);
      await this.afAuth.signInWithCredential(facebookCredential);
    } else {
      console.log('nativeFacebookLogin -> facebookResponseResult', facebookResponseResult);
      console.log('Cancelled by user.');
      throw new Error('Login Cancelled');
    }
  }

  getIdToken() {
    return this.idToken;
  }

  async doGoogleLogin(): Promise<void> {
    if (!this.authPlatformAvailabilityService.canPlatformUseGoogleAuth()) {
      throw new Error('Google Auth Unavailable');
    }
    if (this.plt.is('cordova') || this.plt.is('capacitor')) {
      await this.nativeGoogleLogin();
      return;
    }
    await this.webGoogleLogin();
  }

  private async webGoogleLogin() {
    const provider = new auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    await this.afAuth.signInWithPopup(provider);
  }

  private async nativeGoogleLogin() {
    const googleUser = await Plugins.GoogleAuth.signIn();
    const credential = auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
    return this.afAuth.signInAndRetrieveDataWithCredential(credential);
  }

  async doAppleLogin(): Promise<void> {
    if (!this.authPlatformAvailabilityService.canPlatformUseAppleAuth()) {
      throw new Error('Apple Auth Unavailable');
    }
    if (this.plt.is('cordova') || this.plt.is('capacitor')) {
      await this.nativeAppleLogin();
      return;
    }
    await this.webAppleLogin();
  }

  private webAppleLogin(): Promise<void> {
    const provider = new auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      // Localize the Apple authentication screen in English.
      locale: 'en',
    });
    return this.afAuth.signInWithRedirect(provider);
  }

  async nativeAppleLogin(): Promise<void> {
    const appleSignInResponse = await SignInWithApple.Authorize();
    console.log('appleSignInResponse', appleSignInResponse);
    if (appleSignInResponse && appleSignInResponse.response && appleSignInResponse.response.identityToken) {
      const credential = new auth.OAuthProvider('apple.com').credential(appleSignInResponse.response.identityToken);
      const response = await this.afAuth.signInWithCredential(credential);
      console.log('Login successful', response);
    } else {
      console.error('AuthService -> nativeAppleLogin -> appleSignInResponse', appleSignInResponse);
      throw new Error('appleSignInResponse does not contain an identity token');
    }
  }

  doEmailAndPasswordLogin(email: string, password: string): Promise<auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async doRegister(email: string, password: string, displayName?: string, phoneNumber?: string): Promise<firebase.auth.UserCredential> {
    const res = await this.afAuth.createUserWithEmailAndPassword(email, password);

    this.getUser(res.user.uid)
      .pipe(first((user) => !!user))
      .subscribe((_user) => {
        this.updateUserInfo(phoneNumber, displayName);
      });

    const verifyUserCallable = this.afFunctions.httpsCallable(API.verifyUserExists);
    const tokenKey = await this.getIdToken();
    verifyUserCallable({
      idToken: tokenKey,
    } as IVerifyUserExistsRequest);

    return res;
  }

  async signOut() {
    await this.afAuth.signOut();
    window.location.reload();
  }

  public getUser(userId?: string): Observable<IUser> {
    return userId
      ? this.afs.collection<IUser>(DB.USERS.ID).doc<IUser>(userId).valueChanges()
      : this.afs.collection<IUser>(DB.USERS.ID).doc<IUser>(this.user.uid).valueChanges();
  }
  public async updateUserInfo(phoneNumber?: string, displayName?: string) {
    console.log('starting updateUserInfo', 'phoneNumber', phoneNumber, 'displayName', displayName);
    const tokenKey = await this.getIdToken();
    const callable = this.afFunctions.httpsCallable(API.updateUserInfo);
    const platform = await this.deviceService.getPlatformType();

    const clientSubscription = this.clientService.client$.pipe(first((client) => !!client)).subscribe((client) => {
      const callableSubscription = callable({
        idToken: tokenKey,
        phoneNumber: phoneNumber,
        displayName: displayName,
        appId: client.appId,
        platform: platform,
      } as IUpdateUserInfoRequest).subscribe((r) => {
        console.log('stopping updateUserInfo');
      });
      this.subscriptions.push(callableSubscription);
    });
    this.subscriptions.push(clientSubscription);
  }

  async isAppleAvailable() {
    const appleAvailable = await this.authPlatformAvailabilityService.canPlatformUseAppleAuth();
    return appleAvailable;
  }
  isGoogleAvailable() {
    return this.authPlatformAvailabilityService.canPlatformUseGoogleAuth();
  }
  isFacebookAvailable() {
    return this.authPlatformAvailabilityService.canPlatformUseFacebookAuth();
  }
}
