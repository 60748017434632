import { Pipe, PipeTransform } from '@angular/core';
import { IClientLocation } from '../../../../../shared/models/i-client-location';

@Pipe({
  name: 'searchLocations'
})
export class SearchLocationsPipe implements PipeTransform {
  transform(input: IClientLocation[], target: string): any {
    if (!input || input.length === 0 || !target || target.length === 0) {
      return input;
    }
    const t = target.toLowerCase();
    return input.filter(location => {
      return (
        location.name.toLowerCase().includes(t) ||
        location.description.toLowerCase().includes(t) ||
        location.address.line1.toLowerCase().includes(t) ||
        (location.address.line2 && location.address.line2.toLowerCase().includes(t)) ||
        location.address.city.toLowerCase().includes(t) ||
        location.address.state.toLowerCase().includes(t) ||
        (location.address.zip &&
          location.address.zip
            .toString()
            .toLowerCase()
            .includes(t))
      );
    });
  }
}
