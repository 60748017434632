import { IOrder } from './../../../../../shared/models/i-order';
import { ILocationTip } from './../../../../../shared/models/i-location-tip';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { filter, map, tap } from 'rxjs/operators';
import { DB } from '../../../../../shared/constants/db';

@Injectable()
export class TipService {
  constructor(private afs: AngularFirestore) {}

  getTip$(order: IOrder) {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(order.clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(order.locationId)
      .collection(DB.CLIENTS.LOCATIONS.TIPS.ID)
      .doc<ILocationTip>(order.tipId)
      .valueChanges()
      .pipe(filter((tipFound) => !!tipFound));
  }
}
