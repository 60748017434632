<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>Order History</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="!auth.isSignedIn">
  <app-members-only featureName="order history"></app-members-only>
</ion-content>

<ion-content *ngIf="auth.isSignedIn">
  <ion-list class="order-list ion-margin-top">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="stacked">Start Date</ion-label>
            <ion-datetime
              [formControl]="startDate"
              display-format="MM/DD/YY"
              min="2018"
              max="2029"
              name="startDate"
              tabindex="1"
              required
            ></ion-datetime>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-label position="stacked">End Date</ion-label>
            <ion-datetime [formControl]="endDate" display-format="MM/DD/YY" min="2018" max="2029" name="endDate" tabindex="2" required></ion-datetime>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <app-order-search-bar [orders]="allOrders" (foundOrders)="foundOrdersChange($event)"></app-order-search-bar>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-item *ngFor="let order of foundOrders" detail="true" (click)="goToDetails(order)" class="order-list-item" lines="none">
      <ion-grid>
        <ion-row style="flex-wrap: nowrap; align-items: center;">
          <ion-col size="2.5" class="order-col-q">
            <ion-label color="dark">
              <p class="text-oq">${{ numToPrice(order.amount) }}</p>
            </ion-label>
          </ion-col>
          <ion-col size="6" class="order-col-q ion-float-right">
            <ion-label color="dark">
              <ion-label *ngIf="!orderPlacedInAdvance(order)" color="dark">
                <p class="bold-oq">{{ getTime(order.created) }}</p>
                <p class="text-oq" style="margin-left: 10px;">{{ getDate(order.created) }}</p>
              </ion-label>
              <div *ngIf="orderPlacedInAdvance(order)">
                <ion-row>
                  <ion-label>
                    <div class="pickup-later-label">Pickup Later At</div>
                  </ion-label>
                </ion-row>
                <ion-row>
                  <ion-label>
                    <div class="bold-oq" style="font-size: 16px;">
                      {{ getTime(order.transitInfo.time) }}
                    </div>
                    <div class="text-oq" style="font-size: 16px; margin-left: 5px;">
                      {{ getDate(order.transitInfo.time) }}
                    </div>
                  </ion-label>
                </ion-row>
              </div>
              <p>
                <small>{{ order.created | timeAgo }} ago</small>
              </p>
            </ion-label>
          </ion-col>
          <ion-button
            class="status-btn ion-float-right"
            size="default"
            expand="block"
            *ngIf="order.status === STATUS.PENDING"
            shape="round"
            fill="outline"
            color="danger"
            >Failed
          </ion-button>
          <ion-button
            class="status-btn ion-float-right"
            size="default"
            expand="block"
            *ngIf="order.status === STATUS.PAID"
            shape="round"
            fill="outline"
            color="success"
            >Paid
          </ion-button>
          <ion-button
            class="status-btn ion-float-right"
            size="default"
            expand="block"
            *ngIf="order.status === STATUS.PROCESSING"
            shape="round"
            fill="outline"
            color="dark"
          >
            Processing
          </ion-button>
          <ion-button
            class="status-btn ion-float-right"
            size="default"
            expand="block"
            *ngIf="order.status === STATUS.READY"
            shape="round"
            fill="outline"
            color="primary"
            >Ready
          </ion-button>
          <ion-button
            class="status-btn ion-float-right"
            size="default"
            expand="block"
            *ngIf="order.status === STATUS.COMPLETE"
            shape="round"
            fill="outline"
            color="secondary"
          >
            Complete
          </ion-button>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>
</ion-content>
