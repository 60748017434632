import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { IRewards } from '../../../../../shared/models/i-rewards';
import { COLOR } from '../../../../../shared/constants/color';

@Injectable()
export class ToastsService {
  constructor(private toastController: ToastController) {}

  async showWelcomeToast(name?: string) {
    const welcome = 'Welcome';
    const message = name ? `${welcome} ${name}` : welcome;
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: message,
      duration: 2000,
      position: 'top',
      translucent: false,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
  async showNotificationToast(message?: string) {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: message,
      duration: 2000,
      position: 'top',
      translucent: false,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  async showGeolocationFailedToast() {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `Unable to get your location`,
      duration: 2000,
      translucent: false,
      animated: true,
      position: 'bottom',
      cssClass: 'geo-location-failed',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  async rewardsInfo() {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `The BEST reward is applied automatically, when qualifying items are added to your cart.`,
      duration: 10000,
      animated: true,
      color: 'light',
      position: 'bottom',
      translucent: false,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  async noRewardsInfo() {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `None of your rewards could be applied to your cart.`,
      duration: 10000,
      animated: true,
      color: 'light',
      position: 'bottom',
      translucent: false,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
  async noLocationSelected() {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `Please select a location`,
      duration: 10000,
      animated: true,
      color: 'light',
      position: 'top',
      translucent: false,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  async rewardErrorInfo() {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `We experienced an error getting your rewards. Please try again later.`,
      duration: 10000,
      animated: true,
      color: 'warning',
      position: 'bottom',
      translucent: false,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  async scanned() {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `Scan Complete`,
      duration: 1500,
      animated: true,
      color: 'success',
      position: 'top',
      translucent: false,
    });
    await toast.present();
  }
  async successfullyAddedPoints(rewards: IRewards) {
    const totalPoints = rewards.points ? rewards.points : 0;
    const totalPointsPluralize = totalPoints === 1 ? '' : 's';
    const prize = rewards.qualifier ? `${rewards.prize} ${rewards.qualifier}` : rewards.prize;
    const points = rewards.numberOfPointsRequired - rewards.points;
    const pluralize = points === 1 ? '' : 's';
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `Customer has ${totalPoints} point${totalPointsPluralize} \nThey are ${points} point${pluralize} away from earning \n${prize}`,
      duration: 10000,
      animated: true,
      color: 'success',
      position: 'top',
      translucent: false,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
  async invalidPickupDate(reason: string) {
    const toast = await this.toastController.create({
      keyboardClose: true,
      message: `Invalid date: ${reason}`,
      duration: 3500,
      animated: true,
      color: COLOR.NAME.DANGER,
      position: 'bottom',
      translucent: false,
    });
    await toast.present();
  }
  async showNotification(message: string) {
    const toast = await this.toastController.create({
      keyboardClose: false,
      message: message,
      duration: 80000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
      animated: true,
      color: 'warning',
      position: 'top',
      cssClass: 'dark-text',
    });
    await toast.present();
  }
  async noRewardUserFound() {
    const toast = await this.toastController.create({
      keyboardClose: false,
      message: `User Not Found`,
      duration: 2500,
      animated: true,
      color: 'danger',
      position: 'top',
      translucent: false,
    });
    await toast.present();
  }
}
