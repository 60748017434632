import { Injectable } from '@angular/core';

@Injectable()
export class ImageResizerService {
  private img: HTMLImageElement;

  resizeImage(base64: string | ArrayBuffer, quality: number, target: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = `${base64}`;
      image.onload = () => {
        let width = 0,
          height = 0,
          ratio = 1;
        if (image.width > image.height) {
          ratio = image.width / target;
          width = image.width / ratio;
          height = image.height / ratio;
        } else {
          ratio = image.height / target;
          width = image.width / ratio;
          height = image.height / ratio;
        }

        const canvas1 = document.createElement('canvas');
        canvas1.width = width;
        canvas1.height = height;

        const context = canvas1.getContext('2d');

        context.drawImage(image, 0, 0, width, height);
        const data = canvas1.toDataURL('image/png', quality || 0.8);
        const trimedData = this.trimPrefix(data);
        resolve(trimedData);
      };
      image.onerror = reject;
    });
  }
  /*
  resizeImage(base64: string | ArrayBuffer, quality: number, target: number): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      try {
        this.img = new Image();
        this.img.onload = () => {
          try {
            let width = 0,
              height = 0,
              resizedDataUri = null,
              ratio = 1;
            if (this.img.width > this.img.height) {
              ratio = this.img.width / target;
              width = this.img.width / ratio;
              height = this.img.height / ratio;
            } else {
              ratio = this.img.height / target;
              width = this.img.width / ratio;
              height = this.img.height / ratio;
            }
            resizedDataUri = this.imageToDataUri(this.img, width, height, quality);
            const result = this.trimPrefix(resizedDataUri);
            console.log('Orig: ' + this.img.width + ' x ' + this.img.height);
            console.log('New: ' + width + ' x ' + height);
            resolve(result);
          } catch (err) {
            reject(err);
          }
        };
        this.img.src = `${base64}`;
      } catch (error) {
        reject(error);
      }
    });
    return promise;
  }*/

  // Detecting vertical squash in loaded image.
  // Fixes a bug which squash image vertically while drawing into canvas for some images.
  // This function is from https://github.com/stomita/ios-imagefile-megapixel
  /*
  private detectVerticalSquash(img) {
    var iw = img.naturalWidth,
      ih = img.naturalHeight;
    var canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = ih;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    var data = ctx.getImageData(0, 0, 1, ih).data;
    // search image edge pixel position in case it is squashed vertically.
    var sy = 0;
    var ey = ih;
    var py = ih;
    while (py > sy) {
      var alpha = data[(py - 1) * 4 + 3];
      if (alpha === 0) {
        ey = py;
      } else {
        sy = py;
      }
      py = (ey + sy) >> 1;
    }
    var ratio = py / ih;
    return ratio === 0 ? 1 : ratio;
  } */

  /**
   * A replacement for context.drawImage
   * (args are for source and destination).
   */
  /*
  private drawImageIOSFix(ctx, img, sx, sy, sw, sh, dx, dy, dw, dh) {
    var vertSquashRatio = this.detectVerticalSquash(img);
    ctx.drawImage(
      img,
      sx * vertSquashRatio,
      sy * vertSquashRatio,
      sw * vertSquashRatio,
      sh * vertSquashRatio,
      dx,
      dy,
      dw,
      dh
    );
  }*/
  /*
  private imageToDataUri(img, width, height, quality) {
    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');
    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;
    // draw source image into the off-screen canvas:
    this.drawImageIOSFix(ctx, img, 0, 0, img.width, img.height, 0, 0, width, height);
    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL('image/png', quality || 0.8); // quality = [0.0, 1.0]
  }
*/
  private trimPrefix(data) {
    const BASE64_MARKER = ';base64,';
    const base64Index = data.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const result = data.substring(base64Index);
    return result;
  }
}
