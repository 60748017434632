import { firestore } from 'firebase/app';
import { IDailyHours } from '../../../../../../shared/models/i-daily-hours';
import { differenceInCalendarDays, addDays, startOfDay, addHours, isBefore } from 'date-fns';

export class DailyHours implements IDailyHours {
  isOpen: boolean;
  start?: firestore.Timestamp;
  end?: firestore.Timestamp;

  constructor(cfg?: IDailyHours) {
    const am = addHours(startOfDay(new Date()), 8);
    const pm = addHours(startOfDay(new Date()), 20);
    this.isOpen = cfg && cfg.isOpen ? cfg.isOpen : false;
    this.start = cfg && cfg.start ? cfg.start : firestore.Timestamp.fromDate(am);
    this.end = cfg && cfg.end ? cfg.end : firestore.Timestamp.fromDate(pm);
    if (isBefore(this.end.toDate(), this.start.toDate())) {
      this.end = firestore.Timestamp.fromDate(addDays(this.end.toDate(), 1));
    }
  }

  now() {
    try {
      const startDate = this.start.toDate();
      const endDate = this.end.toDate();
      const offset = differenceInCalendarDays(new Date(), startDate);
      const after = {
        start: addDays(startDate, offset).toISOString(),
        end: addDays(endDate, offset).toISOString(),
        open: this.isOpen
      };
      console.log({ after });
      return after;
    } catch (e) {
      console.log('error getting now', e);
      return null;
    }
  }
}
