<ion-header style="opacity: 0.99;">
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>Locations</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen style="opacity: 0.99;">
  <!-- location list with distance -->
  <ion-list class="ion-margin-top" *ngIf="locations">
    <ion-list-header>
      <ion-label>Select a location</ion-label>
    </ion-list-header>

    <ion-item class="hover-item" *ngFor="let location of locations" detail="true" (click)="selectLocation(location)">
      <ion-label>
        <small *ngIf="location.title" class="location-title" style="white-space: normal;">
          {{ location.title }}
        </small>
        <br *ngIf="location.title" />
        <small style="white-space: normal;"> {{ location.name }} </small> <br />
        <small *ngIf="canGetDistance && !location.distance && !(location.distance$ | async)" class="blink_me">
          Calculating distance
        </small>
        <small *ngIf="location.distance"> {{ location.distance.duration.text }} or {{ location.distance.distance.text }} away </small>
        <span *ngIf="!location.distance">
          <small *ngIf="location.distance$ | async as distance"> {{ distance.duration.text }} or {{ distance.distance.text }} away </small>
        </span>

        <br />
      </ion-label>
      <ion-icon slot="start" name="navigate"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>
