import { SearchOrdersPipe } from './search-orders.pipe';
import { SearchMenuItemsPipe } from './search-menu-items.pipe';
import { PhonePipe } from './phone.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SortByRowPipe } from './sort-by-row.pipe';
import { OptionLocationLabelPipe } from './option-location-label.pipe';
import { LocationNamePipe } from './location-name.pipe';
import { SearchMenusPipe } from './search-menus.pipe';
import { SearchCategoriesPipe } from './search-categories.pipe';
import { SearchOptionGroupsPipe } from './search-option-groups.pipe';
import { SearchLocationsPipe } from './search-locations.pipe';
import { SearchClientsPipe } from './search-clients.pipe';
import { SearchApiKeysPipe } from './search-api-keys.pipe';
import { AtmPipe } from './atm.pipe';
import { LookupNameByKeyPipe } from './lookup-name-by-key';
import { SearchUserRoles } from './search-user-roles.pipe';
import { SortByRolePipe } from './sort-by-role.pipe';
import { SearchUserFeedbackPipe } from './search-user-feedback.pipe';

@NgModule({
  imports: [CommonModule, IonicModule],
  entryComponents: [],
  exports: [
    TimeAgoPipe,
    SortByRowPipe,
    OptionLocationLabelPipe,
    PhonePipe,
    LocationNamePipe,
    SearchMenuItemsPipe,
    SearchOrdersPipe,
    SearchMenusPipe,
    SearchCategoriesPipe,
    SearchOptionGroupsPipe,
    SearchLocationsPipe,
    SearchClientsPipe,
    SearchApiKeysPipe,
    AtmPipe,
    LookupNameByKeyPipe,
    SearchUserRoles,
    SortByRolePipe,
    SearchUserFeedbackPipe
  ],
  declarations: [
    TimeAgoPipe,
    SortByRowPipe,
    OptionLocationLabelPipe,
    PhonePipe,
    LocationNamePipe,
    SearchMenuItemsPipe,
    SearchOrdersPipe,
    SearchMenusPipe,
    SearchCategoriesPipe,
    SearchOptionGroupsPipe,
    SearchLocationsPipe,
    SearchClientsPipe,
    SearchApiKeysPipe,
    AtmPipe,
    LookupNameByKeyPipe,
    SearchUserRoles,
    SortByRolePipe,
    SearchUserFeedbackPipe
  ],
  providers: []
})
export class SharedPipesModule {}
