<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button tabindex="6" color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>

    <ion-title>Profile</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <app-members-only *ngIf="!auth.isSignedIn" featureName="user profile"></app-members-only>

  <ion-grid *ngIf="auth.isSignedIn">
    <ion-row>
      <ion-col class="ion-padding ion-margin ion-text-center" size="4" offset="4">
        <ion-icon class="profile-icon" *ngIf="!auth.user.photoURL" name="person-circle-outline" color="secondary" size="large"> </ion-icon>
        <img class="profile-img" *ngIf="auth.user.photoURL && auth.user.photoURL.length > 0" [src]="auth.user.photoURL" />
      </ion-col>
    </ion-row>

    <ion-row *ngIf="providers && providers.length > 0">
      <ion-col size="12" class="ion-padding ion-text-center">
        <small style="display: block; text-decoration: underline;">Linked With</small>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="providers && providers.length > 0">
      <ion-col *ngFor="let provider of providers" class="ion-text-center">
        <ion-icon [color]="provider.color" class="fab-icon" [name]="provider.name"></ion-icon>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-no-padding ion-no-margin" size="12" *ngIf="user">
        <ion-item *ngIf="user.email">
          <ion-label position="floating">Email</ion-label>
          <ion-input disabled read-only tabindex="0" type="email" [value]="user.email"></ion-input>
        </ion-item>
        <ion-item *ngIf="user.displayName">
          <ion-label position="floating">Name</ion-label>
          <ion-input disabled read-only tabindex="1" [value]="user.displayName"></ion-input>
        </ion-item>
        <ion-item *ngIf="auth.user">
          <ion-label position="floating">Phone Number</ion-label>
          <ion-row>
            <app-phone-input (phoneNumberChange)="phoneNumberChange($event)" [phoneNumberInput]="phoneNumber"> </app-phone-input>
            <ion-spinner *ngIf="uploadingPhoneNumber" name="crescent"></ion-spinner>
          </ion-row>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-padding">
        <ion-button style="text-decoration: underline;" fillcolor="danger" fill="clear" expand="block" size="small" (click)="signOut()">
          Sign Out
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
