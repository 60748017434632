<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>Transfer Items</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-card color="white">
      <ion-card-header>
        <ion-card-title>Clients</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-list *ngIf="clients$ | async as clients">
          <ion-item *ngFor="let client of clients">
            <ion-label>{{client.name}}</ion-label>
            <ion-button (click)="itemsDestination(client.id)">Move Here</ion-button>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-grid>
</ion-content>
