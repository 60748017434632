const VERSION = require('../../package.json').version;

// Production
export const environment = {
  domain: null,
  production: true,
  version: VERSION,
  trackjs: {
    application: 'production',
    token: '971bc65f7b5d4226a38a7a2b0c5f4004',
    version: VERSION,
  },
  firebase: {
    apiKey: 'AIzaSyCSwc3VZ5IiLVeAi36cyxINmuBEBwXxvkI',
    authDomain: 'threebapps.firebaseapp.com',
    databaseURL: 'https://threebapps.firebaseio.com',
    projectId: 'threebapps',
    storageBucket: 'threebapps.appspot.com',
    messagingSenderId: '1001660133162',
    appId: '1:1001660133162:web:49df7f5dbf46c9229a3853',
  },
};
