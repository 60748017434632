import { NavController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { ModalsService } from '../../../modals/modals.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-members-only',
  templateUrl: './members-only.component.html',
  styleUrls: ['./members-only.component.scss']
})
export class MembersOnlyComponent implements OnInit {
  @Input()
  featureName = 'this feature';
  public href: string;

  constructor(
    public auth: AuthService,
    private modals: ModalsService,
    private router: Router,
    private navCtrl: NavController
  ) {}

  ngOnInit() {
    this.href = this.router.url;

    this.auth.user$.subscribe(user => {
      if (user && this.href.includes('backUrl=')) {
        const backUrl = this.href.split('backUrl=');
        const url = backUrl[1].replace(/%2F/g, '/');
        this.navCtrl.navigateForward(url);
      }
    });
  }

  async showLogin() {
    await this.modals.showLogin();
  }
  async signOut() {
    await this.auth.signOut();
  }

  async showSignUp() {
    this.modals.showSignUp();
  }
}
