
import { WeeklyHours } from './../../shared/components/weekly-hours/weekly-hours.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { filter, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocationService } from '../../shared/providers/location.service';

@Component({
  selector: 'app-hours-modal',
  templateUrl: './hours-modal.component.html',
  styleUrls: ['./hours-modal.component.scss']
})
export class HoursModalComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  hours: WeeklyHours;
  constructor(public modalController: ModalController, private locationService: LocationService) {}

  ngOnInit() {
    this.subscription = this.locationService.location$
      .pipe(
        filter(location => !!location && !!location.hours),
        first()
      )
      .subscribe(location => {
        this.hours = new WeeklyHours(location.hours);
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
