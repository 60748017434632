import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { DebugMenuModalComponent } from './debug-menu-modal/debug-menu-modal.component';

import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { MapModalComponent } from './map-modal/map-modal.component';
import { HoursModalComponent } from './hours-modal/hours-modal.component';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { LocationsModalComponent } from './locations-modal/locations-modal.component';
import { OrderHistoryComponent } from './order-history-modal/order-history-modal.component';
import { TransferMenuModalComponent } from './transfer-menu-modal/transfer-menu-modal.component';
import { IMenu } from '../../../../shared/models/i-menu';
import { TransferItemsModalComponent } from './transfer-items-modal/transfer-items-modal.component';

@Injectable()
export class ModalsService {
  constructor (public modalController: ModalController) {}

  async dismiss () {
    this.modalController.dismiss();
  }

  async showLocations () {
    const modal = await this.modalController.create({
      component: LocationsModalComponent,
      keyboardClose: true
    });
    await modal.present();
  }

  async showSignUp () {
    const props = { isLogin: false };
    const modal = await this.modalController.create({
      component: LoginModalComponent,
      keyboardClose: true,
      componentProps: props
    });
    await modal.present();
  }

  async showLogin () {
    const props = { isLogin: true };
    const modal = await this.modalController.create({
      component: LoginModalComponent,
      keyboardClose: true,
      componentProps: props
    });
    await modal.present();
  }

  async showMap () {
    const modal = await this.modalController.create({
      component: MapModalComponent,
      keyboardClose: true
    });
    return await modal.present();
  }

  async showProfile () {
    const modal = await this.modalController.create({
      component: ProfileModalComponent,
      keyboardClose: true
    });
    await modal.present();
  }

  async showHours () {
    const modal = await this.modalController.create({
      component: HoursModalComponent,
      keyboardClose: true
    });
    return await modal.present();
  }

  async showOrderHistory () {
    const modal = await this.modalController.create({
      component: OrderHistoryComponent,
      keyboardClose: true
    });
    await modal.present();
  }

  async showDebugMenu () {
    const modal = await this.modalController.create({
      component: DebugMenuModalComponent,
      keyboardClose: true
    });
    await modal.present();
  }

  async showFeedbackPage () {
    const modal = await this.modalController.create({
      component: FeedbackModalComponent,
      keyboardClose: true
    });
    await modal.present();
  }

  async showTransferMenuPage (menu: IMenu, selectedClientId: string) {
    const options = {
      menu,
      selectedClientId
    };
    const modal = await this.modalController.create({
      componentProps: options,
      component: TransferMenuModalComponent,
      keyboardClose: true
    });
    return await modal.present();
  }
  async showTransferItemsPage (selectedClientId: string) {
    const options = {
      selectedClientId
    };
    const modal = await this.modalController.create({
      componentProps: options,
      component: TransferItemsModalComponent,
      keyboardClose: true
    });
    return await modal.present();
  }
}
