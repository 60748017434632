<ion-header class="header-container">
  <ion-toolbar color="white" style="--border-color: white;">
    <ion-button class="close-button" tabindex="8" color="medium" fill="clear" size="small" (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Logo Section -->
  <div style="text-align: center;">
    <span class="logo-container">
      <span>
        <svg
          class="three-b-apps-logo"
          version="1.1"
          id="logo"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 128.4 124.1"
          style="enable-background: new 0 0 128.4 124.1;"
          xml:space="preserve"
        >
          <style type="text/css"></style>
          <g>
            <path
              class="st0"
              d="M12.2,56c0,14.1,0,28.2,0,42.3c0,1.7,0,1.7,1.6,1.7c17.7,0,35.3,0,53,0c1.6,0,1.6,0,1.6-1.7c0-4,0-8,0-12
                 c0-1,0.3-1.4,1.3-1.3c1.3,0.1,2.6,0.1,3.9,0c0.8,0,1.2,0.2,1.2,1.1c0,5.4,0,10.9,0,16.3c0,4.8,0,9.6,0,14.4c0,1.9-2.2,4.4-4.1,4.1
                 c-0.9-0.1-1.8,0.2-2.7,0.2c-18.9,0-37.8-0.1-56.7-0.1c-1.7,0-3.2-0.1-4.2-1.5c-0.6-0.8-1.1-1.9-1.2-2.9c-0.1-1.6,0.1-3.2,0.1-4.8
                 c0-32.9,0-65.8,0-98.7c0-1.2,0-2.4-0.2-3.6c-0.3-2.4,2.3-4.6,4.6-4.6C20.5,5,30.6,5,40.6,5.1c9.6,0,19.2,0,28.8,0
                 c0.7,0,1.5-0.2,2.1,0c2.7,1,3.3,2.7,3.3,5.4c0,1-0.1,1.9-0.1,2.9c0,5.9,0,11.8,0,17.7c0,1.1,0.1,2.1,0.1,3.2c0,0.7-0.2,1.1-1,1
                 c-1.4,0-2.7-0.1-4.1,0c-1,0.1-1.2-0.4-1.2-1.3c0-5.1,0-10.2,0-15.3c0-1.8,0-3.5,0-5.3c0-1-0.3-1.2-1.3-1.2c-13.3,0-26.6,0-39.9,0
                 c-4.5,0-9,0-13.5,0c-1.6,0-1.6,0-1.6,1.6C12.2,27.8,12.2,41.9,12.2,56z M46.4,110.1c-0.1-4.3-3.3-6.1-6.2-6.1c-3.3,0-6,3-5.9,6.3
                 c0.1,3.5,3,6,6.3,5.9C43.9,116.2,46.5,113.4,46.4,110.1z"
            />
            <path
              class="st1"
              d="M59.7,60.1c0-5.5,0.1-11,0-16.4c-0.1-3.8,2.8-5.6,5.7-5.6c11,0.1,21.9,0,32.9,0c3.5,0,5.6,2.1,5.6,5.6
                 c0,8.1,0,16.2,0,24.3c0,1-0.3,1.4-1.3,1.3c-2.8,0-5.5,0-8.3,0c-3.2,0-5.7,2.4-5.8,5.5c-0.1,2.1-0.1,4.2,0,6.3
                 c0,0.8-0.3,1.1-1.1,1.1c-7.7,0-15.4,0-23.1-0.1c-2.4,0-4.5-2.2-4.5-4.8C59.7,71.6,59.7,65.8,59.7,60.1z"
            />
            <path
              class="st2"
              d="M106.7,54.4c0-1.2,0-2.3,0-3.5c0-2.5,0-5.1,0-7.6c0.1-4.5-3.6-8.2-8.1-8.3c-2.6,0-5.2,0-7.9,0
                 c0-1.6,0-3.1,0-4.6c0-1.7,0-3.5,0.1-5.2c0-2.3,1.5-3.8,3.8-3.8c8.4,0,16.9,0,25.3,0c2.1,0,3.7,1.4,3.8,3.6c0.1,1.9,0.1,3.9,0.1,5.8
                 c0,6.5-0.1,13-0.1,19.4c0,2.5-1.5,4.1-3.9,4.1C115.5,54.5,111.2,54.4,106.7,54.4z"
            />
            <path
              class="st3"
              d="M116.3,84.8c0,3.2,0,6.3,0,9.5c0,1.9-1,3-2.9,3c-6.3,0-12.7,0-19,0c-1.7,0-3-1.2-3-2.9c0-6.4,0-12.8,0-19.2
                 c0-1.8,1.2-2.9,3.1-2.9c6.4,0,12.7,0,19.1,0c1.6,0,2.7,1.1,2.7,2.7C116.3,78.3,116.3,81.5,116.3,84.8z"
            />
            <path
              class="st4"
              d="M52.2,65.1c0,3.2,0,6.3,0,9.5c0,1.9-1,3-2.9,3c-6.3,0-12.7,0-19,0c-1.7,0-3-1.2-3-2.9c0-6.4,0-12.8,0-19.2
                 c0-1.8,1.2-2.9,3.1-2.9c6.4,0,12.7,0,19.1,0c1.6,0,2.7,1.1,2.7,2.7C52.2,58.6,52.2,61.9,52.2,65.1z"
            />
            <path
              class="st5"
              d="M46.4,110.1c0.1,3.3-2.5,6.1-5.9,6.1c-3.2,0-6.1-2.4-6.3-5.9c-0.1-3.3,2.6-6.3,5.9-6.3
                 C43,104,46.3,105.8,46.4,110.1z"
            />
          </g>
        </svg>
      </span>
      <span class="logo-text">3B Apps</span>
    </span>
    <div class="threebapps-message">
      Sign up once, use with any 3B Apps powered restaurant.
    </div>
  </div>

  <div class="form-options-container">
    <span *ngIf="isLogin" style="font-weight: bold;" class="option" (click)="showLogin()" [ngClass]="{ active: isLogin }">
      Login
    </span>
    <span *ngIf="!isLogin" class="option" (click)="showLogin()" [ngClass]="{ active: isLogin }">
      Login
    </span>
    <span *ngIf="!isLogin" style="font-weight: bold;" class="option" (click)="showSignUp()" [ngClass]="{ active: !isLogin }">
      Signup
    </span>
    <span *ngIf="isLogin" class="option" (click)="showSignUp()" [ngClass]="{ active: !isLogin }">
      Signup
    </span>
  </div>
  <div class="form-container"></div>

  <!-- Login Section -->
  <div *ngIf="isLogin" class="ion-padding">
    <form name="loginForm" #loginForm="ngForm" (ngSubmit)="login()">
      <div class="input-icon-container">
        <ion-icon name="mail"></ion-icon>
        <ion-input
          clearInput
          [(ngModel)]="email"
          inputmode="email"
          placeholder="Email"
          name="email"
          tabindex="1"
          type="email"
          class="brand-input"
          required
        ></ion-input>
      </div>

      <div class="input-icon-container">
        <ion-icon name="lock-closed"></ion-icon>
        <ion-input
          clearOnEdit="false"
          [(ngModel)]="password"
          placeholder="Password"
          name="password"
          tabindex="2"
          type="password"
          class="brand-input"
          required
        ></ion-input>
      </div>

      <div class="forgot-password-container">
        <ion-button class="forgot-password-button" tabindex="3" color="primary" fill="clear" size="small" (click)="forgotPassword()"
          >Forgot Password?</ion-button
        >
      </div>

      <ion-button type="submit" form="loginForm" tabindex="5" fill="solid" expand="block" class="main-action-button" [disabled]="loginForm.invalid">
        Login
      </ion-button>
    </form>

    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col class="hr-label-col">
          <app-hr-label text="Or Sign In With"></app-hr-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="text-align-center" *ngIf="isAppleAvailable">
          <ion-button tabindex="4" fill="solid" size="small" class="apple-color sign-in-options" (click)="appleAuth()">
            <ion-icon color="light" class="fab-icon" name="logo-apple"></ion-icon>
            <ion-label color="light">&nbsp; Continue with Apple</ion-label>
          </ion-button>
        </ion-col>
        <ion-col *ngIf="isGoogleAvailable" class="text-align-center">
          <ion-button tabindex="5" fill="solid" size="small" class="google-color sign-in-options" (click)="googleAuth()">
            <ion-icon color="light" class="fab-icon" name="logo-google"></ion-icon>
            <ion-label color="light">&nbsp; Google</ion-label>
          </ion-button>
        </ion-col>
        <ion-col *ngIf="isFacebookAvailable" class="text-align-center">
          <ion-button tabindex="6" fill="solid" size="small" class="facebook-color sign-in-options" (click)="facebookAuth()">
            <ion-icon color="light" class="fab-icon" name="logo-facebook"></ion-icon>
            <ion-label color="light">&nbsp; Facebook</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="privacy-and-terms-col">
          <app-privacy-and-terms tabindex="8"></app-privacy-and-terms>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!-- Sign Up Section -->
  <div class="ion-padding" *ngIf="!isLogin">
    <form name="signUpForm" #signUpForm="ngForm" (ngSubmit)="onSubmit(signUpForm)">
      <div class="input-icon-container">
        <ion-icon name="mail"></ion-icon>
        <ion-input [(ngModel)]="email" placeholder="Email" name="email" tabindex="1" type="email" class="brand-input" required></ion-input>
      </div>
      <div class="input-icon-container">
        <ion-icon name="person"></ion-icon>
        <ion-input [(ngModel)]="displayName" placeholder="Name" name="displayName" tabindex="2" type="text" class="brand-input" required></ion-input>
      </div>
      <div class="brand-input">
        <ion-row>
          <ion-icon class="phone-icon" name="call"> </ion-icon>
          <app-phone-input
            [style.color]="phoneNumberIsValid ? 'green' : 'red'"
            style="margin-left: 39px;"
            (phoneNumberChange)="phoneNumberChange($event)"
            (phoneNumberIsValid)="phoneNumberIsValidChange($event)"
          >
          </app-phone-input>
        </ion-row>
      </div>
      <div class="input-icon-container">
        <ion-icon name="lock-closed"></ion-icon>
        <ion-input
          [(ngModel)]="password"
          placeholder="Password"
          name="password"
          tabindex="4"
          type="password"
          class="brand-input"
          required
        ></ion-input>
      </div>

      <ion-button
        type="submit"
        form="signUpForm"
        tabindex="3"
        fill="solid"
        expand="block"
        class="main-action-button sign-up-button"
        [disabled]="signUpForm.invalid"
      >
        Sign Up
      </ion-button>
    </form>
    <ion-grid>
      <ion-row>
        <ion-col class="hr-label-col">
          <app-hr-label text="Or Sign Up With"></app-hr-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="text-align-center" *ngIf="isAppleAvailable">
          <ion-button tabindex="4" fill="solid" size="small" class="apple-color sign-in-options" (click)="appleAuth()">
            <ion-icon color="light" class="fab-icon" name="logo-apple"></ion-icon>
            <ion-label color="light">&nbsp; Continue with Apple</ion-label>
          </ion-button>
        </ion-col>
        <ion-col class="text-align-center" *ngIf="isGoogleAvailable">
          <ion-button *ngIf="isGoogleAvailable" tabindex="5" fill="solid" size="small" class="google-color sign-in-options" (click)="googleAuth()">
            <ion-icon color="light" class="fab-icon" name="logo-google"></ion-icon>
            <ion-label color="light">&nbsp; Google</ion-label>
          </ion-button>
        </ion-col>
        <ion-col class="text-align-center" *ngIf="isFacebookAvailable">
          <ion-button tabindex="6" fill="solid" size="small" class="facebook-color sign-in-options" (click)="facebookAuth()">
            <ion-icon color="light" class="fab-icon" name="logo-facebook"></ion-icon>
            <ion-label color="light">&nbsp; Facebook</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <app-privacy-and-terms></app-privacy-and-terms>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
