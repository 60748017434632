import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IMenuItem } from '../../../../../shared/models/i-menu-item';

@Injectable()
export class ItemBuilderService {
  private isEdit = false;
  private isEditSubject = new BehaviorSubject<boolean>(this.isEdit);
  private itemSubject = new BehaviorSubject<IMenuItem | null>(null);
  isEdit$ = this.isEditSubject.asObservable();
  item$ = this.itemSubject.asObservable();

  constructor() {}

  editItem(item: IMenuItem) {
    this.isEdit = true;
    this.isEditSubject.next(this.isEdit);
    this.itemSubject.next(item);
  }

  reset() {
    this.isEdit = false;
    this.isEditSubject.next(false);
    this.itemSubject.next(null);
  }
}
