import { IUser } from './../../../../../shared/models/i-user';
import { DeviceService } from './../../shared/providers/device.service';
import { LocationService } from './../../shared/providers/location.service';
import { ClientService } from './../../shared/providers/client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from '../../shared/providers/auth.service';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { API } from '../../../../../shared/constants/api-endpoints';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();
  loading: HTMLIonLoadingElement;
  clientId: string;
  locationId: string;
  message: string;
  user: IUser;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    private auth: AuthService,
    private afFunctions: AngularFireFunctions,
    private clientService: ClientService,
    private locationService: LocationService,
    private deviceService: DeviceService
  ) {}

  async ngOnInit() {
    await this.showLoading();
    const sub = combineLatest([
      this.clientService.clientId$,
      this.locationService.locationId$,
      this.auth.getUser()
    ])
      .pipe(
        filter(([clientId]) => !!clientId),
        map(([clientId, locationId, user]) => {
          this.clientId = clientId;
          this.locationId = locationId;
          this.user = user;
          this.hideLoading();
        })
      )
      .subscribe();
    this.subscriptions.push(sub);
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
  async showLoading() {
    this.loading = await this.loadingController.create({
      translucent: true
    });
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }
  async submit() {
    const platform = await this.deviceService.getPlatformType();
    const token = await this.auth.getIdToken();
    const callable = this.afFunctions.httpsCallable(API.submitFeedback);
    await callable({
      idToken: token,
      message: this.message,
      name: this.user.displayName,
      userId: this.auth.user.uid,
      locationId: this.locationId,
      clientId: this.clientId,
      platform: platform
    });
    this.dismiss();
  }
}
