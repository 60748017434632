import { AuthService } from './auth.service';
import { IPrintOrderRequest } from './../../../../../shared/models/i-print-order-request';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  AngularFirestore,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DB } from '../../../../../shared/constants/db';
import { API } from '../../../../../shared/constants/api-endpoints';
import { IClient } from '../../../../../shared/models/i-client';
import { IClientLocation } from '../../../../../shared/models/i-client-location';
import { IMenu } from '../../../../../shared/models/i-menu';
import { IOrder } from '../../../../../shared/models/i-order';
import { IOrderIds } from '../../../../../shared/models/i-order-ids';

@Injectable()
export class AdminClientService {
  constructor(
    private afFunctions: AngularFireFunctions,
    public afs: AngularFirestore,
    private auth: AuthService
  ) {}

  createClient(client: IClient) {
    this.afs
      .collection(DB.CLIENTS.ID)
      .add(client)
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.log('TCL: createClient -> error', error);
      });
  }

  createLocation(clientId: string, location: IClientLocation) {
    this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .add(location)
      .then(result => {
        console.log(result);
      })
      .catch(error => console.log(error));
  }

  createClientMenu(clientId: string, menu: IMenu) {
    this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.MENUS.ID)
      .add(menu)
      .then(result => {
        console.log(result);
      })
      .catch(error => console.log(error));
  }

  createLocationMenu(clientId: string, locationId: string, menu: IMenu) {
    this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection(DB.CLIENTS.LOCATIONS.MENUS.ID)
      .add(menu)
      .then(result => {
        console.log(result);
      })
      .catch(error => console.log(error));
  }

  getClients$(): Observable<QueryDocumentSnapshot<IClient>[]> {
    return this.afs
      .collection<IClient>(DB.CLIENTS.ID)
      .snapshotChanges()
      .pipe(map(snapshots => snapshots.map(snapshot => snapshot.payload.doc)));
  }

  getClient$(clientId: string): Observable<DocumentSnapshot<IClient>> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc<IClient>(clientId)
      .snapshotChanges()
      .pipe(map(snapshot => snapshot.payload));
  }

  getLocations$(clientId: string): Observable<QueryDocumentSnapshot<IClientLocation>[]> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection<IClientLocation>(DB.CLIENTS.LOCATIONS.ID)
      .snapshotChanges()
      .pipe(map(snapshots => snapshots.map(snapshot => snapshot.payload.doc)));
  }

  getLocation$(
    clientId: string,
    locationId: string
  ): Observable<DocumentSnapshot<IClientLocation>> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc<IClientLocation>(locationId)
      .snapshotChanges()
      .pipe(map(snapshot => snapshot.payload));
  }

  getOrders$(clientId: string, locationId: string): Observable<QueryDocumentSnapshot<IOrder>[]> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID)
      .snapshotChanges()
      .pipe(map(snapshots => snapshots.map(snapshot => snapshot.payload.doc)));
  }

  getOrder$(
    clientId: string,
    locationId: string,
    orderId: string
  ): Observable<DocumentSnapshot<IOrder>> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection(DB.CLIENTS.LOCATIONS.ORDERS.ID)
      .doc<IOrder>(orderId)
      .snapshotChanges()
      .pipe(map(snapshot => snapshot.payload));
  }

  getOrderRef(ids: IOrderIds): AngularFirestoreDocument<IOrder> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(ids.client)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(ids.location)
      .collection(DB.CLIENTS.LOCATIONS.ORDERS.ID)
      .doc<IOrder>(ids.order);
  }
  async printOrder(order: IOrder, clientId: string, locationId: String) {
    const idToken = await this.auth.getIdToken();
    return new Promise<any>((resolve, reject) => {
      const callable = this.afFunctions.httpsCallable(API.printOrder);
      callable({
        locationId,
        idToken,
        orderId: order.id,
        clientId
      } as IPrintOrderRequest).subscribe(
        res => {
          resolve(res);
        },
        err => reject(err)
      );
    });
  }
}
