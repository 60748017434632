import { FeeTypes, FeeType } from './../constants/fee-type';
import { ICoupon } from './i-coupon';
import { ICart } from './i-cart';

import { IEarnedReward } from './i-earned-reward';
import { DiscountTypes } from '../constants/discount-type';

/**
 * limits the value to 2 decimals places
 */
export const asCurrency = (value: number, decimalPlaces = 2) => {
  return parseFloat(value.toFixed(decimalPlaces));
};

export const sum = (accumulator: number, currentValue: number) => {
  const newValue = accumulator + currentValue;
  const fixedValue = newValue.toFixed(2);
  const result = parseFloat(fixedValue);
  return result;
};

const lastOrOnly = (p, c) => {
  return c;
};

interface IDiscount {
  coupon: ICoupon;
  value: number;
}

export class Cart {
  static newCart(timestamp?: string) {
    return {
      coupons: [],
      items: [],
      netDiscount: 0,
      netPrice: 0,
      subtotal: 0,
      tip: 0,
      processingFee: 0,
      tax: 0,
      total: 0,
      lastUpdated: timestamp ? timestamp : new Date().toISOString()
    } as ICart;
  }

  static recalculate(cart: ICart): ICart {
    if (!cart.items || cart.items.length === 0) {
      cart.coupons = [];
      cart.items = [];
    }
    cart.tax = cart.tax ? cart.tax : 0;
    cart.taxRate = cart.taxRate ? cart.taxRate : 0;
    cart.total = cart.total ? cart.total : 0;
    cart.tip = cart.tip ? cart.tip : 0;
    cart.feeType = cart.feeType = cart.feeType ? cart.feeType : (FeeTypes.VALUE as FeeType);
    cart.feeEnabled = cart.feeEnabled === true;

    const netPrice = this.calculateNetPrice(cart);
    const netDiscount = this.calculateDiscount(cart, netPrice);
    const subTotal = asCurrency(netPrice - netDiscount);
    const processingFee = cart.feeEnabled
      ? asCurrency(cart.feeType === FeeTypes.RATE ? subTotal * cart.feeValue : cart.feeValue)
      : 0;
    const tax = asCurrency((subTotal + processingFee) * cart.taxRate);
    const tip = asCurrency(cart.tip ? cart.tip : 0);
    const total = asCurrency(subTotal + tax + tip + processingFee);

    cart.netDiscount = netDiscount;
    cart.processingFee = processingFee;
    cart.netPrice = netPrice;
    cart.subtotal = subTotal;
    cart.tax = tax;
    cart.tip = tip;
    cart.total = total;
    cart.lastUpdated = new Date().toISOString();
    return cart;
  }

  static calculateDiscount(cart: ICart, netPrice: number) {
    if (!netPrice) {
      return 0; // can't discount a price of 0
    }
    const discountRate = this.calculateNetDiscountRate(cart); // percentage of price to be discounted
    const itemDiscount = this.calculateNetItemDiscount(cart); // value of items to be discounted
    let valueDiscount = this.calculateNetValueDiscount(cart); // amount to be discounted
    valueDiscount = valueDiscount + itemDiscount; // combine "flat" discounts
    valueDiscount = valueDiscount > netPrice ? netPrice : valueDiscount; // value discount cant be larger than amount
    const netDiscountableValue = asCurrency(netPrice - valueDiscount); // rate discount determined AFTER other discounts are applied.
    const rateDiscount = asCurrency(netDiscountableValue * discountRate); // value to be discount if x% off
    let discount = asCurrency(valueDiscount + rateDiscount); // combine value based and rate based discounts
    discount = discount > netPrice ? netPrice : discount; // if discount is larger than price discount equals the price
    return discount;
  }

  static calculateNetPrice(cart: ICart): number {
    const itemPrices = cart.items.map(item => {
      const prices = item.options.map(option => {
        return option.price;
      });
      prices.push(item.price);
      return prices.reduce(sum);
    });
    if (itemPrices.length === 0) {
      return 0;
    }
    return itemPrices.reduce(sum);
  }

  static calculateNetValueDiscount(cart: ICart): number {
    const values = cart.coupons
      .filter(c => c.type === DiscountTypes.VALUE)
      .filter(c => !!c.value)
      .map(c => c.value);
    if (values.length === 0) {
      return 0;
    }
    return values.reduce(sum);
  }

  static calculateNetDiscountRate(cart: ICart): number {
    const rates = cart.coupons.filter(c => c.type === DiscountTypes.RATE).map(c => c.value);
    if (rates.length === 0) {
      return 0;
    }
    return rates.reduce(sum);
  }

  static calculateNetItemDiscount(cart: ICart): number {
    const discounts = cart.coupons
      .filter(c => c.type === DiscountTypes.ITEM)
      .filter(c => !!c.itemDiscount)
      .map(c => c.itemDiscount);
    if (discounts.length === 0) {
      return 0;
    }
    return discounts.reduce(sum);
  }

  static bestValueDiscount(cart: ICart, earned: IEarnedReward[]): ICoupon {
    return earned
      .filter(reward => {
        return reward.type === DiscountTypes.VALUE;
      })
      .map(
        (reward: IEarnedReward): ICoupon => {
          const coupon = {
            name: reward.prize,
            code: reward.couponCode,
            type: reward.type,
            value: reward.value,
            valueDiscount: asCurrency(reward.value > cart.netPrice ? cart.netPrice : reward.value)
          } as ICoupon;
          if (reward.icon) {
            coupon.icon = reward.icon;
          }
          if (reward.qualifier) {
            coupon.qualifier = reward.qualifier;
          }
          return coupon;
        }
      )
      .reduce(lastOrOnly, null);
  }

  static bestRateDiscount(cart: ICart, earned: IEarnedReward[]): ICoupon {
    return earned
      .filter(reward => {
        return reward.type === DiscountTypes.RATE;
      })
      .map(reward => {
        return {
          name: reward.prize,
          icon: reward.icon,
          qualifier: reward.qualifier,
          code: reward.couponCode,
          type: reward.type,
          rateDiscount: asCurrency(cart.netPrice * reward.value),
          value: reward.value
        } as ICoupon;
      })
      .sort((a, b) => {
        if (a.value > b.value) {
          return -1;
        }
        if (a.value < b.value) {
          return 1;
        }
        return 0;
      })
      .reduce((p, c) => {
        return c;
      }, null);
  }

  static bestItemDiscount(cart: ICart, earned: IEarnedReward[]): ICoupon {
    const results = [];
    earned.forEach(reward => {
      if (reward.type !== DiscountTypes.ITEM || !cart || !cart.items || cart.items.length === 0) {
        return null;
      }
      cart.items.forEach(item => {
        reward.items.forEach(allowed => {
          if (allowed.id !== item.id) {
            return;
          }
          results.push({
            itemId: item.id,
            couponCode: reward.couponCode,
            name: reward.prize,
            total: item.total,
            items: reward.items,
            qualifier: reward.qualifier,
            type: reward.type
          });
        });
      });
      return results;
    });

    const result = results
      .filter(x => !!x)
      .sort((a, b) => {
        if (a.total > b.total) {
          return -1;
        }

        if (a.total < b.total) {
          return 1;
        }
        return 0;
      })
      .shift();

    if (!result) {
      return null;
    }
    const coupon = {
      name: result.name,
      items: result.items,
      appliedTo: result.itemId,
      code: result.couponCode,
      itemDiscount: result.total,
      type: result.type
    } as ICoupon;
    if (result.icon) {
      coupon.icon = result.icon;
    }
    if (result.qualifier) {
      coupon.qualifier = result.qualifier;
    }

    return coupon;
  }

  static bestDiscount(cart: ICart, earnedRewards: IEarnedReward[]): ICoupon {
    const itemCoupon = Cart.bestItemDiscount(cart, earnedRewards);
    const valueCoupon = Cart.bestValueDiscount(cart, earnedRewards);
    const rateCoupon = Cart.bestRateDiscount(cart, earnedRewards);
    const discounts: IDiscount[] = [];
    if (itemCoupon) {
      discounts.push({ coupon: itemCoupon, value: itemCoupon.itemDiscount });
    }
    if (valueCoupon) {
      discounts.push({ coupon: valueCoupon, value: valueCoupon.value });
    }
    if (rateCoupon) {
      discounts.push({ coupon: rateCoupon, value: asCurrency(cart.netPrice * rateCoupon.value) });
    }
    if (discounts.length === 0) {
      return null;
    }
    return discounts
      .filter(discount => !!discount)
      .sort((a, b) => {
        if (a.value > b.value) {
          return -1;
        }
        if (a.value < b.value) {
          return 1;
        }
        return 0;
      })
      .shift().coupon;
  }
}
