import { ItemBuilderService } from './item-builder.service';
import { DomainService } from './domain.service';
import { MenuService } from './menu.service';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AuthService } from './auth.service';
import { ToastsService } from './toasts.service';
import { ModalsService } from '../../modals/modals.service';
import { RewardsService } from './rewards.service';
import { CartService } from './cart.service';
import { DistanceService } from './distance.service';
import { StorageService } from './storage.service';
import { GeolocationService } from './geolocation.service';
import { ClientService } from './client.service';
import { LocationsService } from './locations.service';
import { LocationService } from './location.service';
import { StripeCheckoutService } from './stripe-checkout.service';
import { AdminClientService } from './admin-client.service';
import { OrdersService } from './orders.service';
import { CheckoutService } from './checkout.service';
import { MessagingService } from './messaging.service';
import { ImageResizerService } from './image-resizer.service';
import { ClientBuilderService } from './client-builder.service';
import { ThemeService } from './theme.service';
import { HttpClientModule } from '@angular/common/http';
import { DeviceService } from './device.service';
import { UserService } from './user.service';
import { LocationPickupService } from './location-pickup.service';
import { AuthPlatformAvailabilityService } from './auth-platform-availability.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, IonicModule],
  entryComponents: [],
  exports: [],
  declarations: [],
  providers: [
    CurrencyPipe,
    DomainService,
    DeviceService,
    AuthService,
    ToastsService,
    ModalsService,
    RewardsService,
    CartService,
    DistanceService,
    StorageService,
    {
      provide: GeolocationService,
      useClass: GeolocationService,
      // useValue: {
      //   currentPosition$: of({ latitude: 41.1439315, longitude: -85.2008939 } as ICoordinates)
      // }
    },
    ClientService,
    LocationsService,
    LocationService,
    MenuService,
    StripeCheckoutService,
    CheckoutService,
    CartService,
    AdminClientService,
    OrdersService,
    MessagingService,
    ImageResizerService,
    ClientBuilderService,
    ThemeService,
    DeviceService,
    UserService,
    ItemBuilderService,
    LocationPickupService,
    DatePipe,
    AuthPlatformAvailabilityService,
  ],
})
export class SharedProvidersModule {}
