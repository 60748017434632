<ion-searchbar [placeholder]="placeholderText" [formControl]="searchBar"> </ion-searchbar>
<ion-segment>
  <ion-segment-button class="outter-skip-button" (click)="firstPage()">
    <ion-icon name="play-skip-back"></ion-icon>
  </ion-segment-button>
  <ion-segment-button (click)="previousPage()">
    <ion-icon name="arrow-back"></ion-icon>
  </ion-segment-button>
  <ion-segment-button disabled>
    ({{ pageNumber * pageSize }}-{{ (pageNumber + 1) * pageSize }}) /
    {{ filtered.length }}
  </ion-segment-button>
  <ion-segment-button (click)="nextPage()">
    <ion-icon name="arrow-forward"></ion-icon>
  </ion-segment-button>
  <ion-segment-button class="outter-skip-button" (click)="lastPage()">
    <ion-icon name="play-skip-forward"></ion-icon>
  </ion-segment-button>
</ion-segment>
