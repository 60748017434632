import { IClientLocation } from './../../../../../../shared/models/i-client-location';
import { WeeklyHours } from './weekly-hours.model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-weekly-hours',
  templateUrl: './weekly-hours.component.html',
  styleUrls: ['./weekly-hours.component.scss']
})
export class WeeklyHoursComponent implements OnInit, OnDestroy {
  location$: Observable<IClientLocation>;
  @Input()
  showHours = false;
  @Input()
  hours: WeeklyHours;
  loaded = false;

  ngOnDestroy() {}

  ngOnInit() {
    if (this.hours) {
      this.loaded = true;
    }
  }
}
