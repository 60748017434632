import { NavController } from '@ionic/angular';
import { AuthService } from '../providers/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private navController: NavController) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user$.pipe(
      map(user => {
        const authorized = !!user;
        if (!authorized) {
          this.navController.navigateForward(['notallowed'], {
            queryParams: { backUrl: state.url }
          });
        }
        return authorized;
      })
    );
  }
}
