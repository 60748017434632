import { ClientService } from './../shared/providers/client.service';
import { IOrderFee } from './../../../../shared/models/i-order-fee';
import { FeeTypes } from './../../../../shared/constants/fee-type';
import { IAddress } from './../../../../shared/models/i-address';
import { DB } from './../../../../shared/constants/db';
import { IClientLocation } from './../../../../shared/models/i-client-location';
import { BehaviorSubject, Observable, combineLatest, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { IWeeklyHours } from '../../../../shared/models/i-weekly-hours';
import { switchMap, first, finalize, map, filter } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { IClient } from '../../../../shared/models/i-client';
import { AlertController } from '@ionic/angular';
import { AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { IClientLocationFeatureFlags } from '../../../../shared/models/i-client-location-feature-flags';
import { IOrderAhead } from '../../../../shared/models/i-order-ahead';
import { IClientLocationOrderReceivedContacts } from '../../../../shared/models/i-client-location-order-received-contacts';

const DEFAULT_FEATUREFLAGS: IClientLocationFeatureFlags = {
  addTipToOrder: false,
  orderFee: { feeType: FeeTypes.VALUE, value: 0, enabled: false } as IOrderFee,
  orderAhead: { maxDaysInAdvance: 4, enabled: false } as IOrderAhead,
  // orderAhead: { maxDaysInAdvance: 4, hoursOrdersAppearInAdvance: 3, enabled: false } as IOrderAhead,
} as IClientLocationFeatureFlags;

const DEFAULT_ADDRESS: IAddress = {
  line1: '',
  city: '',
  state: '',
};
@Injectable()
export class LocationBuilderService implements OnDestroy {
  private subscriptions = new Array<Subscription>();
  private isEdit = false;
  private isEditSubject = new BehaviorSubject<boolean>(this.isEdit);
  private locationIdSubject = new BehaviorSubject<string | null>(null);
  private clientIdSubject = new BehaviorSubject<string | null>(null);
  private modelSubject = new BehaviorSubject<IClientLocation | null>(null);

  isEdit$ = this.isEditSubject.asObservable();
  location$: Observable<IClientLocation>;
  isOpen = {
    info: false,
    contacts: false,
    address: false,
    settings: false,
  };

  model$ = this.modelSubject.asObservable();
  model: IClientLocation;
  locationId$ = this.locationIdSubject.asObservable();
  url: string;
  uploadPercent: Observable<number>;
  error: string;
  imagePath: string;
  base64: string | ArrayBuffer;
  file: File;
  filePath: string;
  fileRef: AngularFireStorageReference;
  rawFile: any;
  clientId$: Observable<string> = this.clientIdSubject.asObservable();
  clientId: string;
  client: IClient;
  client$: Observable<IClient>;
  phoneContact1: string;
  phoneContact2: string;
  orderRecivedContacts$: Observable<IClientLocationOrderReceivedContacts>;
  emailContact1: string;
  emailContact2: string;
  emailContact3: string;

  constructor(
    private afs: AngularFirestore,
    private alertController: AlertController,
    private storage: AngularFireStorage,
    private clientService: ClientService
  ) {
    const sub = combineLatest([this.clientId$, this.locationId$])
      .pipe(
        filter(([clientId]) => !!clientId),
        map(([clientId, locationId]) => {
          this.clientId = clientId;
          this.client$ = this.clientService.getClient(clientId);

          const clientSub = this.client$.subscribe((client) => {
            this.client = client;
          });
          this.subscriptions.push(clientSub);

          if (locationId) {
            this.location$ = this.afs
              .collection(DB.CLIENTS.ID)
              .doc<IClient>(clientId)
              .collection(DB.CLIENTS.LOCATIONS.ID)
              .doc<IClientLocation>(locationId)
              .valueChanges();
          }
        })
      )
      .subscribe();
    this.subscriptions.push(sub);

    this.orderRecivedContacts$ = this.clientId$.pipe(
      first((clientId) => !!clientId),
      switchMap((clientId) => {
        return this.locationId$.pipe(
          filter((locationId) => !!locationId),
          switchMap((locationId) => {
            return this.afs
              .collection(DB.CLIENTS.ID)
              .doc<IClient>(clientId)
              .collection(DB.CLIENTS.LOCATIONS.ID)
              .doc<IClientLocation>(locationId)
              .collection(DB.CLIENTS.LOCATIONS.CONTACTS.ID)
              .doc(DB.CLIENTS.LOCATIONS.CONTACTS.DOCUMENTS.ORDER_RECEIVED)
              .valueChanges();
          })
        );
      })
    );

    // TODO make all order contacts belong on their on collection like emails are
    const modelSub = this.model$.pipe(filter((location) => !!location)).subscribe((location) => {
      this.phoneContact1 = location.orderCompleteContacts ? location.orderCompleteContacts[0] : null;
      this.phoneContact2 = location.orderCompleteContacts ? location.orderCompleteContacts[1] : null;
    });
    this.subscriptions.push(modelSub);

    const orderContactSubscription = this.orderRecivedContacts$.pipe(filter((contacts) => !!contacts)).subscribe((orderContacts) => {
      console.log('orderContacts', orderContacts);
      this.emailContact1 = orderContacts.emailContacts ? orderContacts.emailContacts[0] : null;
      this.emailContact2 = orderContacts.emailContacts ? orderContacts.emailContacts[1] : null;
      this.emailContact3 = orderContacts.emailContacts ? orderContacts.emailContacts[2] : null;
    });
    this.subscriptions.push(orderContactSubscription);
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  async preview(event: any) {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      const error = 'Only images are supported.';
      await this.showAlert('Not Supported', error);
      return;
    }
    const reader = new FileReader();
    this.file = files[0];
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.base64 = reader.result;
    };
  }

  async save() {
    if (this.file) {
      await this.uploadFile();
    } else {
      await this.updateAsset();
    }
  }

  async uploadFile() {
    this.filePath = `${this.client.domain}/${this.clientId}/locations/${this.model.id}`;
    this.fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, this.file);
    // get notified when the download URL is available
    const sub = task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          const subscription = this.fileRef.getDownloadURL().subscribe((url) => {
            this.updateAsset(url);
          });
          this.subscriptions.push(subscription);
        })
      )
      .subscribe();
    await this.subscriptions.push(sub);
  }

  async updateAsset(url?: string) {
    if (url) {
      this.model.icon = url;
    }
    if (this.phoneContact1 || this.phoneContact2 || this.emailContact1 || this.emailContact2 || this.emailContact3) {
      const phoneContacts: string[] = [];
      const emailContacts: string[] = [];

      if (this.phoneContact1 !== '' && this.phoneContact1 !== ' ' && this.phoneContact1) {
        phoneContacts.push(this.phoneContact1);
      }
      if (this.phoneContact2 !== '' && this.phoneContact2 !== ' ' && this.phoneContact2) {
        phoneContacts.push(this.phoneContact2);
      }
      if (this.emailContact1 !== '' && this.emailContact1 !== ' ' && this.emailContact1) {
        emailContacts.push(this.emailContact1);
      }
      if (this.emailContact2 !== '' && this.emailContact2 !== ' ' && this.emailContact2) {
        emailContacts.push(this.emailContact2);
      }
      if (this.emailContact3 !== '' && this.emailContact3 !== ' ' && this.emailContact3) {
        emailContacts.push(this.emailContact3);
      }
      this.updateOrderReceivedContacts(emailContacts, phoneContacts);
      this.model.orderCompleteContacts = phoneContacts;
    } else {
      this.model.orderCompleteContacts = [];
    }
    for (const phoneNumber of this.model.orderCompleteContacts) {
      if (!this.isVaildNumber(phoneNumber)) {
        this.showAlert(`Phone number invalid "${phoneNumber}"`);
        return;
      }
    }

    const clientId = this.clientId;
    const modelId = this.model.id;
    console.log({ clientId: clientId, modelId: modelId });

    if (!clientId || !modelId) {
      console.log({ clientId: clientId, modelId: modelId });
      this.showAlert('Error Saving Location');
      return;
    }
    try {
      await this.afs.collection(DB.CLIENTS.ID).doc(clientId).collection(DB.CLIENTS.LOCATIONS.ID).doc(modelId).set(this.model);
      await this.reset();
    } catch (error) {
      console.log(error);
      this.showAlert('Error Saving Location');
    }
  }

  removeImage() {
    this.imagePath = null;
    this.base64 = null;
    this.file = null;
    this.filePath = null;
    this.fileRef = null;
    this.rawFile = null;
    this.model.icon = null;
  }

  async showAlert(header: string, subheader?: string) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      animated: true,
      backdropDismiss: true,
      translucent: false,
      keyboardClose: true,
    });
    await alert.present();
  }
  isVaildNumber(phoneNumber: string): boolean {
    const reg = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$', 'im');

    const valid = reg.test(phoneNumber);
    if (!valid) {
      return false;
    }
    if (!phoneNumber) {
      return false;
    }
    return true;
  }

  setLocationId(locationId: string) {
    this.locationIdSubject.next(locationId);
  }
  setClientId(clientId: string) {
    this.clientIdSubject.next(clientId);
  }

  updateHours(hours: IWeeklyHours) {
    return this.clientId$.pipe(
      first((clientId) => !!clientId),
      switchMap((clientId) => {
        return this.locationId$.pipe(
          first((locationId) => !!locationId),
          switchMap((locationId) => {
            return this.afs
              .collection(DB.CLIENTS.ID)
              .doc<IClient>(clientId)
              .collection(DB.CLIENTS.LOCATIONS.ID)
              .doc<IClientLocation>(locationId)
              .set({ hours: hours } as IClientLocation, { merge: true });
          })
        );
      })
    );
  }
  updateAddress(address: IAddress, name: string) {
    return this.clientId$.pipe(
      first((clientId) => !!clientId),
      switchMap((clientId) => {
        return this.locationId$.pipe(
          first((locationId) => !!locationId),
          switchMap((locationId) => {
            return this.afs
              .collection(DB.CLIENTS.ID)
              .doc<IClient>(clientId)
              .collection(DB.CLIENTS.LOCATIONS.ID)
              .doc<IClientLocation>(locationId)
              .set({ address: address, name: name } as IClientLocation, { merge: true });
          })
        );
      })
    );
  }
  updateOrderReceivedContacts(emails: string[], phones: string[]) {
    this.afs
      .collection(DB.CLIENTS.ID)
      .doc<IClient>(this.clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc<IClientLocation>(this.model.id)
      .collection(DB.CLIENTS.LOCATIONS.CONTACTS.ID)
      .doc(DB.CLIENTS.LOCATIONS.CONTACTS.DOCUMENTS.ORDER_RECEIVED)
      .set(
        {
          emailContacts: emails,
          phoneContacts: phones,
        } as IClientLocationOrderReceivedContacts,
        { merge: true }
      );
  }
  edit(location: IClientLocation, clientId: string) {
    this.setModel(location);
    this.isEdit = true;
    this.isEditSubject.next(this.isEdit);
    this.modelSubject.next(location);
    this.setLocationId(location.id);
    this.setClientId(clientId);
  }
  setModel(newModel: IClientLocation) {
    // Set default values for feature flags
    if (!newModel.featureFlags) {
      newModel.featureFlags = DEFAULT_FEATUREFLAGS;
    }
    if (newModel.featureFlags.addTipToOrder === undefined) {
      newModel.featureFlags.addTipToOrder = DEFAULT_FEATUREFLAGS.addTipToOrder;
    }
    if (!newModel.featureFlags.orderFee) {
      newModel.featureFlags.orderFee = DEFAULT_FEATUREFLAGS.orderFee;
    }
    if (!newModel.featureFlags.orderAhead) {
      newModel.featureFlags.orderAhead = DEFAULT_FEATUREFLAGS.orderAhead;
    }

    if (!newModel.address) {
      newModel.address = DEFAULT_ADDRESS;
    }
    this.model = newModel;
  }

  reset() {
    this.rawFile = null;
    this.base64 = null;
    this.fileRef = null;
    this.file = null;
    this.filePath = null;
    this.rawFile = null;
    this.phoneContact1 = null;
    this.phoneContact2 = null;
    this.emailContact1 = null;
    this.emailContact2 = null;
    this.emailContact3 = null;
    this.isEdit = false;
    this.isEditSubject.next(false);
    this.locationIdSubject.next(null);
    this.isOpen = {
      info: false,
      contacts: false,
      address: false,
      settings: false,
    };
    this.model = {
      id: this.afs.createId(),
      name: '',
      isActive: true,
      hours: null,
      tax: 0,
      description: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
      },
      phone: null,
      geolocation: null,
      activeMenu: null,
      orderCompleteContacts: [],
      featureFlags: DEFAULT_FEATUREFLAGS,
      row: 0,
    };
  }
}
