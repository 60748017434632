import { WeeklyHours } from './../../shared/components/weekly-hours/weekly-hours.model';
import { PlatformType } from './../../../../../shared/constants/platform-type';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthService } from './../../shared/providers/auth.service';
import { IUser } from './../../../../../shared/models/i-user';
import { IClientLocation } from './../../../../../shared/models/i-client-location';
import { OrdersService } from './../../shared/providers/orders.service';
import { DomainService } from './../../shared/providers/domain.service';
import { DeviceService } from './../../shared/providers/device.service';
import { ClientService } from './../../shared/providers/client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocationService } from '../../shared/providers/location.service';
import { environment } from '../../../../environments/environment.prod';
import { IClient } from '../../../../../shared/models/i-client';
import { Subscription, combineLatest } from 'rxjs';
import { LocationsService } from '../../shared/providers/locations.service';
import { UserRole } from '../../../../../shared/access-control/roles/UserRole';
import { IUserSettings } from '../../../../../shared/settings/i-user-settings';
import { Key } from '../../../../../shared/access-control/roles/Key';
import { FCM } from '@capacitor-community/fcm';
const fcm = new FCM();

@Component({
  selector: 'app-debug-menu-modal',
  templateUrl: './debug-menu-modal.component.html',
  styleUrls: ['./debug-menu-modal.component.scss'],
})
export class DebugMenuModalComponent implements OnInit, OnDestroy {
  version = environment.version;
  subscriptions: Subscription[] = [];

  /* Client */
  locations: IClientLocation[];
  client: IClient;
  user: IUser;
  orderCount: number;

  /* Device */
  selectedLocation: IClientLocation;
  selectedLocationHours: WeeklyHours;

  fcmToken: string;
  domain: string;
  platformType: PlatformType;
  isNative: boolean;

  platformBuildAgent: string; // cordova or capacitor

  /* toggles */
  showColors: boolean;

  constructor(
    public modalController: ModalController,
    private locationService: LocationService,
    private locationsService: LocationsService,
    private clientService: ClientService,
    private deviceService: DeviceService,
    private domainService: DomainService,
    private ordersService: OrdersService,
    private authSerivce: AuthService
  ) {}

  async ngOnInit() {
    const locationsSub = this.locationsService.locations$
      .pipe(
        filter((locations) => !!locations),
        map((locations) => {
          const clientLocations: IClientLocation[] = [];
          locations.forEach((doc) => {
            const item = doc.data() as IClientLocation;
            clientLocations.push(item);
          });
          return clientLocations;
        })
      )
      .subscribe((locations) => (this.locations = locations));
    this.subscriptions.push(locationsSub);

    const locationSub = this.locationService.location$.pipe(filter((location) => !!location)).subscribe((selectedLocation) => {
      this.selectedLocation = selectedLocation;
      this.selectedLocationHours = new WeeklyHours(selectedLocation.hours);
    });
    this.subscriptions.push(locationSub);

    const domainSub = this.domainService.domain$.subscribe((domain) => (this.domain = domain));
    this.subscriptions.push(domainSub);

    const clientSub = this.clientService.client$.subscribe((client) => (this.client = client));
    this.subscriptions.push(clientSub);

    if (this.authSerivce.user) {
      const userSub = this.authSerivce.getUser().subscribe((user) => {
        this.user = user;
      });
      this.subscriptions.push(userSub);
    }

    if (this.authSerivce.user) {
      const ordersCountSub = combineLatest([this.locationService.locationId$, this.clientService.clientId$])
        .pipe(
          filter(([locationId, clientId]) => !!locationId || !!clientId),
          switchMap(([locationId, clientId]) => this.ordersService.getOrdersForUser(clientId, locationId, null, null))
        )
        .subscribe((orders) => {
          this.orderCount = orders ? orders.length : 0;
        });
      this.subscriptions.push(ordersCountSub);
    }

    this.platformType = await this.deviceService.getPlatformType();
    this.isNative = this.deviceService.isNative();
    if (this.isNative) {
      const result = await fcm.getToken();
      this.fcmToken = result.token;
    } else {
      this.fcmToken = 'N/A';
    }
    this.platformBuildAgent = this.deviceService.getPlatformAgent();
  }

  ngOnDestroy() {
    this.subscriptions.filter((sub) => !!sub).forEach((sub) => sub.unsubscribe());
  }

  dismiss() {
    this.modalController.dismiss();
  }
  rolesString(user: IUser) {
    return JSON.stringify(user.roles as Record<Key, UserRole>);
  }
  settingsString(user: IUser) {
    return JSON.stringify(user.settings as Record<Key, IUserSettings>);
  }
}
