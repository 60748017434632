export interface IDimensions {
  width: number;
  height: number;
}

export const dimensions = (file: File): Promise<IDimensions> =>
  new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        return resolve({ width, height });
      };
      img.src = window.URL.createObjectURL(file);
    } catch (exception) {
      return reject(exception);
    }
  });
