import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, LoadingController, NavController, AlertController } from '@ionic/angular';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { LocationsService } from '../../shared/providers/locations.service';
import { LocationService } from '../../shared/providers/location.service';
import { GeolocationService } from '../../shared/providers/geolocation.service';
import { ToastsService } from '../../shared/providers/toasts.service';
import { DistanceService } from '../../shared/providers/distance.service';
import { IClientLocation } from '../../../../../shared/models/i-client-location';
import { Subscription, combineLatest, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ICoordinates } from '../../../../../shared/models/i-coordinates';

const sortLocations = (a, b): 0 | 1 | -1 => {
  if (!a || !b || !a.distance || !b.distance) {
    return 0;
  }
  if (a.distance.distance.value < b.distance.distance.value) {
    return -1;
  }
  if (a.distance.distance.value > b.distance.distance.value) {
    return 1;
  }
  return 0;
};
@Component({
  selector: 'app-locations-modal',
  templateUrl: './locations-modal.component.html',
  styleUrls: ['./locations-modal.component.scss']
})
export class LocationsModalComponent implements OnInit, OnDestroy {
  loading: HTMLIonLoadingElement;
  public snapshots: QueryDocumentSnapshot<IClientLocation>[];
  public locations: IClientLocation[];
  canGetDistance = false;
  subscription: Subscription;

  constructor(
    public modalController: ModalController,
    private locationsService: LocationsService,
    private locationService: LocationService,
    private geolocationService: GeolocationService,
    private toastService: ToastsService,
    private distanceService: DistanceService,
    private loadingController: LoadingController,
    private navCtrl: NavController,
    private alertController: AlertController
  ) {}

  async ngOnInit() {
    await this.showLoading();
    this.canGetDistance = this.geolocationService.isAvailable;
    this.loadLocations();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private async showLoading() {
    this.loading = await this.loadingController.create({
      translucent: true
    });
    await this.loading.present();
  }

  private async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  private async loadLocations() {
    this.subscription = this.locationsService.locations$
      .pipe(
        filter(snapshots => !!snapshots),
        switchMap(snapshots => {
          this.snapshots = snapshots;
          this.locations = snapshots
            .map(snapshot => snapshot.data())
            .filter(location => location.isActive);
          this.hideLoading();

          return this.geolocationService.isAvailable
            ? this.geolocationService.currentPosition$
            : of(null);
        }),
        filter(myPosition => !!myPosition),
        tap(myPosition => {
          this.calculateDistanceFromUser(myPosition);
          this.sortLocationsByDistance(myPosition, this.snapshots);
        })
      )
      .subscribe();
  }

  private calculateDistanceFromUser(myPosition: ICoordinates) {
    this.locations = this.locations.map(location => {
      const address = location.name;
      location.distance$ = this.distanceService.getDistance(myPosition, address);
      return location;
    });
  }

  private sortLocationsByDistance(
    myPosition: ICoordinates,
    snapshots: QueryDocumentSnapshot<IClientLocation>[]
  ) {
    const distances$ = snapshots
      .filter(location => location.data().isActive)
      .map(snapshot => {
        const location = snapshot.data();
        return this.distanceService.getDistance(myPosition, location.name);
      });

    combineLatest([...distances$]).subscribe(distances => {
      this.locations = this.snapshots
        .map(snapshot => {
          const location = snapshot.data();
          location.distance = distances
            .filter(d => d.address === location.name)
            .reduce((p, c) => c, null);
          return location;
        })
        .sort(sortLocations);
    });
  }

  async selectLocation(location: IClientLocation) {
    const snapshot = this.snapshots
      .filter(s => s.data().name === location.name)
      .reduce((p, c) => c, null);
    await this.promptRemeberLocation(snapshot);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  showGeolocationFailed() {
    this.toastService.showGeolocationFailedToast();
  }

  goToMenu() {
    this.navCtrl.navigateForward('/menu');
  }

  async promptRemeberLocation(snapshot: QueryDocumentSnapshot<IClientLocation>) {
    const alert = await this.alertController.create({
      header: 'Remember This Location?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            this.locationService.selectLocation(snapshot.id, false);
            this.continue();
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.locationService.selectLocation(snapshot.id, true);
            this.continue();
          }
        }
      ],
      animated: true
    });
    await alert.present();
  }

  private continue() {
    this.goToMenu();
    setTimeout(() => {
      this.dismiss();
    }, 500);
  }
}
