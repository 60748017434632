import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { LocationService } from './location.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocationGuard implements CanActivate {
  constructor(private locationService: LocationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.locationService.locationId$.pipe(
      map(id => {
        if (!id) {
          console.log('TCL: LocationGuard -> canActivate -> failed location guard');
          this.router.navigate(['/']);
          return false;
        }
        return true;
      })
    );
  }
}
