import { combineLatest, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit, OnDestroy {
  @Input() phoneNumberInput = new FormControl();
  @Output() phoneNumberChange = new EventEmitter<string>();
  @Output() phoneNumberIsValid = new EventEmitter<boolean>();

  areaCode = new FormControl();
  prexAndLineNumber = new FormControl();
  phoneNumber: string;

  private subscriptions = new Array<Subscription>();
  constructor() {}
  ngOnInit() {
    const sub = combineLatest([this.areaCode.valueChanges, this.prexAndLineNumber.valueChanges]).subscribe(([areaCode, lineValue]) => {
      this.validateAreaAndLineNumber(areaCode, lineValue);
    });
    this.subscriptions.push(sub);

    const phoneNumberSubscription = this.phoneNumberInput.valueChanges.subscribe((phoneNumber) => {
      this.phoneNumber = phoneNumber;
      this.createLineAndAreaCode();
    });
    this.subscriptions.push(phoneNumberSubscription);
    this.phoneNumber = this.phoneNumberInput.value; // get value of phone number if it was initialized before the subscription above
    this.createLineAndAreaCode();
  }
  createLineAndAreaCode() {
    this.phoneNumber = this.phoneNumber ? this.phoneNumber : '';
    this.areaCode.setValue(this.phoneNumber.includes('+') ? this.phoneNumber.slice(0, 2) : '+1', {
      emitEvent: this.areaCode.value ? false : true,
    });
    this.prexAndLineNumber.setValue(this.phoneNumber.includes('+') && this.phoneNumber.length >= 12 ? this.phoneNumber.slice(2, 12) : '', {
      emitEvent: false,
    });
    this.validateAreaAndLineNumber(this.areaCode.value, this.prexAndLineNumber.value);
  }
  validateAreaAndLineNumber(areaCode: string, line: string) {
    const lineNumber = this.convertToNumber(line);
    const newNumber = areaCode + lineNumber;
    const valid = this.isVaildNumber(newNumber);
    if (valid) {
      this.onChange(newNumber);
      this.phoneNumberIsValid.emit(true);
    } else {
      this.phoneNumberIsValid.emit(false);
    }
  }
  convertToNumber(value: string) {
    let newString = value;
    const removeChars = ['-', '(', ')', ' ', '+', '-', '.'];
    for (const char of removeChars) {
      newString = newString.split(char).join('');
    }
    return Number(newString);
  }
  isVaildNumber(phoneNumber: string): boolean {
    const reg = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$', 'im');

    const valid = reg.test(phoneNumber);
    if (!valid) {
      return false;
    }
    if (!phoneNumber || phoneNumber == '') {
      return false;
    }
    if (phoneNumber.length > 12 || phoneNumber.length < 12) {
      return false;
    }
    return true;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  onChange(phoneNumber: string) {
    console.log('onChange called', phoneNumber);
    this.phoneNumberChange.emit(phoneNumber);
  }
}
