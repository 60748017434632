export class KeyGen {
  private static readonly delimeter = '==>';

  public static generate(keys: string[] = []): string {
    return keys.reduce((p, c) => {
      return `${p}${KeyGen.delimeter}${c}`;
    });
  }

  public static parseKey(key: string): string[] {
    return key.split(KeyGen.delimeter);
  }
}
