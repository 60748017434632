export const DB = {
  THREEBAPPS: {
    ID: '3bapps',
    STRIPE: {
      ID: 'stripe',
    },
    ASSETS: {
      ID: 'assets',
      CLIENT_DASHBOARD: {
        ID: 'clientDashboard',
        DOCUMENTS: {
          IPHONE_BACKGROUND: 'iphoneBackground',
        },
      },
    },
    SETTINGS: {
      ID: 'settings',
      KEYS: {
        ID: 'keys',
      },
    },
    DOCUMENTS: {
      ID: 'documents',
      CHECKLISTS: {
        ID: 'checklists',
      },
      RESOURCE: {
        ID: 'resource',
      },
    },
  },

  CLIENTS: {
    ID: 'clients',
    ITEMS: {
      ID: 'items',
    },
    MENUS: {
      ID: 'menus',
    },
    COUPONS: {
      ID: 'coupons',
    },
    REPORTS: {
      ID: 'reports',
    },

    LOCATIONS: {
      ID: 'locations',
      MENUS: {
        ID: 'menus',
      },
      ORDERS: {
        ID: 'orders',
      },
      TIPS: {
        ID: 'tips',
      },
      CONTACTS: {
        ID: 'contacts',
        DOCUMENTS: { ORDER_RECEIVED: 'order-received' },
      },
    },
  },

  USERS: {
    ID: 'users',
    SETTINGS: {
      ID: 'settings',
    },
    CARTS: {
      ID: 'carts',
    },
    REWARDS: {
      ID: 'rewards',
      HISTORY: {
        ID: 'history',
      },
    },
  },

  BOTS: {
    ID: 'bots',
    BUDGET: { ID: 'budget', NOTIFICATIONS: { ID: 'notifications', LAST: 'last' } },
    ORDERS: { ID: 'orders', NOTIFICATIONS: { ID: 'notifications' } },
    FEEDBACK: { ID: 'feedback', NOTIFICATIONS: { ID: 'notifications' } },
    BACKUPS: { ID: 'backups', NOTIFICATIONS: { ID: 'notifications', LAST: 'last' } },
  },

  FEEDBACK: {
    ID: 'feedback',
  },

  MESSAGINGTOKENS: {
    ID: 'messagingTokens',
    CLIENTS: {
      ID: 'clients',
      TOKENS: {
        ID: 'tokens',
      },
    },
  },
};
