import { STATUS } from './../../../../../shared/constants/status';
import { AuthService } from './auth.service';
import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap, debounceTime } from 'rxjs/operators';
import { DB } from '../../../../../shared/constants/db';
import { IOrder } from '../../../../../shared/models/i-order';
import { IPrintInfo } from '../../../../../shared/models/i-print-info';
import * as _ from 'lodash';
import { IOrderQuery } from '../../../../../shared/models/i-order-query';
import { AngularFireFunctions } from '@angular/fire/functions';
import { API } from '../../../../../shared/constants/api-endpoints';
import { IGetOrdersRequest } from '../../../../../shared/models/i-get-orders-request';

@Injectable()
export class OrdersService {
  private querySubject = new BehaviorSubject<IOrderQuery | null>(null);
  private query$ = this.querySubject.asObservable();

  constructor(private afs: AngularFirestore, private auth: AuthService, private afFunctions: AngularFireFunctions) {}

  search(query: IOrderQuery | null) {
    console.log({ search: query });
    this.querySubject.next(query);
  }

  getOrder(clientId: string, locationId: string, orderId: string): Observable<IOrder> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection(DB.CLIENTS.LOCATIONS.ORDERS.ID)
      .doc<IOrder>(orderId)
      .valueChanges();
  }

  async completeOrder(clientId: string, locationId: string, orderId: string): Promise<void> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection(DB.CLIENTS.LOCATIONS.ORDERS.ID)
      .doc(orderId)
      .set({ status: STATUS.COMPLETE }, { merge: true });
  }

  orderPrinted(printInfo: IPrintInfo, clientId: string, locationId: string, orderId: string): Promise<void> {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection(DB.CLIENTS.LOCATIONS.ORDERS.ID)
      .doc(orderId)
      .set({ printInfo }, { merge: true });
  }

  getPendingOrders(clientId: string, locationId: string): Observable<IOrder[]> {
    const collectionRef = this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('status', '==', STATUS.PENDING).orderBy('created', 'asc');
        return query;
      });
    return collectionRef.valueChanges();
  }

  getPaidOrders(clientId: string, locationId: string): Observable<IOrder[]> {
    const collectionRef = this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('status', '==', STATUS.PAID).orderBy('created', 'asc');
        return query;
      });
    return collectionRef.valueChanges();
  }

  getProcessingOrders(clientId: string, locationId: string): Observable<IOrder[]> {
    const collectionRef = this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('status', '==', STATUS.PROCESSING).orderBy('created', 'asc');
        return query;
      });
    return collectionRef.valueChanges();
  }

  getReadyOrders(clientId: string, locationId: string): Observable<IOrder[]> {
    const collectionRef = this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('status', '==', STATUS.READY).orderBy('created', 'asc');
        return query;
      });
    return collectionRef.valueChanges();
  }

  getOpenOrders(clientId: string, locationId: string): Observable<IOrder[]> {
    return combineLatest([
      this.getPaidOrders(clientId, locationId),
      this.getProcessingOrders(clientId, locationId),
      this.getReadyOrders(clientId, locationId),
    ]).pipe(
      map(([paid, processing, ready]) => {
        return new Array<IOrder>().concat(paid, processing, ready).sort((a: IOrder, b: IOrder) => {
          return this.getTime(new Date(a.created)) - this.getTime(new Date(b.created));
        });
      })
    );
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  getCompletedOrders(clientId: string, locationId: string): Observable<IOrder[]> {
    const searchByAmount$ = this.query$.pipe(
      switchMap((search) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc(clientId)
          .collection(DB.CLIENTS.LOCATIONS.ID)
          .doc(locationId)
          .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (search && search.text) {
              if (search.text.length > 0) {
                try {
                  const amount = parseFloat(search.text);
                  console.log('TCL: OrdersService -> searchByAmount -> amount', amount);
                  query = query.where('amount', '==', amount);
                } catch (err) {
                  // no op
                }
              }
            }
            query.limit(50);
            const start = search && search.start ? new Date(search.start).toISOString() : new Date().toISOString();
            const end = search && search.end ? new Date(search.end).toISOString() : new Date().toISOString();
            console.log({ start, end });
            return query.where('status', '==', STATUS.COMPLETE).where('created', '>', start).where('created', '<', end).orderBy('created', 'desc');
          })
          .valueChanges();
      })
    );

    const searchById$ = this.query$.pipe(
      switchMap((search) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc(clientId)
          .collection(DB.CLIENTS.LOCATIONS.ID)
          .doc(locationId)
          .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (search && search.text) {
              if (search.text.length > 0) {
                query = query.where('id', '==', search.text);
              }
            }
            const start = search && search.start ? search.start : new Date().toISOString();
            const end = search && search.end ? new Date(search.end).toISOString() : new Date().toISOString();
            return query.where('status', '==', STATUS.COMPLETE).where('created', '>', start).where('created', '<', end).orderBy('created', 'desc');
          })
          .valueChanges();
      })
    );

    const searchByEmail$ = this.query$.pipe(
      switchMap((search) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc(clientId)
          .collection(DB.CLIENTS.LOCATIONS.ID)
          .doc(locationId)
          .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (search && search.text) {
              if (search.text.length > 0) {
                query = query.where('contact.email', '==', search.text);
              }
            }
            const start = search && search.start ? search.start : new Date().toISOString();
            const end = search && search.end ? new Date(search.end).toISOString() : new Date().toISOString();
            return query.where('status', '==', STATUS.COMPLETE).where('created', '>', start).where('created', '<', end).orderBy('created', 'desc');
          })
          .valueChanges();
      })
    );

    const searchByPhone$ = this.query$.pipe(
      switchMap((search) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc(clientId)
          .collection(DB.CLIENTS.LOCATIONS.ID)
          .doc(locationId)
          .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (search && search.text) {
              if (search.text.length > 0) {
                query = query.where('contact.phone', '==', search.text);
              }
            }
            const start = search && search.start ? search.start : new Date().toISOString();
            const end = search && search.end ? search.end : new Date().toISOString();
            return query.where('status', '==', STATUS.COMPLETE).where('created', '>', start).where('created', '<', end).orderBy('created', 'desc');
          })
          .valueChanges();
      })
    );

    const searchByName$ = this.query$.pipe(
      switchMap((search) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc(clientId)
          .collection(DB.CLIENTS.LOCATIONS.ID)
          .doc(locationId)
          .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (search && search && search.text) {
              if (search.text.length > 0) {
                query = query.where('contact.name', '==', search.text);
              }
            }
            const start = search && search.start ? search.start : new Date().toISOString();
            const end = search && search.end ? search.end : new Date().toISOString();
            return query.where('status', '==', STATUS.COMPLETE).where('created', '>', start).where('created', '<', end).orderBy('created', 'desc');
          })
          .valueChanges();
      })
    );
    return combineLatest([searchByAmount$, searchById$, searchByEmail$, searchByPhone$, searchByName$]).pipe(
      debounceTime(1000),
      switchMap(([amountResult, idResult, emailResult, phoneResult, nameResult]) => {
        // console.log({ amountResult, idResult, emailResult, phoneResult, nameResult, allResult });
        // remove duplicates
        const result = _.unionBy(amountResult, idResult, emailResult, phoneResult, nameResult, 'id');
        console.log({ result });
        return of(result);
      })
    );
  }
  markOrdersAsComplete(clientId: string, locationId: string, orders: IOrder[]) {
    const promises = [];
    orders.forEach((order) => {
      const promise = this.afs
        .collection(DB.CLIENTS.ID)
        .doc(clientId)
        .collection(DB.CLIENTS.LOCATIONS.ID)
        .doc(locationId)
        .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID)
        .doc(order.id)
        .set({ status: STATUS.COMPLETE }, { merge: true });
      promises.push(promise);
    });

    return Promise.all(promises);
  }

  getOrdersForUser(clientId: string, locationId: string, startDate?: Date, endDate?: Date) {
    if (!clientId) {
      throw Error('No clientId given');
    }
    if (!locationId) {
      throw Error('No locationId given');
    }
    if (!this.auth.user) {
      throw Error('No user signed in');
    }
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc(locationId)
      .collection<IOrder>(DB.CLIENTS.LOCATIONS.ORDERS.ID, (ref) => {
        let queryRefrence = ref.where('userId', '==', this.auth.user.uid).orderBy('created', 'desc');
        if (startDate) {
          queryRefrence = queryRefrence.where('created', '>', startDate);
        }
        if (endDate) {
          queryRefrence = queryRefrence.where('created', '<', endDate);
        }
        return queryRefrence;
      })
      .valueChanges();
  }
}
