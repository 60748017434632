import { DeviceService } from './../../shared/providers/device.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, NavParams, ToastController, NavController, Platform } from '@ionic/angular';
import { ToastsService } from '../../shared/providers/toasts.service';
import { AuthService } from '../../shared/providers/auth.service';
import { AlertController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoadingController } from '@ionic/angular';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit, OnDestroy {
  email: string;
  password: string;
  displayName: string;
  phoneNumber: string;
  phoneNumberIsValid = false;
  isLogin = true;
  errorHeader: string;
  loading: HTMLIonLoadingElement;
  backUrl: string;
  subscriptions = new Array<Subscription>();

  isAppleAvailable = false;
  isFacebookAvailable = false;
  isGoogleAvailable = false;

  constructor(
    private toasts: ToastsService,
    private authService: AuthService,
    private modalController: ModalController,
    private navParams: NavParams,
    public alertController: AlertController,
    public toastController: ToastController,
    public afAuth: AngularFireAuth,
    private loadingController: LoadingController,
    private titleCasePipe: TitleCasePipe,
    private route: ActivatedRoute,
    private navController: NavController,
    public plt: Platform,
    public deviceService: DeviceService
  ) {}

  async ngOnInit() {
    this.isAppleAvailable = await this.authService.isAppleAvailable();
    this.isFacebookAvailable = this.authService.isFacebookAvailable();
    this.isGoogleAvailable = this.authService.isGoogleAvailable();
    console.log(
      `LoginModalComponent isAppleAvailable ${this.isAppleAvailable} isFacebookAvailable ${this.isFacebookAvailable} isGoogleAvailable ${this.isGoogleAvailable}`
    );

    this.isLogin = this.navParams.get('isLogin');
    const sub = this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('backUrl')),
        filter((url) => !!url)
      )
      .subscribe((url) => {
        this.backUrl = url;
        console.log({ backUrl: this.backUrl });
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  async onSubmit(form) {
    await this.registerEmailAndPassword();
  }

  async login() {
    try {
      await this.showLoading();
      const result = await this.authService.doEmailAndPasswordLogin(this.email, this.password);
      await this.dismiss();
      await this.showWelcomeToast(result.user.email);
      if (this.backUrl) {
        this.navController.navigateBack(this.backUrl);
      }
    } catch (error) {
      await this.loginError(error);
      await this.hideLoading();
    }
    await this.hideLoading();
  }

  async appleAuth() {
    try {
      await this.authService.doAppleLogin();
      await this.dismiss();
      await this.showWelcomeToast(this.authService.user.displayName);
      if (this.backUrl) {
        this.navController.navigateBack(this.backUrl);
      } else {
        this.navController.navigateForward('');
      }
    } catch (error) {
      console.log('appleAuth ERROR --- ', error);
      if (error.message !== '1001') {
        // Error 1001 is when apple auth is dismissed
        await this.loginError(error);
      }
    }
  }
  async facebookAuth() {
    try {
      await this.authService.doFacebookLogin();
      await this.dismiss();
      await this.showWelcomeToast(this.authService.user.displayName);
      if (this.backUrl) {
        this.navController.navigateBack(this.backUrl);
      }
    } catch (error) {
      console.log('facebookAuth ERROR --- ', error);
      await this.loginError(error);
    }
  }

  async googleAuth() {
    try {
      await this.authService.doGoogleLogin();
      await this.dismiss();
      await this.showWelcomeToast(this.authService.user.displayName);
      if (this.backUrl) {
        this.navController.navigateBack(this.backUrl);
      }
    } catch (error) {
      console.log('googleAuth ERROR --- ', error);
      await this.loginError(error);
    }
  }

  async showWelcomeToast(name?: string) {
    await this.toasts.showWelcomeToast(name);
  }

  async showSignUp() {
    this.isLogin = false;
  }

  async showLogin() {
    this.isLogin = true;
  }

  async showLoading() {
    this.loading = await this.loadingController.create({
      translucent: true,
    });
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async registerEmailAndPassword() {
    await this.showLoading();
    try {
      const result = await this.authService.doRegister(this.email, this.password, this.displayName, this.phoneNumber);
      await this.dismiss();
      await this.showWelcomeToast(result.user.email);
      if (this.backUrl) {
        this.navController.navigateBack(this.backUrl);
      }
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        await this.hideLoading();
        await this.login();
        return;
      }
      await this.loginError(error);
    }
    await this.hideLoading();
  }

  async loginError(error) {
    if (error && error.code) {
      const e = error.code.replace(/-/g, ' ');
      const et = e.replace('auth/', '');
      this.errorHeader = this.titleCasePipe.transform(et);
    } else {
      this.errorHeader = error;
    }

    const alert = await this.alertController.create({
      header: this.errorHeader,
      message: error.message,
      buttons: [
        {
          text: 'Cancel',
          handler: (blah) => {},
        },
      ],
    });
    await alert.present();
  }

  async forgotPassword() {
    await this.enterForgottenPasswordPrompt();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      buttons: [
        {
          text: 'Done',
          role: 'cancel',
        },
      ],
      position: 'top',
      duration: 2000,
    });
    await toast.present();
  }

  async sendResetPasswordEmail(email: string) {
    try {
      await this.afAuth.sendPasswordResetEmail(email);
      await this.presentToast('Password reset email sent!');
    } catch (error) {
      this.presentToast('Error resetting password.');
    }
  }
  async enterForgottenPasswordPrompt() {
    const alert = await this.alertController.create({
      header: 'Enter Email',
      inputs: [
        {
          name: 'email',
          type: 'text',
          placeholder: 'example@email.com',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Ok',
          handler: (result) => {
            this.sendResetPasswordEmail(result.email);
          },
        },
      ],
      backdropDismiss: true,
      animated: true,
      translucent: false,
      keyboardClose: true,
    });
    await alert.present();
  }
  phoneNumberChange(phone: string) {
    this.phoneNumber = phone;
  }
  phoneNumberIsValidChange(valid: boolean) {
    this.phoneNumberIsValid = valid;
  }
}
