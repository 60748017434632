import { DomainService } from './domain.service';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

const theme = (domain: string = 'demo') => {
  const element = document.getElementById('domainTheme');
  element.setAttribute('href', `./themes/${domain}.css`);
};
@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(private service: DomainService) {}
  theme = theme;

  // Moving to init to make explicit that this service needs to be injected in a component.
  init() {
    this.service.domain$.pipe(filter(domain => !!domain)).subscribe(domain => this.theme(domain));
    window['Theme'] = theme; // add global function so themes can be tested via JS console.
  }
}
