import { Component, OnInit } from '@angular/core';
import { BrowserService } from '../../providers/browser.service';

@Component({
  selector: 'app-privacy-and-terms',
  templateUrl: './privacy-and-terms.component.html',
  styleUrls: ['./privacy-and-terms.component.scss']
})
export class PrivacyAndTermsComponent implements OnInit {
  constructor(private browserService: BrowserService) {}
  PRIVACY_POLICY_URL = 'https://www.3bapps.com/privacypolicy';
  TERMS_OF_SERVICE_URL = 'https://www.3bapps.com/terms-of-service';

  ngOnInit() {}

  async privacyPolicy() {
    await this.browserService.open(this.PRIVACY_POLICY_URL);
  }
  async termsOfService() {
    await this.browserService.open(this.TERMS_OF_SERVICE_URL);
  }
}
