import { Pipe, PipeTransform } from '@angular/core';
import { IOrder } from '../../../../../shared/models/i-order';

@Pipe({
  name: 'searchOrders',
})
export class SearchOrdersPipe implements PipeTransform {
  private input = '';
  transform(input: IOrder[], target: string): any {
    if (!input || input.length === 0 || !target || target.length === 0) {
      return input;
    }
    this.input = target && target.toLowerCase();
    return input.filter(
      (order) =>
        this.matchByName(order) ||
        this.matchByEmail(order) ||
        this.matchByPrice(order) ||
        this.matchById(order)
    );
  }

  matchByName(order: IOrder): boolean {
    return (
      order &&
      order.contact &&
      order.contact.name.length > 0 &&
      order.contact.name.toLowerCase().includes(this.input)
    );
  }
  matchByEmail(order: IOrder): boolean {
    return (
      order &&
      order.contact &&
      order.contact.email.length > 0 &&
      order.contact.email.toLowerCase().includes(this.input)
    );
  }
  matchByPrice(order: IOrder): boolean {
    return (
      order &&
      order.amount !== null &&
      order.amount !== undefined &&
      `${order.amount}`.includes(this.input)
    );
  }

  matchById(order: IOrder): boolean {
    return (
      order && order.id !== null && order.id !== undefined && `${order.id}`.includes(this.input)
    );
  }
}
