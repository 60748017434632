import { Component, OnInit } from '@angular/core';
import { PlatformType, PlatformTypes } from '../../../../../shared/constants/platform-type';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '../../shared/providers/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { API } from '../../../../../shared/constants/api-endpoints';

@Component({
  selector: 'app-contact-user-modal',
  templateUrl: './contact-user-modal.component.html',
  styleUrls: ['./contact-user-modal.component.scss'],
})
export class ContactUserModalComponent implements OnInit {
  userEmail: string;
  userName: string;
  userPhone: string;
  userId: string;
  locationId: string;
  clientId: string;
  orderId?: string;
  platform: PlatformType;
  messageToUser: string;

  constructor(
    params: NavParams,
    public modalController: ModalController,
    private auth: AuthService,
    private afFunctions: AngularFireFunctions
  ) {
    this.userEmail = params.get('userEmail');
    this.userName = params.get('userName');
    this.userPhone = params.get('userPhone');
    this.userId = params.get('userId');
    this.platform = params.get('platform');
    this.locationId = params.get('locationId');
    this.clientId = params.get('clientId');
    this.orderId = params.get('orderId');
  }

  ngOnInit() {}

  formatPlatformName(name: string) {
    const platform = name as PlatformType;
    if (platform === PlatformTypes.IOS) {
      return 'IOS';
    } else if (platform === PlatformTypes.ANDROID) {
      return 'Android';
    } else if (platform === PlatformTypes.WEBDESKTOP) {
      return 'web on Desktop';
    } else if (platform === PlatformTypes.WEBMOBILE) {
      return 'web on Mobile';
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }
  async sendNotification() {
    const token = await this.auth.getIdToken();
    const callable = this.afFunctions.httpsCallable(API.sendUserMessage);
    callable({
      idToken: token,
      message: this.messageToUser,
      userId: this.userId,
      locationId: this.locationId,
      clientId: this.clientId,
      orderId: this.orderId,
    }).subscribe(() => {
      console.log('sendNotification --- complete');
    });
    this.dismiss();
  }
}
