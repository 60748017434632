import { IUser } from './../../../../../shared/models/i-user';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, NavController, LoadingController } from '@ionic/angular';
import { AuthService } from '../../shared/providers/auth.service';
import { first, map, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

interface Provider {
  name: string;
  color: string;
}
@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit, OnDestroy {
  providers: Provider[];
  user: IUser;
  loading: HTMLIonLoadingElement;
  uploadingPhoneNumber = false;
  private subscriptions = new Array<Subscription>();
  phoneNumber = new FormControl();

  constructor(
    public modalController: ModalController,
    public auth: AuthService,
    private navController: NavController,
    private loadingController: LoadingController
  ) {}

  async ngOnInit() {
    await this.showLoading();
    this.getProviders();
    const sub = this.auth.getUser().subscribe(user => {
      this.user = user;
      if (user && user.phoneNumber) {
        this.phoneNumber.setValue(user.phoneNumber, { emitEvent: true });
      }
      this.hideLoading();
    });
    this.subscriptions.push(sub);
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
  }
  dismiss() {
    this.modalController.dismiss();
  }

  onSubmit(form) {
    console.log('TCL: ProfileModalComponent -> onSubmit -> form', form);
  }
  async phoneNumberChange(phone: string) {
    this.uploadingPhoneNumber = true;
    await this.auth.updateUserInfo(phone, null);
    this.uploadingPhoneNumber = false;
  }

  private async getProviders() {
    const authUserSub = this.auth.user$
      .pipe(
        filter(user => !!user),
        first(user => !user || !user.providerData),
        map(user => {
          const ids = user.providerData.map(data => data.providerId);
          const results = [];
          if (ids.some(id => id.includes('google'))) {
            results.push({ name: 'logo-google', color: 'danger' });
          }
          if (ids.some(id => id.includes('facebook'))) {
            results.push({ name: 'logo-facebook', color: 'primary' });
          }
          if (ids.some(id => id.includes('twitter'))) {
            results.push({ name: 'logo-twitter', color: 'secondary' });
          }
          return results as Provider[];
        })
      )
      .subscribe(providers => {
        this.providers = providers;
        console.log('TCL: ProfileModalComponent -> providers', providers);
      });
    await this.subscriptions.push(authUserSub);
  }

  signOut() {
    this.modalController.dismiss();
    this.auth.signOut();
    this.navController.navigateRoot('/');
  }

  async showLoading(message?: string) {
    await this.hideLoading();
    this.loading = await this.loadingController.create({
      translucent: true,
      message: message
    });
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }
}
