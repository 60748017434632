import { FeeTypes, FeeType } from './../../../../../shared/constants/fee-type';
import { LocationService } from './location.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Cart } from '../../../../../shared/models/cart';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { ICart } from '../../../../../shared/models/i-cart';
import { IEarnedReward } from '../../../../../shared/models/i-earned-reward';
import { ToastController } from '@ionic/angular';
import { RewardsService } from './rewards.service';
import { filter } from 'rxjs/operators';
import { IUser } from '../../../../../shared/models/i-user';
import { DB } from '../../../../../shared/constants/db';
import { IOrderItem } from '../../../../../shared/models/i-order-item';
import { sum } from '../../../../../shared/helpers/sum';
import { ICartItem } from '../../../../../shared/models/i-cart-item';
import { LoadingState } from '../../../../../shared/enums/loading-state.enum';
import { catchError } from 'rxjs/operators';

const MAX_TIP = 50;
const OVER_TIP_MAX_ERROR = 'The max tip amount is';
const LESS_TIP_MIN_ERROR = 'Can not enter a negative number';

@Injectable()
export class CartService {
  private userId: string;
  private locationId: string;
  private cart = Cart.newCart();
  private cartIsReadySubject = new BehaviorSubject<boolean>(false);
  private cartSubject = new BehaviorSubject<ICart | null>(null);
  private cartRef: AngularFirestoreDocument<ICart>;
  private earnedRewards: IEarnedReward[];

  public rewardState = LoadingState.Loading;
  public cartIsReady$ = this.cartIsReadySubject.asObservable();
  public cart$ = this.cartSubject.asObservable();

  constructor(
    public afs: AngularFirestore,
    private toastController: ToastController,
    private auth: AuthService,
    private locationService: LocationService,
    private rewardsService: RewardsService
  ) {
    this.loadCart();
  }

  loadCart(): void {
    combineLatest(this.auth.user$, this.locationService.location$, this.rewardsService.earned$)
      .pipe(
        filter(([user, location]) => !!user && !!location),
        catchError(error => {
          this.rewardState = LoadingState.Failed;
          throw error;
        })
      )
      .subscribe(([user, location, earnedRewards]) => {
        this.earnedRewards = earnedRewards;
        this.rewardState = LoadingState.Completed;
        this.userId = user.uid;
        this.locationId = location.id;

        // this creates the cart if it doesn't exist otherwise it does nothing
        let feeInfo;
        if (location.featureFlags) {
          if (location.featureFlags.orderFee) {
            if (location.featureFlags.orderFee.enabled === true) {
              feeInfo = {
                feeValue: location.featureFlags.orderFee.value,
                feeEnabled: location.featureFlags.orderFee.enabled,
                feeType: location.featureFlags.orderFee.feeType as FeeType
              };
            }
          }
        }
        if (!feeInfo) {
          feeInfo = { feeEnabled: false, feeValue: 0, feeType: FeeTypes.VALUE as FeeType };
        }
        this.afs
          .collection<IUser>(DB.USERS.ID)
          .doc<IUser>(user.uid)
          .collection<IUser>(DB.USERS.CARTS.ID)
          .doc(this.locationId)
          .set(Object.assign({ taxRate: location.tax }, feeInfo), { merge: true });

        // get reference to cart firestore document
        this.cartRef = this.afs
          .collection<IUser>(DB.USERS.ID)
          .doc<IUser>(user.uid)
          .collection<IUser>(DB.USERS.CARTS.ID)
          .doc<ICart>(this.locationId);

        this.cartRef.valueChanges().subscribe(cart => {
          console.log('TCL: CartService -> cart', cart);
          if (!cart.items) {
            cart.items = [];
          }
          if (!cart.taxRate) {
            cart.taxRate = location.tax;
          }
          this.cart = cart;
          const isCartReady = !!this.cart && !!this.cart.items && Array.isArray(this.cart.items);
          this.checkForRewards();
          this.cartIsReadySubject.next(isCartReady);
          this.cartSubject.next(this.cart);
        });
      });
  }

  checkForRewards() {
    const coupon = Cart.bestDiscount(this.cart, this.earnedRewards);
    this.cart.coupons = [];
    if (coupon) {
      this.cart.coupons.push(coupon);
      this.cart = Cart.recalculate(this.cart);
    }
  }

  private sumOptions(dto: IOrderItem): number {
    return dto.options.length === 0
      ? dto.item.price
      : dto.options.map(x => x.price).reduce(sum) + dto.item.price;
  }

  removeItem(item: ICartItem) {
    this.cart.items = this.cart.items.filter(i => i.cartId !== item.cartId);
    this.recalculateCart();
  }

  addFirstOrderReward(freeItem: IOrderItem) {
    if (this.containsFirstOrderReward()) {
      return;
    }
    freeItem.options = [];
    this.addItem(freeItem);
  }

  containsFirstOrderReward(): boolean {
    return this.cart.items
      .map(item => item.isFirstOrderReward)
      .reduce((p, c) => {
        if (p) {
          return true;
        }
        if (c) {
          return true;
        }
      }, false);
  }
  changeTip(tip: number) {
    if (tip > MAX_TIP) {
      throw new Error(OVER_TIP_MAX_ERROR + ' $' + MAX_TIP);
      return;
    }
    if (tip < 0) {
      throw new Error(LESS_TIP_MIN_ERROR);
      return;
    }
    this.cart.tip = Math.abs(tip);
    this.recalculateCart();
  }
  addItem(dto: IOrderItem) {
    const total = this.sumOptions(dto);
    const cartItem = {
      id: dto.item.id,
      cartId: this.afs.createId(),
      name: dto.item.name,
      price: dto.item.price,
      total: total,
      timestamp: new Date().toISOString()
    } as ICartItem;
    if (dto.item.isFirstOrderReward) {
      cartItem.isFirstOrderReward = dto.item.isFirstOrderReward;
    }
    if (dto.options) {
      cartItem.options = dto.options;
    }
    if (dto.comments) {
      cartItem.comments = dto.comments;
    }
    if (dto.item.icon) {
      cartItem.icon = dto.item.icon;
    }
    this.cart.items.push(cartItem);
    this.recalculateCart();
    this.presentToast();
  }

  private recalculateCart() {
    console.log('TCL: CartService -> recalculateCart -> recalculateCart');
    this.cart = Cart.recalculate(this.cart);
    this.cartSubject.next(this.cart);
    console.log('TCL: CartService -> recalculateCart -> this.cart', this.cart);
    this.cartRef.set(this.cart);
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Added to cart',
      animated: true,
      position: 'top',
      cssClass: 'added-to-cart',
      duration: 2000
    });
    await toast.present();
  }

  clearCart() {
    this.cart = Cart.newCart();
    this.cartSubject.next(this.cart);
    console.log('clear cart');
    this.afs
      .collection<IUser>(DB.USERS.ID)
      .doc<IUser>(this.userId)
      .collection<IUser>(DB.USERS.CARTS.ID)
      .doc(this.locationId)
      .set(this.cart, { merge: false });
  }
}
