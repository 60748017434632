<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start"> <ion-menu-button></ion-menu-button> </ion-buttons>
    <ion-title> Oops</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="medium">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card color="white">
          <ion-card-content>
            <ion-item lines="none">
              <ion-icon slot="start" size="large" name="alert" color="danger"></ion-icon>
              <ion-label class="ion-justify-content-around ion-align-items-center">
                Unexpected error has occurred. Please contact
                <a href="mailto:support@3bapps.com?subject=Stripe Connect Setup Error">
                  3B Apps Support
                </a>
              </ion-label>
            </ion-item>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
