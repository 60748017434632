import { IClientLocation } from './../../../../../shared/models/i-client-location';
import { IFirstOrderReward } from './../../../../../shared/models/i-first-order-reward';
import { WeeklyHours } from './../components/weekly-hours/weekly-hours.model';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { ImageResizerService } from './image-resizer.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IClient } from '../../../../../shared/models/i-client';
import { dimensions } from '../../../../../shared/helpers/dimensions';
import { DB } from '../../../../../shared/constants/db';
import { filter, switchMap, tap } from 'rxjs/operators';
import { IWeeklyHours } from '../../../../../shared/models/i-weekly-hours';
import { IRewards } from '../../../../../shared/models/i-rewards';
import { IClientFeatureFlags } from '../../../../../shared/models/i-client-feature-flags';
import { IMenu } from '../../../../../shared/models/i-menu';
import { AngularFirestore } from '@angular/fire/firestore';

const DEFAULT_REWARDS: IRewards = {
  prize: '',
  qualifier: '',
  isActive: false,
  costToEarnPoint: 1,
  numberOfPointsRequired: 10,
  items: [],
  type: 'VALUE',
  value: 0,
};
const DEFAULT_FEATUREFLAGS: IClientFeatureFlags = {
  canAddLocation: false,
  newOrderSMS: false,
  stopAutoClientMenuSync: false,
  canSendPushNotifications: false,
  firstOrderReward: {
    active: false,
    showToast: false,
    item: null,
  } as IFirstOrderReward,
};

@Injectable({ providedIn: 'root' })
export class ClientBuilderService {
  private isEdit = false;
  private isEditSubject = new BehaviorSubject<boolean>(this.isEdit);
  private modelSubject = new BehaviorSubject<IClient | null>(null);
  private clientIdSubject = new BehaviorSubject<string | null>(null);

  isEdit$ = this.isEditSubject.asObservable();
  model$ = this.modelSubject.asObservable();
  clientId$ = this.clientIdSubject.asObservable();
  client$: Observable<IClient>;
  clientMenus$: Observable<IMenu[]>;
  locations$: Observable<IClientLocation[]>;
  clientId: string;
  isOpen = {
    appStore: false,
    info: false,
    stripe: false,
    twilio: false,
    settings: false,
    assets: false,
    transferMenu: false,
  };

  loading: HTMLIonLoadingElement;
  url: string;
  uploadPercent: Observable<number>;
  imagePath: string;
  error: string;
  model: IClient;
  rawFaviconFile: any;
  base64Favicon: string | ArrayBuffer;
  rawIconFile: any;
  base64Icon: string | ArrayBuffer;
  rawLogoFile: any;
  base64Logo: string | ArrayBuffer;
  rawMobileBackgroundFile: any;
  base64MobileBackground: string | ArrayBuffer;
  rawDesktopBackgroundFile: any;
  base64DesktopBackground: string | ArrayBuffer;
  file: File;

  constructor(
    private afs: AngularFirestore,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private resizer: ImageResizerService,
    private storage: AngularFireStorage
  ) {
    this.client$ = this.clientId$.pipe(
      filter((clientId) => !!clientId),
      switchMap((clientId) => {
        this.clientId = clientId;
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc<IClient>(clientId)
          .valueChanges()
          .pipe(
            tap((client) => {
              this.model = client;
              this.modelSubject.next(client);
            })
          );
      })
    );
    this.locations$ = this.clientId$.pipe(
      filter((clientId) => !!clientId),
      switchMap((clientId) => {
        return this.afs.collection(DB.CLIENTS.ID).doc(clientId).collection<IClientLocation>(DB.CLIENTS.LOCATIONS.ID).valueChanges();
      })
    );

    this.clientMenus$ = this.clientId$.pipe(
      filter((clientId) => !!clientId),
      switchMap((clientId) => {
        return this.afs.collection(DB.CLIENTS.ID).doc(clientId).collection<IMenu>(DB.CLIENTS.MENUS.ID).valueChanges();
      })
    );
  }

  setClientId(clientId: string) {
    console.log('TCL: ClientBuilderService -> setClientId -> clientId', clientId);
    this.clientIdSubject.next(clientId);
  }

  edit(client: IClient) {
    this.model = client;
    if (!this.model.hours) {
      this.model.hours = new WeeklyHours().export();
    }
    if (!this.model.featureFlags) {
      this.model.featureFlags = DEFAULT_FEATUREFLAGS;
    }
    if (!this.model.featureFlags.firstOrderReward) {
      this.model.featureFlags.firstOrderReward = DEFAULT_FEATUREFLAGS.firstOrderReward;
    }
    if (!this.model.rewards) {
      this.model.rewards = DEFAULT_REWARDS;
    }
    this.modelSubject.next(client);
    this.isEdit = true;
    this.isEditSubject.next(this.isEdit);
    this.clientIdSubject.next(client.id);
  }

  reset() {
    this.isEdit = false;
    this.isEditSubject.next(false);
    this.rawFaviconFile = null;
    this.base64Favicon = null;
    this.rawIconFile = null;
    this.base64Icon = null;
    this.rawLogoFile = null;
    this.base64Logo = null;
    this.rawMobileBackgroundFile = null;
    this.base64MobileBackground = null;
    this.rawDesktopBackgroundFile = null;
    this.base64DesktopBackground = null;
    this.model = {
      id: this.afs.createId(),
      name: '',
      domain: '',
      row: 0,
      isActive: false,
      assets: {
        logo: {},
        icon: {},
        background: {},
      },
      hours: new WeeklyHours().export(),
      rewards: DEFAULT_REWARDS,
      keys: {
        stripe: null,
      },
      stripeConnect: {
        account: null,
        fee: {
          type: 'rate',
          value: 0.03,
        },
      },
      featureFlags: DEFAULT_FEATUREFLAGS,
    } as IClient;
  }

  async save() {
    if (this.model.domain.length === 0 || this.model.name.length === 0) {
      await this.showAlert('Please enter a domain and a name');
      return;
    }
    await this.updateAsset();
  }

  async preview(event: any, name: string) {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      const error = 'Only images are supported.';
      await this.showAlert('Not Supported', error);
      return;
    }
    const reader = new FileReader();
    this.file = files[0];
    console.log({ file: this.file });
    reader.readAsDataURL(this.file);
    reader.onload = async () => {
      const now = new Date().toISOString();
      const file = this.file;
      switch (name) {
        case 'logo':
          try {
            this.base64Logo = reader.result;
            const dimes = await dimensions(file);

            // invalid
            if (dimes.height < 512 || dimes.width < 512) {
              this.file = null;
              this.base64Logo = null;
              this.rawLogoFile = null;
              await this.showAlert('Image too small', 'Minimum size allowed 512x512');
              return;
            }

            // small
            const smallBase64 = await this.resizer.resizeImage(this.base64Logo, 1, 128);
            this.model.assets.logo.small = await this.uploadFile(`${this.model.domain}/${this.clientId}/logo/small.${now}`, file, smallBase64);

            // medium
            const mediumBase64 = await this.resizer.resizeImage(this.base64Logo, 1, 256);
            this.model.assets.logo.medium = await this.uploadFile(`${this.model.domain}/${this.clientId}/logo/medium.${now}`, file, mediumBase64);

            // large
            const largeBase64 = await this.resizer.resizeImage(this.base64Logo, 1, 512);
            this.model.assets.logo.large = await this.uploadFile(`${this.model.domain}/${this.clientId}/logo/large.${now}`, file, largeBase64);
          } catch (error) {
            console.log(error);
          }

          break;
        case 'icon':
          this.base64Icon = reader.result;
          this.model.assets.icon.primary = await this.uploadFile(`${this.model.domain}/${this.clientId}/icon/primary.${now}`, file);
          break;
        case 'favicon':
          this.base64Favicon = reader.result;
          const faviconBase64 = await this.resizer.resizeImage(this.base64Favicon, 1, 24);
          this.model.assets.icon.favicon = await this.uploadFile(`${this.model.domain}/${this.clientId}/icon/favicon.${now}`, file, faviconBase64);
          break;
        case 'mobileBackground':
          this.base64MobileBackground = reader.result;
          this.model.assets.background.mobile = await this.uploadFile(`${this.model.domain}/${this.clientId}/background/mobile.${now}`, file);
          break;
        case 'desktopBackground':
          this.base64DesktopBackground = reader.result;
          this.model.assets.background.desktop = await this.uploadFile(`${this.model.domain}/${this.clientId}/background/desktop.${now}`, file);
          break;
      }
    };
  }

  async uploadFile(path: string, file: File, base64?: string): Promise<string> {
    const fileRef = this.storage.ref(path);
    let task: AngularFireUploadTask;
    if (!base64) {
      task = this.storage.upload(path, file);
    } else {
      const x = `data:${file.type};base64,`;
      console.log({ x });
      task = fileRef.putString(`${x}${base64}`, 'data_url');
    }
    await task.snapshotChanges().toPromise();
    const url = await fileRef.getDownloadURL().toPromise();
    return url;
  }

  async updateAsset(reset = true) {
    await this.showLoading('updating asset');
    if (this.model.appUrl) {
      this.model.appUrl = this.model.appUrl.replace(' ', '');
    }
    try {
      if (this.model.featureFlags && this.model.featureFlags.firstOrderReward && this.model.featureFlags.firstOrderReward.item) {
        this.model.featureFlags.firstOrderReward.item.price = 0;
      }
      await this.afs.collection<IClient>(DB.CLIENTS.ID).doc(this.model.id).set(this.model);
      if (reset) {
        this.reset();
      }
      await this.hideLoading();
    } catch (error) {
      console.log(error);
      await this.showAlert('Error Saving Client');
    }
  }

  async showAlert(header: string, subheader?: string) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      buttons: [
        {
          text: 'Ok',
        },
      ],
      animated: true,
      backdropDismiss: true,
      translucent: false,
      keyboardClose: true,
    });
    await alert.present();
  }
  async showLoading(message?: string) {
    await this.hideLoading();
    this.loading = await this.loadingController.create({
      translucent: true,
      message: message,
    });
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  updateHours(hours: IWeeklyHours) {
    return this.clientId$.pipe(
      switchMap((clientId) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc<IClient>(clientId)
          .set({ hours } as IClient, { merge: true });
      })
    );
  }
  updateRewards(rewards: IRewards) {
    return this.clientId$.pipe(
      switchMap((clientId) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc<IClient>(clientId)
          .set({ rewards } as IClient, { merge: true });
      })
    );
  }
}
