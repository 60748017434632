<ion-header style="opacity:0.99;">
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>Location Hours</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen color="light" style="opacity:0.99;">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card color="white">
          <ion-card-content>
            <app-weekly-hours *ngIf="hours" [showHours]="true" [hours]="hours"></app-weekly-hours>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
