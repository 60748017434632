<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>Map</ion-title>
    <ion-buttons slot="primary">
      <ion-button *ngIf="address" color="light" fill="outline" (click)="getDirections()">
        Directions
        <ion-icon color="light" slot="end" name="navigate"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen>
  <iframe *ngIf="url" [src]="url" frameborder="0" style="border:0;height:100%;width:100%;" allowfullscreen></iframe>
</ion-content>