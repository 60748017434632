import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, LoadingController } from '@ionic/angular';
import { AuthService } from '../../shared/providers/auth.service';
import { IMenu } from '../../../../../shared/models/i-menu';
import { Observable } from 'rxjs';
import { IClient } from '../../../../../shared/models/i-client';
import { AngularFirestore } from '@angular/fire/firestore';
import { DB } from '../../../../../shared/constants/db';

@Component({
  selector: 'app-transfer-menu-modal',
  templateUrl: './transfer-menu-modal.component.html',
  styleUrls: ['./transfer-menu-modal.component.scss']
})
export class TransferMenuModalComponent implements OnInit {
  private loading: HTMLIonLoadingElement;

  clients$: Observable<IClient[]>;
  menu: IMenu;
  selectedClientId: string;

  constructor (
    params: NavParams,
    public modalController: ModalController,
    private afs: AngularFirestore,
    private loadingController: LoadingController
  ) {
    this.menu = params.get('menu');
    this.selectedClientId = params.get('selectedClientId');
    this.clients$ = this.afs.collection<IClient>(DB.CLIENTS.ID).valueChanges();
  }

  ngOnInit () {}

  dismiss () {
    this.modalController.dismiss();
  }
  async menuDestination (clientId: string) {
    await this.showLoading();
    if (!this.menu) {
      return new Error('No menu was provided for transfer!');
    }
    if (!clientId) {
      return new Error('No clientId was provided for transfer!');
    }
    const copy = Object.assign({}, this.menu);
    copy.id = this.afs.createId();
    copy.name = `${copy.name} Copy`;

    await this.afs
      .collection<IClient>(DB.CLIENTS.ID)
      .doc(clientId)
      .collection<IMenu>(DB.CLIENTS.MENUS.ID)
      .doc(copy.id)
      .set(copy);

    await this.hideLoading();
    this.dismiss();
  }

  async showLoading (message?: string) {
    await this.hideLoading();
    this.loading = await this.loadingController.create({
      translucent: true,
      message: message
    });
    await this.loading.present();
  }

  async hideLoading () {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }
}
