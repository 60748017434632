import { OrdersService } from './../../shared/providers/orders.service';
import { COLOR } from '../../../../../shared/constants/color';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { STATUS } from '../../../../../shared/constants/status';
import { ClientService } from '../../shared/providers/client.service';
import { LocationService } from '../../shared/providers/location.service';
import { AuthService } from '../../shared/providers/auth.service';
import { IOrder } from '../../../../../shared/models/i-order';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { startOfMonth, addMonths } from 'date-fns';
import { formatDate } from '@angular/common';
import { TransitMethods } from '../../../../../shared/constants/transit-methods';

@Component({
  selector: 'app-order-history-modal',
  templateUrl: './order-history-modal.component.html',
  styleUrls: ['./order-history-modal.component.scss'],
})
export class OrderHistoryComponent implements OnInit, OnDestroy {
  orders: IOrder[];
  loading: HTMLIonLoadingElement;
  private subscriptions = new Array<Subscription>();
  STATUS = STATUS;
  COLOR = COLOR.NAME;
  startDate = new FormControl();
  endDate = new FormControl();
  clientId: string;
  locationId: string;
  allOrders = new EventEmitter<IOrder[]>();
  foundOrders: IOrder[];
  getOrdersSubscription: Subscription;

  constructor(
    private clientService: ClientService,
    private locationService: LocationService,
    private loadingController: LoadingController,
    public modalController: ModalController,
    public navController: NavController,
    public auth: AuthService,
    private ordersService: OrdersService
  ) {}
  ngOnInit() {
    const firstOfTheMonth = startOfMonth(new Date());
    const today = new Date();
    const oneMonthAgo = addMonths(firstOfTheMonth, -1);

    const searchSubscription = combineLatest([
      this.auth.user$,
      this.clientService.clientId$,
      this.locationService.locationId$,
      this.startDate.valueChanges,
      this.endDate.valueChanges,
    ])
      .pipe(filter(([user, clientId, locationId]) => !!user || !!clientId || !!locationId))
      .subscribe(([user, clientId, locationId, start, end]) => {
        this.clientId = clientId;
        this.locationId = locationId;
        if (user) {
          this.getOrders(clientId, locationId, start, end);
        }
      });
    this.subscriptions.push(searchSubscription);

    this.endDate.setValue(today.toISOString(), { emitEvent: true });
    this.startDate.setValue(oneMonthAgo.toISOString(), { emitEvent: true });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    if (this.getOrdersSubscription) {
      this.getOrdersSubscription.unsubscribe();
    }
  }

  async showLoading(text?: string) {
    this.loading = await this.loadingController.create({
      translucent: true,
      message: text,
    });
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async goToDetails(order: IOrder) {
    if (!order || !order.email) {
      throw new Error('Order missing email');
    }
    await this.hideLoading();
    this.navController.navigateForward(['orders', order.id, 'clients', order.clientId, 'locations', order.locationId]);

    this.dismiss();
  }

  foundOrdersChange(orders: IOrder[]) {
    this.foundOrders = orders;
  }

  statusColor(status: string): string {
    switch (status) {
      case STATUS.PENDING:
        return COLOR.NAME.DARK;
      case STATUS.PAID:
        return COLOR.NAME.SECONDARY;
      case STATUS.PROCESSING:
        return COLOR.NAME.PRIMARY;
      case STATUS.READY:
        return COLOR.NAME.SUCCESS;
      case STATUS.COMPLETE:
        return COLOR.NAME.TERTIARY;
      default:
        return COLOR.NAME.DARK;
    }
  }

  getOrders(clientId: string, locationId: string, startDate?: Date, endDate?: Date) {
    if (!this.auth.user) {
      console.log('No user can not do orders query');
      return;
    }
    try {
      if (this.getOrdersSubscription) {
        this.getOrdersSubscription.unsubscribe();
      }
      this.getOrdersSubscription = this.ordersService.getOrdersForUser(clientId, locationId, startDate, endDate).subscribe((orders) => {
        this.allOrders.emit(orders);
      });
    } catch (error) {
      console.log('ERROR -- CAN NOT FIND ORDERS', error);
    }
  }
  getTime(date) {
    if (!date) {
      return;
    }
    return formatDate(date, 'h:mm a', 'en-US');
  }
  getDate(date) {
    if (!date) {
      return;
    }
    return formatDate(date, 'd/M/yy', 'en-US');
  }
  processName(name: string) {
    if (name.includes('@')) {
      const res = name.split('@');
      return res[0];
    } else {
      return name;
    }
  }
  numToPrice(str) {
    if (!str) {
      return '0.00';
    }
    return str.toFixed(2);
  }
  isPrinted(order: IOrder) {
    if (order.printInfo.printed) {
      return true;
    } else {
      return false;
    }
  }

  orderPlacedInAdvance(order: IOrder) {
    if (!order.transitInfo) {
      return false;
    } else if (order.transitInfo.transitMethod === TransitMethods.PICKUP_LATER) {
      return true;
    } else if (order.transitInfo.transitMethod === TransitMethods.DELIVERY) {
      return true;
    } else {
      return false;
    }
  }
}
