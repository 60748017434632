import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import { DB } from '../../../../../shared/constants/db';
import { IEarnedReward } from '../../../../../shared/models/i-earned-reward';
import { IRewards } from '../../../../../shared/models/i-rewards';
import { IUser } from '../../../../../shared/models/i-user';
import { AuthService } from './auth.service';
import { ClientService } from './client.service';

@Injectable()
export class RewardsService {
  public rewards$: Observable<IRewards>;
  public earned$: Observable<IEarnedReward[]>;
  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private clientService: ClientService
  ) {
    this.rewards$ = combineLatest([this.clientService.client$, this.auth.user$]).pipe(
      filter(([client, user]) => !!client && !!user),
      switchMap(([client, user]) => {
        // get reference to document
        const rewardsRef = this.afs
          .collection<IUser>(DB.USERS.ID)
          .doc<IUser>(user.uid)
          .collection<IRewards>(DB.USERS.REWARDS.ID)
          .doc<IRewards>(client.domain);

        // create if it does not exists
        rewardsRef.set(client.rewards, { merge: true });
        return rewardsRef.valueChanges();
      })
    );

    this.earned$ = combineLatest([this.auth.user$, this.clientService.client$]).pipe(
      first(([user, client]) => !!user && !!client),
      switchMap(([user, client]) => {
        return this.afs
          .collection(DB.USERS.ID)
          .doc(user.uid)
          .collection(DB.USERS.REWARDS.ID)
          .doc(client.domain)
          .collection<IEarnedReward>(
            DB.USERS.REWARDS.HISTORY.ID,
            (ref: firebase.firestore.CollectionReference | firebase.firestore.Query) => {
              return ref.where('used', '==', false).limit(50);
            }
          )
          .valueChanges();
      })
    );
  }
}
