import { ContactUserModalComponent } from './contact-user-modal/contact-user-modal.component';
import { NgModule } from '@angular/core';
import { ProfileModalComponent } from './profile-modal/profile-modal.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HoursModalComponent } from './hours-modal/hours-modal.component';
import { MapModalComponent } from './map-modal/map-modal.component';
import { OrderHistoryComponent } from './order-history-modal/order-history-modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LocationsModalComponent } from './locations-modal/locations-modal.component';
import { ModalsService } from './modals.service';
import { SharedComponentsModule } from '../shared/components/shared.components.module';
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { DebugMenuModalComponent } from './debug-menu-modal/debug-menu-modal.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { SearchUserFeedbackPipe } from '../shared/pipes/search-user-feedback.pipe';
import { TransferMenuModalComponent } from './transfer-menu-modal/transfer-menu-modal.component';
import { TransferItemsModalComponent } from './transfer-items-modal/transfer-items-modal.component';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, SharedPipesModule, SharedComponentsModule],
  exports: [
    ProfileModalComponent,
    HoursModalComponent,
    MapModalComponent,
    DebugMenuModalComponent,
    FeedbackModalComponent,
    OrderHistoryComponent,
    LoginModalComponent,
    LocationsModalComponent,
    ContactUserModalComponent,
    TransferMenuModalComponent,
    TransferItemsModalComponent
  ],
  entryComponents: [
    LoginModalComponent,
    ProfileModalComponent,
    HoursModalComponent,
    MapModalComponent,
    DebugMenuModalComponent,
    FeedbackModalComponent,
    OrderHistoryComponent,
    LocationsModalComponent,
    ContactUserModalComponent,
    TransferMenuModalComponent,
    TransferItemsModalComponent
  ],
  declarations: [
    ProfileModalComponent,
    HoursModalComponent,
    MapModalComponent,
    DebugMenuModalComponent,
    FeedbackModalComponent,
    OrderHistoryComponent,
    LoginModalComponent,
    LocationsModalComponent,
    ContactUserModalComponent,
    TransferMenuModalComponent,
    TransferItemsModalComponent
  ],
  providers: [ModalsService, TitleCasePipe, SearchUserFeedbackPipe]
})
export class ModalModule {}
