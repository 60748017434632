<ion-header style="opacity: 0.99;">
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>Contact Customer</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen color="light" style="opacity: 0.99;" *ngIf="userId">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card color="white">
          <ion-card-header>
            <ion-card-title>Contact</ion-card-title>
            <ion-card-subtitle style="font-weight: bold;">{{ userName }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <ion-item *ngIf="userEmail">
                <ion-label>Email: {{ userEmail }}</ion-label>
              </ion-item>
              <ion-item *ngIf="userPhone">
                <ion-label>Phone: {{ userPhone }}</ion-label>
              </ion-item>
              <ion-item *ngIf="platform">
                <ion-label>Platform: {{ formatPlatformName(platform) }}</ion-label>
              </ion-item>
              <ion-item *ngIf="platform === 'IOS' || platform === 'ANDROID'">
                <ion-label position="stacked">Send User Push Notification</ion-label>
                <ion-input [(ngModel)]="messageToUser" placeholder="message"></ion-input>
                <ion-button style="min-width: 100px;" (click)="sendNotification()">Send</ion-button>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
