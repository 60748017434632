import { IUserFeedback } from '../../../../../shared/models/i-user-feedback';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchUserFeedbackPipe'
})
export class SearchUserFeedbackPipe implements PipeTransform {
  private input = '';
  transform(input: IUserFeedback[], target: string): any {
    if (!input || input.length === 0 || !target || target.length === 0) {
      return input;
    }
    this.input = target && target.toLowerCase();
    return input.filter(
      item =>
        this.matchByClientId(item) ||
        this.matchByLocationId(item) ||
        this.matchByUserId(item) ||
        this.matchByName(item)
    );
  }

  matchByClientId(feedback: IUserFeedback): boolean {
    return (
      feedback &&
      feedback.clientId &&
      feedback.clientId.length > 0 &&
      feedback.clientId.toLowerCase().includes(this.input)
    );
  }
  matchByName(feedback: IUserFeedback): boolean {
    return (
      feedback &&
      feedback.name &&
      feedback.name.length > 0 &&
      feedback.name.toLowerCase().includes(this.input)
    );
  }
  matchByLocationId(feedback: IUserFeedback): boolean {
    return (
      feedback &&
      feedback.locationId &&
      feedback.locationId.length > 0 &&
      feedback.locationId.toLowerCase().includes(this.input)
    );
  }
  matchByUserId(feedback: IUserFeedback): boolean {
    return (
      feedback &&
      feedback.userId &&
      feedback.userId.length > 0 &&
      feedback.userId.toLowerCase().includes(this.input)
    );
  }
}
