<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="secondary">
      <ion-button color="danger" (click)="dismiss()"> Close </ion-button>
    </ion-buttons>
    <ion-title>All the Debug Info</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row class="ion-justify-content-center">
    <ion-col>
      <ion-list>
        <ion-list-header>
          <ion-label class="info-title">Client Info</ion-label>
        </ion-list-header>
        <!-- VERSION -->
        <ion-item>
          <ion-label>Version: {{ version }}</ion-label>
          <ion-icon slot="end" name="information-circle"></ion-icon>
        </ion-item>
        <!-- DOMAIN -->
        <ion-item>
          <ion-label>Domain: {{ domain }}</ion-label>
          <ion-icon slot="end" name="cloud-circle"></ion-icon>
        </ion-item>
        <!-- COLORS -->
        <ion-list>
          <ion-list-header>
            <ion-button (click)="showColors = !showColors">Show Colors </ion-button>
            <ion-icon slot="end" name="color-fill"></ion-icon>
          </ion-list-header>
          <div *ngIf="showColors">
            <ion-item color="primary">
              <ion-label>Primary</ion-label>
            </ion-item>
            <ion-item color="secondary">
              <ion-label>Secondary</ion-label>
            </ion-item>
            <ion-item color="tertiary">
              <ion-label>Tertiary</ion-label>
            </ion-item>
            <ion-item color="success">
              <ion-label>Success</ion-label>
            </ion-item>
            <ion-item color="warning">
              <ion-label>Warning</ion-label>
            </ion-item>
            <ion-item color="danger">
              <ion-label>Danger</ion-label>
            </ion-item>
            <ion-item color="dark">
              <ion-label>Dark</ion-label>
            </ion-item>
            <ion-item color="medium">
              <ion-label>Medium</ion-label>
            </ion-item>
            <ion-item color="light">
              <ion-label>Light</ion-label>
            </ion-item>
            <ion-item color="white">
              <ion-label>White</ion-label>
            </ion-item>
          </div>
        </ion-list>
        <!-- CLIENT -->
        <ion-list *ngIf="client">
          <ion-list-header>
            <ion-label>Client</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>Client Id: {{ client.id }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Client Name: {{ client.name }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>Client Domain: {{ client.domain }}</ion-label>
          </ion-item>
        </ion-list>
        <ion-item *ngIf="!client">
          <ion-label color="danger">No Client Found!</ion-label>
        </ion-item>
        <!-- LOCATIONS -->
        <ion-list *ngIf="locations">
          <ion-list-header>
            <ion-label>Locations</ion-label>
          </ion-list-header>
          <div *ngFor="let locationFound of locations">
            <ion-item>
              <ion-label>Location Id: {{ locationFound.id }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>Location Active: {{ locationFound.isActive }}</ion-label>
            </ion-item>
          </div>
        </ion-list>
        <ion-item *ngIf="!locations">
          <ion-label color="danger">No Locations Found!</ion-label>
        </ion-item>
        <!-- USER -->
        <ion-list *ngIf="user">
          <ion-list-header>
            <ion-label>User</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label class="info-label">roles: {{ rolesString(user) }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>uid: {{ user.uid }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label>email: {{ user.email }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="info-label">settings: {{ settingsString(user) }}</ion-label>
          </ion-item>
        </ion-list>
        <ion-item *ngIf="!user">
          <ion-label color="danger">No user Found!</ion-label>
        </ion-item>
        <!-- ORDER COUNT -->
        <ion-item *ngIf="orderCount">
          <ion-label>Order Count: {{ orderCount }}</ion-label>
          <ion-icon slot="end" name="list"></ion-icon>
        </ion-item>
        <ion-item *ngIf="!orderCount">
          <ion-label color="danger">No user Orders Found!</ion-label>
        </ion-item>
      </ion-list>
    </ion-col>
    <ion-col>
      <ion-list>
        <ion-list-header>
          <ion-label class="info-title">Device</ion-label>
        </ion-list-header>
        <!-- TOKEN -->
        <ion-item>
          <ion-label>Token: {{ fcmToken }}</ion-label>
          <ion-icon slot="end" name="chatbubbles-outline"></ion-icon>
        </ion-item>
        <!-- SELECTED LOCATION -->
        <ion-item>
          <ion-label class="info-label">Selected Location: {{ selectedLocation ? selectedLocation.id : 'none' }} </ion-label>
          <ion-label class="info-label"
            >Is Open:
            {{ selectedLocationHours ? selectedLocationHours.isOpen : 'unknown' }}
          </ion-label>
          <ion-icon slot="end" name="cafe"></ion-icon>
        </ion-item>
        <!-- PLATFORM TYPE -->
        <ion-item>
          <ion-label>Platform Type: {{ platformType }}</ion-label>
          <ion-icon slot="end" name="phone-portrait"></ion-icon>
        </ion-item>
        <!-- PLATFORM AGENT -->
        <ion-item>
          <ion-label>Platform Agent: {{ platformBuildAgent ? platformBuildAgent : 'Web' }}</ion-label>
          <ion-icon slot="end" name="phone-portrait"></ion-icon>
        </ion-item>
        <!-- IS NATIVE -->
        <ion-item>
          <ion-label>Is Native: {{ isNative }}</ion-label>
          <ion-icon slot="end" name="code-working"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
</ion-content>
