import { PlatformType } from './../../../../../shared/constants/platform-type';
import { Injectable, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PlatformTypes } from '../../../../../shared/constants/platform-type';
import { Plugins, DeviceInfo } from '@capacitor/core';

const CORDOVA = 'cordova';
const CAPACITOR = 'capacitor';

const { Device } = Plugins;
let deviceInfo: DeviceInfo;

@Injectable({
  providedIn: 'root',
})
export class DeviceService implements OnInit {
  constructor(public platform: Platform) {}

  async ngOnInit() {
    deviceInfo = await Device.getInfo();
  }

  public isNative(): boolean {
    return this.platform.is(CORDOVA) || this.platform.is(CAPACITOR);
  }

  public getPlatformAgent(): null | 'cordova' | 'capacitor' {
    if (this.platform.is(CAPACITOR)) {
      return CAPACITOR;
    } else if (this.platform.is(CORDOVA)) {
      return CORDOVA;
    } else {
      return null;
    }
  }

  public async getPlatformType(): Promise<PlatformType> {
    if (!deviceInfo) {
      deviceInfo = await Device.getInfo();
    }
    if (this.platform.is('desktop')) {
      return PlatformTypes.WEBDESKTOP as PlatformType;
    } else if (this.platform.is('mobileweb')) {
      return PlatformTypes.WEBMOBILE as PlatformType;
    } else if (this.isNative() ? deviceInfo.platform.toLowerCase() === 'android' : false) {
      return PlatformTypes.ANDROID as PlatformType;
    } else if (this.isNative() ? deviceInfo.platform.toLowerCase() === 'ios' : false) {
      return PlatformTypes.IOS as PlatformType;
    } else {
      return PlatformTypes.WEBDESKTOP as PlatformType;
    }
  }
}
