import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavParams, ModalController, LoadingController } from '@ionic/angular';
import { AuthService } from '../../shared/providers/auth.service';
import { IMenu } from '../../../../../shared/models/i-menu';
import { Observable, Subscription } from 'rxjs';
import { IClient } from '../../../../../shared/models/i-client';
import { AngularFirestore } from '@angular/fire/firestore';
import { DB } from '../../../../../shared/constants/db';
import { IMenuItem } from '../../../../../shared/models/i-menu-item';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-items-modal',
  templateUrl: './transfer-items-modal.component.html',
  styleUrls: ['./transfer-items-modal.component.scss']
})
export class TransferItemsModalComponent implements OnInit, OnDestroy {
  private loading: HTMLIonLoadingElement;
  private subscriptions = new Array<Subscription>();

  clients$: Observable<IClient[]>;
  items: IMenuItem[];
  selectedClientId: string;

  constructor (
    params: NavParams,
    public modalController: ModalController,
    private afs: AngularFirestore,
    private loadingController: LoadingController
  ) {
    this.selectedClientId = params.get('selectedClientId');
    this.clients$ = this.afs.collection<IClient>(DB.CLIENTS.ID).valueChanges();
  }

  ngOnInit () {}
  ngOnDestroy () {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
  async dismiss () {
    await this.hideLoading();
    this.modalController.dismiss();
  }
  async itemsDestination (clientId: string) {
    await this.showLoading();
    if (!clientId) {
      return new Error('No clientId was provided for transfer!');
    }
    if (clientId === this.selectedClientId) {
      return new Error('Can not transfer items to itself');
    }
    const itemsSubscription = this.afs
      .collection<IClient>(DB.CLIENTS.ID)
      .doc(this.selectedClientId)
      .collection<IMenuItem>(DB.CLIENTS.ITEMS.ID)
      .get()
      .pipe(first(items => !!items))
      .subscribe(itemsSnapshots => {
        const items = itemsSnapshots.docs;
        items.map(item => {
          if (item) {
            return;
          }
          if (!item.id) {
            return;
          }
          return item;
        });
        for (let index = 0; index < items.length; index++) {
          const item = items[index];
          const copy = Object.assign({}, item.data() as IMenuItem);
          this.afs
            .collection<IClient>(DB.CLIENTS.ID)
            .doc(clientId)
            .collection<IMenu>(DB.CLIENTS.ITEMS.ID)
            .doc(copy.id)
            .set(copy)
            .then(() => {
              if (index === items.length - 1) {
                this.dismiss();
              }
            });
        }
      });
    this.subscriptions.push(itemsSubscription);
  }

  async showLoading (message?: string) {
    await this.hideLoading();
    this.loading = await this.loadingController.create({
      translucent: true,
      message: message
    });
    await this.loading.present();
  }

  async hideLoading () {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }
}
