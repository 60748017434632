import { Injectable } from '@angular/core';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class StorageService {
  storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  get(key: string): string {
    return this.storage.getItem(key);
  }

  getObject(key: string): any {
    const value = this.storage.getItem(key);
    const obj = value && value.length > 0 ? JSON.parse(value) : null;
    return obj;
  }

  set(key: string, value: any): void {
    value = typeof value === 'string' ? value : JSON.stringify(value);
    return this.storage.setItem(key, value);
  }

  deleteAll(): void {
    this.storage.clear();
  }

  delete(key: string): void {
    this.storage.removeItem(key);
  }
}
