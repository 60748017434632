import { Injectable } from '@angular/core';
import { DeviceService } from './device.service';
import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;

@Injectable({ providedIn: 'root' })
export class BrowserService {
  constructor(private device: DeviceService) {}

  private openWithInAppBrowser(url: string) {
    return Browser.open({ url: url });
  }
  public async open(url: string) {
    if (this.device.isNative()) {
      await this.openWithInAppBrowser(url);
    } else {
      window.open(url);
    }
  }
}
