import { PhoneInputComponent } from './phone-input/phone-input.component';
import { FeedbackSearchBarComponent } from './feedback-search-bar/feedback-search-bar.component';
import { SearchOrdersPipe } from './../pipes/search-orders.pipe';
import { OrderSearchBarComponent } from './order-search-bar/order-search-bar.component';
import { ItemSearchBarComponent } from './item-search-bar/item-search-bar.component';
import { TipService } from './../providers/tip.service';
import { StripeIconComponent } from './stripe-icon/stripe-icon.component';
import { TwilioIconComponent } from './twilio-icon/twilio-icon.component';
import { EditHoursComponent } from './edit-hours/edit-hours.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserQrCodeComponent } from './user-qr-code/user-qr-code.component';
import { RewardsCardComponent } from './rewards-card/rewards-card.component';
import { BadgedCartIconComponent } from './../../shared/components/badged-cart-icon/badged-cart-icon.component';
import { CartComponent } from './cart/cart.component';
import { UserBannerComponent } from './user-banner/user-banner.component';
import { OrderProgressComponent } from './order-progress/order-progress.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { LocationAddressComponent } from './location-address/location-address.component';
import { LocationHoursComponent } from './location-hours/location-hours.component';
import { HrLabelComponent } from './hr-label/hr-label.component';
import { PrivacyAndTermsComponent } from './privacy-and-terms/privacy-and-terms.component';
import { MembersOnlyComponent } from './members-only/members-only.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { OptionLocationComponent } from './option-location/option-location.component';
import { OptionQuantityComponent } from './option-quantity/option-quantity.component';
import { OptionAmountComponent } from './option-amount/option-amount.component';
import { OrderItemLabelComponent } from './order-item-label/order-item-label.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { QrGeneratorComponent } from './qr-generator/qr-generator.component';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { QrCodeIconComponent } from './qr-code-icon/qr-code-icon.component';
import { WeeklyHoursComponent } from './weekly-hours/weekly-hours.component';
import { CardTemplateComponent } from './card-template/card-template.component';
import { StripeConnectIconComponent } from './stripe-connect-icon/stripe-connect-icon.component';
import { StripeConnectErrorComponent } from './stripe-connect-error/stripe-connect-error.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AtmInputComponent } from './atm-input/atm-input.component';
import { SelectLocationMenusComponent } from './select-location-menus/select-location-menus.component';
import { LocationBuilderService } from '../../location-builder/location-builder.service';
import { SearchMenusPipe } from '../pipes/search-menus.pipe';
import { SearchLocationsPipe } from '../pipes/search-locations.pipe';
import { DragulaModule } from 'ng2-dragula';
import { Ng2OdometerModule } from 'ng2-odometer';
import { DeviceService } from '../providers/device.service';
import { EditItemComponent } from './edit-item/edit-item.component';
import { BrowserService } from '../providers/browser.service';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { OrderTimePickerComponent } from './order-time-picker/order-time-picker.component';
import { CartItemsComponent } from './cart-items/cart-items.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FlexLayoutModule,
    SharedPipesModule,
    ReactiveFormsModule,
    DragulaModule.forRoot(),
    Ng2OdometerModule.forRoot(),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 60,
      maxPercent: 100,
      showTitle: false,
      showUnits: false,
      showSubtitle: false,
      showImage: true,
      showBackground: false,
      showInnerStroke: false,
      startFromZero: true,
      outerStrokeWidth: 5,
      innerStrokeWidth: 4,
      animationDuration: 800,
      lazy: false,
    }),
    ZXingScannerModule,
  ],
  entryComponents: [],
  declarations: [
    HrLabelComponent,
    MembersOnlyComponent,
    PrivacyAndTermsComponent,
    LocationHoursComponent,
    LocationAddressComponent,
    OrderDetailComponent,
    ProgressBarComponent,
    OrderProgressComponent,
    UserBannerComponent,
    OptionLocationComponent,
    OptionQuantityComponent,
    OptionAmountComponent,
    OrderItemLabelComponent,
    CartComponent,
    CartItemsComponent,
    UserBannerComponent,
    BadgedCartIconComponent,
    QrGeneratorComponent,
    QrScannerComponent,
    QrCodeIconComponent,
    WeeklyHoursComponent,
    CardTemplateComponent,
    TwilioIconComponent,
    StripeIconComponent,
    StripeConnectIconComponent,
    StripeConnectErrorComponent,
    RewardsCardComponent,
    UserQrCodeComponent,
    AtmInputComponent,
    EditHoursComponent,
    SelectLocationMenusComponent,
    ItemSearchBarComponent,
    OrderSearchBarComponent,
    FeedbackSearchBarComponent,
    EditItemComponent,
    PhoneInputComponent,
    OrderTimePickerComponent,
  ],

  providers: [LocationBuilderService, SearchMenusPipe, SearchLocationsPipe, TipService, SearchOrdersPipe, DeviceService, BrowserService],
  exports: [
    HrLabelComponent,
    MembersOnlyComponent,
    PrivacyAndTermsComponent,
    LocationHoursComponent,
    LocationAddressComponent,
    OrderDetailComponent,
    ProgressBarComponent,
    OrderProgressComponent,
    UserBannerComponent,
    OptionLocationComponent,
    OptionQuantityComponent,
    OptionAmountComponent,
    OrderItemLabelComponent,
    CartComponent,
    CartItemsComponent,
    UserBannerComponent,
    BadgedCartIconComponent,
    QrGeneratorComponent,
    QrScannerComponent,
    QrCodeIconComponent,
    WeeklyHoursComponent,
    CardTemplateComponent,
    TwilioIconComponent,
    StripeIconComponent,
    StripeConnectIconComponent,
    StripeConnectErrorComponent,
    RewardsCardComponent,
    UserQrCodeComponent,
    AtmInputComponent,
    EditHoursComponent,
    SelectLocationMenusComponent,
    ItemSearchBarComponent,
    OrderSearchBarComponent,
    FeedbackSearchBarComponent,
    EditItemComponent,
    PhoneInputComponent,
    OrderTimePickerComponent,
  ],
})
export class SharedComponentsModule {}
