import { Injectable } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { ClientService } from './client.service';
import { Observable } from 'rxjs';
import { switchMap, map, tap, filter } from 'rxjs/operators';
import { DB } from '../../../../../shared/constants/db';
import { IClientLocation } from '../../../../../shared/models/i-client-location';
import { LocationService } from './location.service';

@Injectable({ providedIn: 'root' })
export class LocationsService {
  public locations$: Observable<QueryDocumentSnapshot<IClientLocation>[]>;

  constructor(
    private afs: AngularFirestore,
    private clientService: ClientService,
    private locationService: LocationService
  ) {
    this.observeLocationsByClientId();
  }

  private observeLocationsByClientId() {
    this.locations$ = this.clientService.clientId$.pipe(
      filter(clientId => !!clientId),
      switchMap(clientId => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc(clientId)
          .collection<IClientLocation>(DB.CLIENTS.LOCATIONS.ID)
          .snapshotChanges()
          .pipe(
            map(snapshots => {
              return snapshots.map(snapshot => {
                const doc = snapshot.payload.doc;
                return doc;
              });
            })
          );
      })
    );
  }
  getLocation$(clientId: string, locationId: string) {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection(DB.CLIENTS.LOCATIONS.ID)
      .doc<IClientLocation>(locationId)
      .valueChanges();
  }
  getLocations$(clientId: string) {
    return this.afs
      .collection(DB.CLIENTS.ID)
      .doc(clientId)
      .collection<IClientLocation>(DB.CLIENTS.LOCATIONS.ID)
      .valueChanges();
  }
  setLocationIfOnlyOne() {
    this.locations$
      .pipe(
        filter(snapshots => !!snapshots && snapshots.length > 0),
        tap(snapshots => {
          const locations = snapshots
            .map(snapshot => snapshot.data())
            .filter(location => location.isActive);
          if (locations.length === 1) {
            this.locationService.selectLocation(locations[0].id, true);
          }
        })
      )
      .subscribe();
  }
}
