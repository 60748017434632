import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DeviceService } from './device.service';
import { PlatformTypes } from '../../../../../shared/constants/platform-type';

@Injectable({ providedIn: 'root' })
export class AuthPlatformAvailabilityService {
  private signInWithApplePluginInstalled = Capacitor.isPluginAvailable('SignInWithApple');
  private googleAuthPluginInstalled = Capacitor.isPluginAvailable('GoogleAuth');
  private facebookLoginPluginInstalled = Capacitor.isPluginAvailable('FacebookLogin');

  constructor(public deviceService: DeviceService) {}
  async canPlatformUseAppleAuth() {
    const platform = await this.deviceService.getPlatformType();
    if (platform === PlatformTypes.ANDROID) {
      return false;
    }
    // Check if plugin is installed on native device
    if (this.deviceService.isNative() && !this.signInWithApplePluginInstalled) {
      return false;
    }
    return true; // It meets the requirements then true
  }

  canPlatformUseGoogleAuth() {
    // Check if plugin is installed on native device
    if (this.deviceService.isNative() && !this.googleAuthPluginInstalled) {
      return false;
    }
    return true; // It meets the requirements then true
  }

  canPlatformUseFacebookAuth() {
    // Not currently working on native, todo
    if (this.deviceService.isNative()) {
      return false;
    } else {
      return true;
    }
    /*
    // Check if plugin is installed on native device
    if (this.deviceService.isNative() && !this.facebookLoginPluginInstalled) {
      return false;
    }
    return true; // It meets the requirements then true
    */
  }
}
