import { AuthService } from './auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { DomainService } from './domain.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { filter, first } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { API } from '../../../../../shared/constants/api-endpoints';
import { IDomainSubscriptionRequest } from '../../../../../shared/models/i-domain-subscription-request';
import { FCM } from '@capacitor-community/fcm';
import { Plugins, PushNotification, HapticsImpactStyle } from '@capacitor/core';
import { IClientSubscriptionRequest } from '../../../../../shared/models/i-client-subscription-request';
import { ClientService } from './client.service';
import { IUserSubscriptionRequest } from '../../../../../shared/models/i-user-subscription-request';
import { IClientLocationSubscriptionRequest } from '../../../../../shared/models/i-client-location-subscription-request';
import { LocationService } from './location.service';

const { PushNotifications, Haptics } = Plugins;
import { Capacitor } from '@capacitor/core';
import { DeviceService } from './device.service';
import { DB } from '../../../../../shared/constants/db';
import { StorageService } from './storage.service';
import { ToastsService } from './toasts.service';

const fcm = new FCM();

const notificationsAreAvailable = Capacitor.isPluginAvailable('PushNotifications');
const fcmIsAvailable = Capacitor.isPluginAvailable('FCMPlugin');

const KEY = 'savedUserId';

@Injectable({ providedIn: 'root' })
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  private domain: string;
  private token: string;
  private savedUserId: string;

  constructor(
    private afs: AngularFirestore,
    private afsFunctions: AngularFireFunctions,
    private angularFireMessaging: AngularFireMessaging,
    private domainService: DomainService,
    private toastsService: ToastsService,
    private clientService: ClientService,
    private locationService: LocationService,
    private deviceService: DeviceService,
    private authService: AuthService,
    private storage: StorageService
  ) {
    this.savedUserId = this.storage.get(KEY);

    this.domainService.domain$
      .pipe(
        filter((domain) => !!domain),
        first()
      )
      .subscribe((domain) => {
        this.domain = domain;
      });

    this.locationService.onUnSubscribeToLocation.subscribe((oldLocationId) => {
      if (oldLocationId) {
        this.unSubscribeFromLocation(oldLocationId);
      }
    });
  }
  receiveMessage() {
    const isNative = this.deviceService.isNative();
    if (isNative) {
      if (notificationsAreAvailable) {
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
          console.log('Push received: ' + JSON.stringify(notification));
          if (notification && notification.body) {
            this.showNotification(notification.body);
            Haptics.impact({
              style: HapticsImpactStyle.Heavy,
            });
          }
        });
      }
    } else {
      this.angularFireMessaging.onMessage((message: any) => {
        console.log('new message received. ', message);
        if (message && message.notification && message.notification.body) {
          this.showNotification(message.notification.body);
        }
        this.currentMessage.next(message);
      });
    }
  }

  registerWebNotifications() {
    console.log('MessagingService -> registerWebNotifications');
    try {
      this.angularFireMessaging.requestToken.subscribe((token) => {
        console.log('Permission granted! Save to the server!', token);
        this.token = token;
        this.subscribeToTopics(token);
        this.receiveMessage();
      });
    } catch (error) {
      console.error('failed to this.angularFireMessaging.requestToken', error);
    }
  }
  async registerNativeNotifications() {
    console.log('MessagingService -> registerNativeNotifications');
    if (!notificationsAreAvailable || !fcmIsAvailable) {
      console.log(
        'MessagingService -> registerNativeNotifications -> notificationsAreAvailable',
        notificationsAreAvailable,
        'fcmIsAvailable',
        fcmIsAvailable
      );
      return;
    }
    // Register with Apple / Google to receive push via APNS/FCM
    const requestPermissionResult = await Plugins.PushNotifications.requestPermission();
    if (requestPermissionResult && requestPermissionResult.granted) {
      console.log('Push notification permission granted');
    } else {
      console.log('Push notification permission not granted!');
      return;
    }
    await Plugins.PushNotifications.register();

    const tokenData = await fcm.getToken();
    if (tokenData.token) {
      this.token = tokenData.token;

      console.log('MessagingService -> registerNativeNotifications -> token', this.token);

      this.receiveMessage();
      this.subscribeToTopics(tokenData.token);
    }
  }

  unSubscribeFromLocation(oldLocationId: string) {
    const isNative = this.deviceService.isNative();
    if (isNative) {
      fcm.unsubscribeFrom({ topic: oldLocationId });
    } else {
      if (!this.authService.user) {
        return;
      }
      if (this.token) {
        const callable = this.afsFunctions.httpsCallable(API.unSubscribeToClientLocationMessages);
        callable({
          locationId: oldLocationId,
          tokens: [this.token],
        } as IClientLocationSubscriptionRequest).subscribe((subscriptionResult) => {
          console.log('MessagingService -> unSubscribeFromLocation -> subscriptionResult', {
            subscriptionResult,
          });
        });
      }
    }
  }
  unSubscribeFromUser(userId: string, clientId: string) {
    console.log('userId', userId);
    const isNative = this.deviceService.isNative();
    if (isNative) {
      fcm.unsubscribeFrom({ topic: `${clientId}_${userId}` });
    } else {
      console.log('token here', this.token);
      if (this.token) {
        const callable = this.afsFunctions.httpsCallable(API.unSubscribeToUserMessages);
        callable({
          clientId: clientId,
          userId: userId,
          tokens: [this.token],
        } as IUserSubscriptionRequest).subscribe((subscriptionResult) => {
          console.log('MessagingService -> unSubscribeFromUser -> subscriptionResult', {
            subscriptionResult,
          });
        });
      }
    }
  }
  subscribeToTopics(token) {
    const isNative = this.deviceService.isNative();
    this.authService.user$.pipe(first((user) => !!user)).subscribe((user) => {
      if (isNative) {
        this.nativeDomainTopic();
      } else {
        this.webDomainTopic(user.uid, token);
      }
    });
    this.clientService.clientId$.pipe(first((clientId) => !!clientId)).subscribe((clientId) => {
      if (isNative) {
        this.nativeClientTopic(clientId);
      } else {
        this.webClientTopic(clientId, token);
      }
      this.authService.user$.subscribe((user) => {
        if (user) {
          this.storage.set(KEY, user.uid);
          this.savedUserId = user.uid;
        } else if (this.savedUserId) {
          this.unSubscribeFromUser(this.savedUserId, clientId);
        }
      });
    });
    combineLatest([this.authService.user$, this.clientService.clientId$])
      .pipe(first(([user, clientId]) => !!user && !!clientId))
      .subscribe(([user, clientId]) => {
        this.afs
          .collection(DB.MESSAGINGTOKENS.ID)
          .doc(user.uid)
          .collection(DB.MESSAGINGTOKENS.CLIENTS.ID)
          .doc(clientId)
          .collection(DB.MESSAGINGTOKENS.CLIENTS.TOKENS.ID)
          .doc(token)
          .set({ id: token })
          .then((_result) => {
            if (isNative) {
              this.nativeUserTopic(user.uid, clientId);
            } else {
              this.webUserTopic(user.uid, clientId, token);
            }
          });
      });
    // user needs to be signed in to save location to profile
    combineLatest([this.locationService.locationId$, this.clientService.clientId$, this.authService.user$])
      .pipe(first(([locationId, clientId, user]) => !!locationId && !!clientId && !!user))
      .subscribe(([locationId, clientId, user]) => {
        this.afs
          .collection(DB.MESSAGINGTOKENS.ID)
          .doc(user.uid)
          .collection(DB.MESSAGINGTOKENS.CLIENTS.ID)
          .doc(clientId)
          .set({ savedLocationId: locationId }, { merge: true });
        if (isNative) {
          this.nativeLocationTopic(locationId);
        } else {
          this.webLocationTopic(locationId, clientId, token);
        }
      });
  }

  private webDomainTopic(_userId: string, token: string) {
    const callable = this.afsFunctions.httpsCallable(API.subscribeToDomainMessages);
    callable({
      domain: this.domain,
      tokens: [token],
    } as IDomainSubscriptionRequest).subscribe((subscriptionResult) => {
      console.log('MessagingService -> webDomainTopic -> subscriptionResult', {
        subscriptionResult,
      });
    });
  }
  private nativeDomainTopic() {
    fcm.subscribeTo({ topic: this.domain });
  }
  private webClientTopic(clientId: string, token: string) {
    const callable = this.afsFunctions.httpsCallable(API.subscribeToClientMessages);
    callable({
      clientId: clientId,
      tokens: [token],
    } as IClientSubscriptionRequest).subscribe((subscriptionResult) => {
      console.log({ subscriptionResult });
    });
  }
  private nativeClientTopic(clientId: string) {
    fcm.subscribeTo({ topic: clientId });
  }
  private webUserTopic(userId: string, clientId: string, token: string) {
    const callable = this.afsFunctions.httpsCallable(API.subscribeToUserMessages);
    callable({
      clientId: clientId,
      userId: userId,
      tokens: [token],
    } as IUserSubscriptionRequest).subscribe((subscriptionResult) => {
      console.log('MessagingService -> webUserTopic -> subscriptionResult', { subscriptionResult });
    });
  }
  private nativeUserTopic(userId: string, clientId: string) {
    fcm.subscribeTo({ topic: `${clientId}_${userId}` });
  }
  private webLocationTopic(locationId: string, clientId: string, token: string) {
    const callable = this.afsFunctions.httpsCallable(API.subscribeToClientLocationMessages);
    callable({
      clientId: clientId,
      locationId: locationId,
      tokens: [token],
    } as IClientLocationSubscriptionRequest).subscribe((subscriptionResult) => {
      console.log('MessagingService -> webLocationTopic -> subscriptionResult', {
        subscriptionResult,
      });
    });
  }
  private nativeLocationTopic(locationId: string) {
    fcm.subscribeTo({ topic: locationId });
  }

  private async showNotification(message: string) {
    return this.toastsService.showNotification(message);
  }
}
