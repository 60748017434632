import { KeyGen } from './../../../../../shared/access-control/keygen';
import { IFirstOrderRequest } from './../../../../../shared/models/i-first-order-request';
import { AngularFireFunctions } from '@angular/fire/functions';
import { switchMap, filter, map } from 'rxjs/operators';
import { DB } from './../../../../../shared/constants/db';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { IUser } from '../../../../../shared/models/i-user';
import { API } from '../../../../../shared/constants/api-endpoints';

@Injectable({ providedIn: 'root' })
export class UserService {
  public user$: Observable<IUser>;

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
    private afFunctions: AngularFireFunctions
  ) {
    this.user$ = this.getUser$();
  }

  getUser$(): Observable<IUser> {
    return this.auth.user$.pipe(
      filter(authUser => !!authUser),
      map(authUser => authUser.uid),
      switchMap(uid =>
        this.afs
          .collection(DB.USERS.ID)
          .doc<IUser>(uid)
          .valueChanges()
      )
    );
  }

  isFirstOrder$(clientId: string): Observable<boolean> {
    return this.user$.pipe(
      filter(user => !!user),
      map(user => {
        console.log('TCL: UserService -> user', user);
        const key = KeyGen.generate([clientId]);
        console.log('TCL: UserService -> key', key);
        const settings = user.settings ? user.settings[key] : null;
        console.log('TCL: UserService -> settings', settings);
        const hasOrdered = settings && settings.hasOrdered ? true : false;
        console.log('TCL: UserService -> hasOrdered', hasOrdered);
        return !hasOrdered;
      })
    );
  }

  async isFirstOrderByOrderLookup(clientId: string): Promise<boolean> {
    try {
      const callable = this.afFunctions.httpsCallable(API.isFirstOrder);
      const idToken = await this.auth.getIdToken();
      const request = {
        clientId,
        idToken
      } as IFirstOrderRequest;
      const task = callable(request).toPromise();
      const result: boolean = await task;
      console.log('TCL: UserService -> isFirstOrder', result);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
