import { IWeeklyHours } from '../../../../../../shared/models/i-weekly-hours';
import { isWithinRange, distanceInWords, addDays, isBefore } from 'date-fns';
import { DailyHours } from './daily-hours.model';

export class WeeklyHours implements IWeeklyHours {
  private now: Date;
  private start: Date;
  private end: Date;
  private offset = 0;
  isOpen = false;
  day: number;
  monday: DailyHours;
  tuesday: DailyHours;
  wednesday: DailyHours;
  thursday: DailyHours;
  friday: DailyHours;
  saturday: DailyHours;
  sunday: DailyHours;
  private currentDay: DailyHours;
  isUnavailable = false;

  constructor(hours?: IWeeklyHours) {
    if (!hours) {
      return;
    }
    this.monday = new DailyHours(hours.monday);
    this.tuesday = new DailyHours(hours.tuesday);
    this.wednesday = new DailyHours(hours.wednesday);
    this.thursday = new DailyHours(hours.thursday);
    this.friday = new DailyHours(hours.friday);
    this.saturday = new DailyHours(hours.saturday);
    this.sunday = new DailyHours(hours.sunday);
    this.isUnavailable = hours.isUnavailable ? true : false;
    this.init();
  }

  get untilOpen(): string {
    if (this.isOpen) {
      return 'open now';
    }
    return distanceInWords(this.now, this.start);
  }

  get untilClosed(): string {
    if (!this.isOpen) {
      return 'closed now';
    }
    return distanceInWords(this.now, this.end);
  }

  export(): IWeeklyHours {
    return {
      monday: Object.assign({}, this.monday),
      tuesday: Object.assign({}, this.tuesday),
      wednesday: Object.assign({}, this.wednesday),
      thursday: Object.assign({}, this.thursday),
      friday: Object.assign({}, this.friday),
      saturday: Object.assign({}, this.saturday),
      sunday: Object.assign({}, this.sunday),
    };
  }

  private init() {
    this.now = new Date();
    this.offset = 1; // start with yesterday;
    this.setValues();
    if (!this.isOpen) {
      this.offset = 0;
      this.setValues();
    }
  }

  private setValues() {
    this.setDay();
    this.setHours();
    this.setIsOpen();
  }

  private setIsOpen(): void {
    this.isOpen = this.currentDay.isOpen && isWithinRange(this.now, this.start, this.end);
  }
  public isOpenAtDate(date: Date) {
    const currentDay = this.getDayForDate(date);
    const hours = this.getHoursForDate(date, currentDay);
    return this.currentDay.isOpen && isWithinRange(date, hours.start, hours.end);
  }

  private setDay(): void {
    this.day = addDays(this.now, -1 * this.offset).getDay();
    switch (this.day) {
      case 0:
        this.currentDay = this.sunday;
        break;
      case 1:
        this.currentDay = this.monday;
        break;
      case 2:
        this.currentDay = this.tuesday;
        break;
      case 3:
        this.currentDay = this.wednesday;
        break;
      case 4:
        this.currentDay = this.thursday;
        break;
      case 5:
        this.currentDay = this.friday;
        break;
      case 6:
        this.currentDay = this.saturday;
        break;
    }
  }

  private getDayForDate(date: Date) {
    this.day = addDays(date, -1 * this.offset).getDay();
    switch (this.day) {
      case 0:
        return this.sunday;
        break;
      case 1:
        return this.monday;
        break;
      case 2:
        return this.tuesday;
        break;
      case 3:
        return this.wednesday;
        break;
      case 4:
        return this.thursday;
        break;
      case 5:
        return this.friday;
        break;
      case 6:
        return this.saturday;
        break;
    }
  }

  private setHours() {
    let start, end;
    start = this.currentDay.start;
    end = this.currentDay.end;
    if (!start || !start.toDate || !end || !end.toDate) {
      return;
    }
    const startDate = start.toDate();
    this.start = addDays(new Date(), this.offset * -1);
    this.start.setHours(startDate.getHours());
    this.start.setMinutes(startDate.getMinutes());

    const endDate = end.toDate();
    this.end = addDays(new Date(), this.offset * -1);
    this.end.setHours(endDate.getHours());
    this.end.setMinutes(endDate.getMinutes());

    if (isBefore(this.end, this.start)) {
      this.end = addDays(this.end, 1);
    }
  }
  private getHoursForDate(date: Date, currentDay: DailyHours) {
    let start, end;
    let timeInfo = { start: null, end: null };
    start = currentDay.start;
    end = currentDay.end;
    if (!start || !start.toDate || !end || !end.toDate) {
      return;
    }
    const startDate = start.toDate();
    timeInfo.start = addDays(date, this.offset * -1);
    timeInfo.start.setHours(startDate.getHours());
    timeInfo.start.setMinutes(startDate.getMinutes());

    const endDate = end.toDate();
    timeInfo.end = addDays(date, this.offset * -1);
    timeInfo.end.setHours(endDate.getHours());
    timeInfo.end.setMinutes(endDate.getMinutes());

    if (isBefore(timeInfo.end, timeInfo.start)) {
      timeInfo.end = addDays(timeInfo.end, 1);
    }
    return timeInfo;
  }
}
