import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { LoadingController } from '@ionic/angular';
import { API } from '../../../../../shared/constants/api-endpoints';
import { IConnectOAuthRequest } from '../../../../../shared/models/i-connect-oauth-request';
import { DB } from '../../../../../shared/constants/db';
import { IClient } from '../../../../../shared/models/i-client';
import { noSlashAtUrlEnd } from '../../../../../shared/helpers/no-slash-at-url-end';
import { AngularFirestore } from '@angular/fire/firestore';

const FAILED = true; // named consts because the values are unintuitive
const SUCCESS = false;

@Injectable({
  providedIn: 'root',
})
export class ConnectOauthGuard implements CanActivate {
  private loading: HTMLIonLoadingElement;
  private clientId: string;
  isLoading = false;

  constructor(
    private afFunctions: AngularFireFunctions,
    private afs: AngularFirestore,
    private loadingController: LoadingController,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return of({
      code: next.queryParamMap.get('code'),
      state: next.queryParamMap.get('state'),
    }).pipe(
      switchMap((params) => {
        console.log('TCL: ConnectOauthGuard -> params', params);
        if (!params.code || !params.state) {
          return of(FAILED); // go to error component
        }
        this.clientId = this.parseState(params.state);
        // send code to backend function to perform OAuth
        // and get the stripe connect account ID for the client
        // and save it to the client in the DB
        return from(this.sendCode(params.code));
      }),
      tap((response) => console.log('TCL: ConnectOauthGuard -> response', response)),
      switchMap(() =>
        this.afs.collection(DB.CLIENTS.ID).doc<IClient>(this.clientId).valueChanges()
      ),
      switchMap((client) => {
        const url = noSlashAtUrlEnd(client.appUrl);
        window.location.href = `${url}/portal/client/${this.clientId}/dashboard`;
        return from(this.hideLoading()).pipe(switchMap(() => of(SUCCESS)));
      }),
      catchError((error) => {
        console.log('TCL: ConnectOauthGuard -> error', error);
        return from(this.hideLoading()).pipe(switchMap(() => of(FAILED)));
      })
    );
  }

  private async showLoading(message?: string) {
    await this.hideLoading();
    this.isLoading = true;
    this.loading = await this.loadingController.create({
      translucent: true,
      message: message,
    });
    await this.loading.present();
  }

  private async hideLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
    this.isLoading = false;
  }

  async sendCode(code: string) {
    console.log('TCL: ConnectOauthGuard -> sendCode -> code', code);
    try {
      await this.showLoading();
      const callable = this.afFunctions.httpsCallable(API.connect);
      const request = { code, clientId: this.clientId } as IConnectOAuthRequest;
      const task = callable(request).toPromise();
      const result = await task;
      console.log(result);
      return true;
    } catch (error) {
      console.log('TCL: ConnectOauthGuard -> sendCode -> error', error);
      return false;
    }
  }

  private parseState(state: string) {
    console.log('TCL: ConnectOauthGuard -> parseState -> state', state);
    // todo return client id right now that will just be the raw state
    // future iterations will require parsing base64 or some other decoding
    return state;
  }
}
