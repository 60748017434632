import { DeviceService } from './device.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap, filter } from 'rxjs/operators';
import { ClientService } from './client.service';
import { DB } from '../../../../../shared/constants/db';
import { IClientLocation } from '../../../../../shared/models/i-client-location';
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';

const KEY = 'locationId';
@Injectable({ providedIn: 'root' })
export class LocationService {
  public location$: Observable<IClientLocation>;
  private locationIdSubject: BehaviorSubject<string | null>;
  locationId$: Observable<string | null>;
  storedId: string;
  public onUnSubscribeToLocation = new Subject<any>();

  constructor(
    private afs: AngularFirestore,
    private clientService: ClientService,
    private storage: StorageService,
    public plt: Platform,
    public deviceService: DeviceService
  ) {
    this.storedId = this.storage.get(KEY);
    this.locationIdSubject = new BehaviorSubject(this.storedId);
    this.locationId$ = this.locationIdSubject.asObservable();
    this.observeLocationById();
  }

  private observeLocationById() {
    this.location$ = combineLatest([this.clientService.clientId$, this.locationIdSubject]).pipe(
      filter(([clientId, id]) => !!clientId && !!id),
      switchMap(([clientId, id]) => {
        return this.afs
          .collection(DB.CLIENTS.ID)
          .doc(clientId)
          .collection<IClientLocation>(DB.CLIENTS.LOCATIONS.ID)
          .doc<IClientLocation>(id)
          .valueChanges();
      })
    );
  }

  selectLocation(locationId: string | null, remember: boolean) {
    if (this.storedId !== locationId && remember && this.storedId) {
      this.onUnSubscribeToLocation.next(this.storedId);
    }

    this.locationIdSubject.next(locationId);
    this.storage.delete(KEY);
    if (remember) {
      this.storage.set(KEY, locationId);
      localStorage.setItem(KEY, locationId);
    }
  }
}
